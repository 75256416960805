import _get from "lodash/get";
import _isString from "lodash/isString";

import { BackgroundColorYellow } from "ds/Alias";
import {
  ColorBlueDark,
  ColorBlueLight,
  ColorBlueLightest,
  ColorGrayBase,
  ColorGrayDark,
  ColorGrayDarkest,
  ColorGrayLight,
  ColorGreenBase,
  ColorGreenLight,
  ColorPinkLightest,
  ColorPurpleLight,
  ColorRedBase,
  ColorRedLightest,
  ColorYellowBase,
  ColorYellowLightest,
} from "ds/Core";
import { Path } from "types/utils";

export const colors = {
  white: "#FFF",
  black: "#000",
  "dark-1": ColorGrayDarkest,
  "dark-2": ColorGrayDark,
  "dark-3": ColorGrayDark,
  "dark-4": ColorGrayBase,
  "light-2": "#F6F8F8",
  "light-4": "#DADCDD",
  "light-5": "#BABEC0",
  "light-8": "#CACDCE",
  "status-critical": ColorRedBase,
  "status-warning": ColorYellowBase,
  "status-ok": ColorGreenBase,
  "ocean-blue": ColorBlueDark,
  unknown: {
    "1": BackgroundColorYellow, // Login, Number, Sandbox and Done screens https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "2": ColorPurpleLight, // Signup, Terms, Readiness, Invite screens https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "3": ColorRedBase, // Choose Plan screens https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "4": ColorGreenLight, // Embeddedd Signup screens https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "5": ColorBlueLight, // Business Verification screen https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "6": ColorRedLightest, // Error message https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A12102
    "7": "", // unused token
    "8": "", // unused token
    "9": "", // unused token
    "10": "", // unused token
    "11": "", // unused token
    "12": ColorGreenBase, // success status https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=2119%3A3407
    "13": ColorYellowBase, // warning status https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=2119%3A3407
    "14": ColorRedBase, // error status https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=2119%3A3407
    "15": ColorGrayLight, // neutral status https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=2119%3A3407
    "16": ColorBlueLightest, // verification code background https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3425%3A8812
    "17": ColorYellowLightest, // number background https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3425%3A8812
    "18": ColorPinkLightest, // Plans table background https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
  },
};

export type TColors = Path<typeof colors>;

export const getColor = (path: TColors): string => {
  const color = _get(colors, path);

  return _isString(color) ? color : path;
};
