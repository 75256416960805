/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { PricesConfigurationDetailsFragment } from "./fragments.generated";
import { SubscriptionDetailsFragment } from "./fragments.generated";
import { PaymentNumberSubscriptionDetailsFragment } from "./fragments.generated";
import { PricesConfigurationDetailsFragmentDoc } from "./fragments.generated";
import { SubscriptionDetailsFragmentDoc } from "./fragments.generated";
import { PaymentNumberSubscriptionDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type BillingPricesConfigurationQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
}>;

export type BillingPricesConfigurationQuery = {
  __typename: "RootQueryType";
  billingPricesConfiguration: ({ __typename: "PricesConfiguration" } & PricesConfigurationDetailsFragment) | null;
};

export type PaddleVersionConfigurationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PaddleVersionConfigurationQuery = {
  __typename: "RootQueryType";
  paddleVersionConfiguration: Types.PaddleVersion | null;
};

export type GetNumberBillingSubscriptionQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
}>;

export type GetNumberBillingSubscriptionQuery = {
  __typename: "RootQueryType";
  number: ({ __typename: "Number" } & SubscriptionDetailsFragment) | null;
};

export type GetPaymentDetailsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
}>;

export type GetPaymentDetailsQuery = {
  __typename: "RootQueryType";
  number: {
    __typename: "Number";
    uuid: string | null;
    subscription: ({ __typename: "NumberSubscription" } & PaymentNumberSubscriptionDetailsFragment) | null;
  } | null;
};

export type SwitchRequestAlreadySubmittedQueryVariables = Types.Exact<{
  subscriptionId: Types.Scalars["String"];
}>;

export type SwitchRequestAlreadySubmittedQuery = {
  __typename: "RootQueryType";
  switchRequestAlreadySubmitted: boolean | null;
};

export const BillingPricesConfigurationDocument = gql`
  query billingPricesConfiguration($numberUuid: ID!) {
    billingPricesConfiguration(numberUuid: $numberUuid) {
      ...PricesConfigurationDetails
    }
  }
  ${PricesConfigurationDetailsFragmentDoc}
`;

/**
 * __useBillingPricesConfigurationQuery__
 *
 * To run a query within a React component, call `useBillingPricesConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPricesConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPricesConfigurationQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useBillingPricesConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<BillingPricesConfigurationQuery, BillingPricesConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingPricesConfigurationQuery, BillingPricesConfigurationQueryVariables>(
    BillingPricesConfigurationDocument,
    options
  );
}
export function useBillingPricesConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingPricesConfigurationQuery, BillingPricesConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingPricesConfigurationQuery, BillingPricesConfigurationQueryVariables>(
    BillingPricesConfigurationDocument,
    options
  );
}
export type BillingPricesConfigurationQueryHookResult = ReturnType<typeof useBillingPricesConfigurationQuery>;
export type BillingPricesConfigurationLazyQueryHookResult = ReturnType<typeof useBillingPricesConfigurationLazyQuery>;
export type BillingPricesConfigurationQueryResult = Apollo.QueryResult<
  BillingPricesConfigurationQuery,
  BillingPricesConfigurationQueryVariables
>;
export const PaddleVersionConfigurationDocument = gql`
  query paddleVersionConfiguration {
    paddleVersionConfiguration
  }
`;

/**
 * __usePaddleVersionConfigurationQuery__
 *
 * To run a query within a React component, call `usePaddleVersionConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaddleVersionConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaddleVersionConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaddleVersionConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<PaddleVersionConfigurationQuery, PaddleVersionConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaddleVersionConfigurationQuery, PaddleVersionConfigurationQueryVariables>(
    PaddleVersionConfigurationDocument,
    options
  );
}
export function usePaddleVersionConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaddleVersionConfigurationQuery, PaddleVersionConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaddleVersionConfigurationQuery, PaddleVersionConfigurationQueryVariables>(
    PaddleVersionConfigurationDocument,
    options
  );
}
export type PaddleVersionConfigurationQueryHookResult = ReturnType<typeof usePaddleVersionConfigurationQuery>;
export type PaddleVersionConfigurationLazyQueryHookResult = ReturnType<typeof usePaddleVersionConfigurationLazyQuery>;
export type PaddleVersionConfigurationQueryResult = Apollo.QueryResult<
  PaddleVersionConfigurationQuery,
  PaddleVersionConfigurationQueryVariables
>;
export const GetNumberBillingSubscriptionQueryDocument = gql`
  query getNumberBillingSubscriptionQuery($numberUuid: ID!) {
    number(numberUuid: $numberUuid) {
      ...SubscriptionDetails
    }
  }
  ${SubscriptionDetailsFragmentDoc}
`;

/**
 * __useGetNumberBillingSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetNumberBillingSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumberBillingSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumberBillingSubscriptionQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useGetNumberBillingSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<GetNumberBillingSubscriptionQuery, GetNumberBillingSubscriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNumberBillingSubscriptionQuery, GetNumberBillingSubscriptionQueryVariables>(
    GetNumberBillingSubscriptionQueryDocument,
    options
  );
}
export function useGetNumberBillingSubscriptionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNumberBillingSubscriptionQuery,
    GetNumberBillingSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNumberBillingSubscriptionQuery, GetNumberBillingSubscriptionQueryVariables>(
    GetNumberBillingSubscriptionQueryDocument,
    options
  );
}
export type GetNumberBillingSubscriptionQueryHookResult = ReturnType<typeof useGetNumberBillingSubscriptionQuery>;
export type GetNumberBillingSubscriptionQueryLazyQueryHookResult = ReturnType<
  typeof useGetNumberBillingSubscriptionQueryLazyQuery
>;
export type GetNumberBillingSubscriptionQueryQueryResult = Apollo.QueryResult<
  GetNumberBillingSubscriptionQuery,
  GetNumberBillingSubscriptionQueryVariables
>;
export const GetPaymentDetailsQueryDocument = gql`
  query getPaymentDetailsQuery($numberUuid: ID!) {
    number(numberUuid: $numberUuid) {
      uuid
      subscription {
        ...PaymentNumberSubscriptionDetails
      }
    }
  }
  ${PaymentNumberSubscriptionDetailsFragmentDoc}
`;

/**
 * __useGetPaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetPaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentDetailsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useGetPaymentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>(
    GetPaymentDetailsQueryDocument,
    options
  );
}
export function useGetPaymentDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>(
    GetPaymentDetailsQueryDocument,
    options
  );
}
export type GetPaymentDetailsQueryHookResult = ReturnType<typeof useGetPaymentDetailsQuery>;
export type GetPaymentDetailsQueryLazyQueryHookResult = ReturnType<typeof useGetPaymentDetailsQueryLazyQuery>;
export type GetPaymentDetailsQueryQueryResult = Apollo.QueryResult<
  GetPaymentDetailsQuery,
  GetPaymentDetailsQueryVariables
>;
export const SwitchRequestAlreadySubmittedDocument = gql`
  query switchRequestAlreadySubmitted($subscriptionId: String!) {
    switchRequestAlreadySubmitted(subscriptionId: $subscriptionId)
  }
`;

/**
 * __useSwitchRequestAlreadySubmittedQuery__
 *
 * To run a query within a React component, call `useSwitchRequestAlreadySubmittedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwitchRequestAlreadySubmittedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwitchRequestAlreadySubmittedQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useSwitchRequestAlreadySubmittedQuery(
  baseOptions: Apollo.QueryHookOptions<SwitchRequestAlreadySubmittedQuery, SwitchRequestAlreadySubmittedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SwitchRequestAlreadySubmittedQuery, SwitchRequestAlreadySubmittedQueryVariables>(
    SwitchRequestAlreadySubmittedDocument,
    options
  );
}
export function useSwitchRequestAlreadySubmittedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SwitchRequestAlreadySubmittedQuery,
    SwitchRequestAlreadySubmittedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SwitchRequestAlreadySubmittedQuery, SwitchRequestAlreadySubmittedQueryVariables>(
    SwitchRequestAlreadySubmittedDocument,
    options
  );
}
export type SwitchRequestAlreadySubmittedQueryHookResult = ReturnType<typeof useSwitchRequestAlreadySubmittedQuery>;
export type SwitchRequestAlreadySubmittedLazyQueryHookResult = ReturnType<
  typeof useSwitchRequestAlreadySubmittedLazyQuery
>;
export type SwitchRequestAlreadySubmittedQueryResult = Apollo.QueryResult<
  SwitchRequestAlreadySubmittedQuery,
  SwitchRequestAlreadySubmittedQueryVariables
>;
