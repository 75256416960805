import { Box } from "grommet";
import { Anchor } from "grommet";
import styled from "styled-components";

import { Button } from "ds/Button";
import { ColorBrandDefault, ColorPurpleDark } from "ds/Core";
import { down } from "ds/theme";
import { ButtonIconMute } from "lib/buttons";
import { px } from "lib/tokens";

export const INBOX_COLUMN_WIDTH = "280px";
export const HEADER_HEIGHT = "64px";
export const HEADER_MOBILE_HEIGHT = "56px";
export const SUB_HEADER_HEIGHT = "48px";
export const FOOTER_HEIGHT = "48px";
export const REPLY_BOX_HEIGHT = "400px";

export const StackToggle = styled(ButtonIconMute)`
  display: block;
  transition: 0s;
`;

export const CenterFooter = styled(Box)`
  position: relative;
`;

interface IInboxChatListContainer {
  selectingActive?: boolean;
}
export const InboxChatListContainer = styled(Box)<IInboxChatListContainer>`
  ${down("lg")} {
    width: 100%;
    box-shadow: none;
    position: relative;
    border: none;
    min-width: 0;
    right: 0;
  }
  transition: all 0.2s ease;
  position: "relative";
  height: 100%;
  top: 0;

  ${({ selectingActive }) => {
    const width = selectingActive ? 372 : 320;
    const right = width - 20;

    return `
      width: ${width}px;
      right: -${px(right)};
    `;
  }}
`;

export const CenterPanel = styled(Box)`
  ${down("lg")} {
    margin-right: 0px;
  }
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const MobileContainer = styled.div<{ offset: `${number}px` }>`
  width: 100%;
  height: ${({ offset }) => `calc(100% - ${offset})`};
  height: ${({ offset }) => `calc(var(--vh, 1vh) * 100 - ${offset})`};
`;

interface ICustomHoverButton {
  backgroundColor?: string;
  hoverColor?: string;
}
export const CustomColorsButton = styled(Button)<ICustomHoverButton>`
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}

  &:hover {
    ${({ hoverColor }) => hoverColor && `background-color: ${hoverColor};`}
  }
`;

export const MessageListAnchor = styled(Anchor)`
  font-size: 11px;
  font-weight: 700;
  text-decoration: underline;
  color: ${ColorBrandDefault};
  transition: all 0.15s ease-in;

  &:hover {
    color: ${ColorPurpleDark};
  }
`;
