import { useQuery } from "@apollo/client";
import { Anchor } from "grommet";
import React from "react";
import { Trans } from "react-i18next";

import { OnboardingApplicationStatus } from "graphql/generated/types";
import { TOrganisationDetails } from "graphql/organisation";
import { GET_INITIAL_NUMBER, TInitialNumber, getInitialNumber, getInitialNumberVariables } from "graphqlQueries";
import {
  FacebookBusinessVerificationStatus,
  GraphNameStatus,
  NumberType,
  SignupType,
  WabaReviewStatus,
} from "graphqlQueries/generated/globalTypes";
import { IUseNavigationOptions } from "hooks";
import { notify } from "lib/notification";
import { parsePhoneNumber } from "libphonenumber-js";
import { numberHasActiveSubscription } from "selectors";
import { FeatureFlag, isEnabledFeature } from "utils/featureFlags";
import i18n from "utils/i18n";
import {
  TurnSupportSignUp,
  facebookBusinessSecurity,
  learnBusinessVerified,
  learnDisplayName,
  learnEmbeddedSignup,
} from "utils/links";
import { getOrganisationMetaVerificationDoneStatus } from "utils/localStorage";

import { StatusColors } from "./styles";
import { OnboardingStep, StepStatuses } from "./types";

export const getNameStatus = (graphNameStatus?: GraphNameStatus | null) =>
  graphNameStatus === GraphNameStatus.APPROVED
    ? {
        label: i18n.t("onboarding.progress-bar.status.approved"),
        color: StatusColors.SUCCESS,
      }
    : graphNameStatus === GraphNameStatus.AVAILABLE_WITHOUT_REVIEW
      ? {
          label: i18n.t("onboarding.progress-bar.status.available-without-review"),
          color: StatusColors.SUCCESS,
        }
      : graphNameStatus === GraphNameStatus.PENDING_REVIEW
        ? {
            label: i18n.t("onboarding.progress-bar.status.pending-review"),
            color: StatusColors.PENDING,
          }
        : graphNameStatus === GraphNameStatus.DECLINED
          ? {
              label: i18n.t("onboarding.progress-bar.status.declined"),
              color: StatusColors.ERROR,
              tooltip: {
                id: "name-declined",
                i18nKey: "onboarding.progress-bar.tooltip.contact-facebook" as const,
                href: learnDisplayName,
              },
            }
          : graphNameStatus === GraphNameStatus.EXPIRED
            ? {
                label: i18n.t("onboarding.progress-bar.status.expired"),
                color: StatusColors.ERROR,
                tooltip: {
                  id: "name-expired",
                  i18nKey: "onboarding.progress-bar.tooltip.contact-facebook" as const,
                  href: learnDisplayName,
                },
              }
            : graphNameStatus === GraphNameStatus.NONE
              ? {
                  label: i18n.t("onboarding.progress-bar.status.none"),
                  color: StatusColors.NONE,
                }
              : {
                  label: i18n.t("onboarding.progress-bar.status.unknown"),
                  color: StatusColors.NONE,
                };

export const getAccountStatus = (accountReviewStatus?: WabaReviewStatus | null) =>
  accountReviewStatus === WabaReviewStatus.APPROVED
    ? {
        label: i18n.t("onboarding.progress-bar.status.approved"),
        color: StatusColors.SUCCESS,
      }
    : accountReviewStatus === WabaReviewStatus.PENDING
      ? {
          label: i18n.t("onboarding.progress-bar.status.pending"),
          color: StatusColors.PENDING,
        }
      : accountReviewStatus === WabaReviewStatus.REJECTED
        ? {
            label: i18n.t("onboarding.progress-bar.status.rejected"),
            color: StatusColors.ERROR,
            tooltip: {
              id: "account-rejected",
              i18nKey: "onboarding.progress-bar.tooltip.contact-facebook" as const,
              href: learnEmbeddedSignup,
            },
          }
        : {
            label: i18n.t("onboarding.progress-bar.status.unknown"),
            color: StatusColors.NONE,
          };

export const getBusinessStatus = (verificationStatus?: FacebookBusinessVerificationStatus | null) => {
  const failedTooltip = {
    id: "business-failed",
    i18nKey: "onboarding.progress-bar.tooltip.contact-facebook" as const,
    href: learnBusinessVerified,
  };

  return verificationStatus === FacebookBusinessVerificationStatus.VERIFIED
    ? {
        label: i18n.t("onboarding.progress-bar.status.verified"),
        color: StatusColors.SUCCESS,
      }
    : verificationStatus === FacebookBusinessVerificationStatus.PENDING
      ? {
          label: i18n.t("onboarding.progress-bar.status.pending"),
          color: StatusColors.PENDING,
        }
      : verificationStatus === FacebookBusinessVerificationStatus.PENDING_NEED_MORE_INFO
        ? {
            label: i18n.t("onboarding.progress-bar.status.pending-need-more-info"),
            color: StatusColors.PENDING,
          }
        : verificationStatus === FacebookBusinessVerificationStatus.PENDING_SUBMISSION
          ? {
              label: i18n.t("onboarding.progress-bar.status.pending-submission"),
              color: StatusColors.PENDING,
            }
          : verificationStatus === FacebookBusinessVerificationStatus.REJECTED
            ? {
                label: i18n.t("onboarding.progress-bar.status.rejected"),
                color: StatusColors.ERROR,
                tooltip: failedTooltip,
              }
            : verificationStatus === FacebookBusinessVerificationStatus.REVOKED
              ? {
                  label: i18n.t("onboarding.progress-bar.status.revoked"),
                  color: StatusColors.ERROR,
                  tooltip: failedTooltip,
                }
              : verificationStatus === FacebookBusinessVerificationStatus.FAILED
                ? {
                    label: i18n.t("onboarding.progress-bar.status.failed"),
                    color: StatusColors.ERROR,
                    tooltip: failedTooltip,
                  }
                : verificationStatus === FacebookBusinessVerificationStatus.NOT_VERIFIED
                  ? {
                      label: i18n.t("onboarding.progress-bar.status.not-verified"),
                      color: StatusColors.NONE,
                      tooltip: {
                        id: "business-not-verified",
                        i18nKey: "onboarding.progress-bar.tooltip.not-verified" as const,
                        href: facebookBusinessSecurity,
                      },
                    }
                  : {
                      label: i18n.t("onboarding.progress-bar.status.unknown"),
                      color: StatusColors.NONE,
                    };
};

const isVerificationStepDone = (
  organisationUuid?: string,
  verificationStatus?: FacebookBusinessVerificationStatus | null
) =>
  getOrganisationMetaVerificationDoneStatus(organisationUuid) ||
  [
    FacebookBusinessVerificationStatus.FAILED,
    FacebookBusinessVerificationStatus.PENDING_NEED_MORE_INFO,
    FacebookBusinessVerificationStatus.PENDING_SUBMISSION,
    FacebookBusinessVerificationStatus.PENDING,
    FacebookBusinessVerificationStatus.REJECTED,
    FacebookBusinessVerificationStatus.REVOKED,
    FacebookBusinessVerificationStatus.VERIFIED,
  ].includes(verificationStatus as FacebookBusinessVerificationStatus);

export const getLegacyStepStatuses = (number?: TInitialNumber, organisation?: TOrganisationDetails) => {
  const { NUMBER_SELECTION, WAITING_FOR_CUSTOM_NUMBER, EMBEDDED_SIGNUP } = OnboardingApplicationStatus;
  const { activeOnboardingApplication, uuid: organisationUuid } = organisation ?? {};
  const { status: onboardingApplicationStatus } = activeOnboardingApplication ?? {};
  const { signupType, waba } = number ?? {};
  const paymentCompleted = numberHasActiveSubscription(number);

  const numberStatus =
    signupType === SignupType.EMBEDDED_SIGNUP || onboardingApplicationStatus === EMBEDDED_SIGNUP
      ? StepStatuses.DONE
      : onboardingApplicationStatus === WAITING_FOR_CUSTOM_NUMBER
        ? StepStatuses.PENDING
        : onboardingApplicationStatus === NUMBER_SELECTION
          ? StepStatuses.NEXT
          : StepStatuses.NONE;

  const embeddedSignupStatus =
    signupType === SignupType.EMBEDDED_SIGNUP
      ? StepStatuses.DONE
      : numberStatus === StepStatuses.DONE && onboardingApplicationStatus === EMBEDDED_SIGNUP
        ? StepStatuses.NEXT
        : StepStatuses.NONE;

  const verificationStatus =
    embeddedSignupStatus === StepStatuses.DONE && numberStatus === StepStatuses.DONE
      ? isVerificationStepDone(organisationUuid, waba?.facebookBusiness?.verificationStatus)
        ? StepStatuses.DONE
        : StepStatuses.NEXT
      : StepStatuses.NONE;

  const payStatus = paymentCompleted
    ? StepStatuses.DONE
    : verificationStatus === StepStatuses.DONE &&
        embeddedSignupStatus === StepStatuses.DONE &&
        numberStatus === StepStatuses.DONE
      ? StepStatuses.NEXT
      : StepStatuses.NONE;

  return {
    payStatus,
    numberStatus,
    embeddedSignupStatus,
    verificationStatus,
  };
};

export const getStepStatuses = (number?: TInitialNumber, organisation?: TOrganisationDetails) => {
  const { NUMBER_SELECTION, WAITING_FOR_CUSTOM_NUMBER, EMBEDDED_SIGNUP, CHOOSE_PLAN, CANCELLED, STARTED } =
    OnboardingApplicationStatus;
  const { activeOnboardingApplication, uuid: organisationUuid } = organisation ?? {};
  const { status: onboardingApplicationStatus } = activeOnboardingApplication ?? {};
  const { waba } = number ?? {};
  const paymentCompleted = numberHasActiveSubscription(number);

  let numberStatus = StepStatuses.NONE;
  let embeddedSignupStatus = StepStatuses.NONE;
  let verificationStatus = StepStatuses.NONE;
  let payStatus = StepStatuses.NONE;

  if (onboardingApplicationStatus === CANCELLED || onboardingApplicationStatus === STARTED)
    return {
      payStatus,
      numberStatus,
      embeddedSignupStatus,
      verificationStatus,
    };

  // WA Business Platform
  if (onboardingApplicationStatus === EMBEDDED_SIGNUP) {
    embeddedSignupStatus = StepStatuses.NEXT;
  } else {
    embeddedSignupStatus = StepStatuses.DONE;
  }

  // Meta Business Verification
  if (embeddedSignupStatus === StepStatuses.DONE) {
    verificationStatus = isVerificationStepDone(organisationUuid, waba?.facebookBusiness?.verificationStatus)
      ? StepStatuses.DONE
      : StepStatuses.NONE;
  }

  // Number selection
  if (onboardingApplicationStatus !== EMBEDDED_SIGNUP) {
    if (onboardingApplicationStatus === NUMBER_SELECTION) {
      numberStatus = StepStatuses.NEXT;
    } else if (onboardingApplicationStatus === WAITING_FOR_CUSTOM_NUMBER) {
      numberStatus = StepStatuses.PENDING;
    } else {
      numberStatus = StepStatuses.DONE;
    }
  }

  // Choose a Plan
  if (paymentCompleted) {
    payStatus = StepStatuses.DONE;
  } else if (
    embeddedSignupStatus === StepStatuses.DONE &&
    numberStatus === StepStatuses.DONE &&
    onboardingApplicationStatus === CHOOSE_PLAN
  ) {
    payStatus = StepStatuses.NEXT;
  }

  return {
    payStatus,
    numberStatus,
    embeddedSignupStatus,
    verificationStatus,
  };
};

export const isOnboardingApplicationInProgress = (status?: OnboardingApplicationStatus) =>
  [
    OnboardingApplicationStatus.STARTED,
    OnboardingApplicationStatus.NUMBER_SELECTION,
    OnboardingApplicationStatus.WAITING_FOR_CUSTOM_NUMBER,
    OnboardingApplicationStatus.EMBEDDED_SIGNUP,
    OnboardingApplicationStatus.CHOOSE_PLAN,
  ].includes(status as OnboardingApplicationStatus);

interface IShouldShowOnboardingSidebar {
  organisation?: TOrganisationDetails;
  number?: TInitialNumber;
}
export const shouldShowOnboardingSidebar = ({ organisation, number }: IShouldShowOnboardingSidebar): boolean => {
  const onboardingApplicationStatus = organisation?.activeOnboardingApplication?.status;
  if (onboardingApplicationStatus === OnboardingApplicationStatus.CANCELLED) return false;
  const { graphNameStatus, numberType, waba, signupType } = number ?? {};
  const { accountReviewStatus, facebookBusiness } = waba ?? {};
  const { verificationStatus } = facebookBusiness ?? {};

  return numberType === NumberType.VIRTUAL_NUMBER
    ? isOnboardingApplicationInProgress(onboardingApplicationStatus)
    : numberType === NumberType.DIRECT_NUMBER && signupType === SignupType.EMBEDDED_SIGNUP
      ? !(
          (graphNameStatus === GraphNameStatus.APPROVED ||
            graphNameStatus === GraphNameStatus.AVAILABLE_WITHOUT_REVIEW) &&
          accountReviewStatus === WabaReviewStatus.APPROVED &&
          verificationStatus === FacebookBusinessVerificationStatus.VERIFIED &&
          numberHasActiveSubscription(number)
        )
      : false;
};

export const shouldShowOnboardingBanner = ({ organisation, number }: IShouldShowOnboardingSidebar): boolean => {
  return (
    number?.numberType === NumberType.DIRECT_NUMBER &&
    isOnboardingApplicationInProgress(organisation?.activeOnboardingApplication?.status)
  );
};

interface IGetCurrentStep {
  onboardingOrganisationUuid?: string | null;
  eulaAccepted?: boolean;
  numberExists: boolean;
  signupType?: SignupType | null;
  status?: OnboardingApplicationStatus;
  verificationStatus?: FacebookBusinessVerificationStatus | null;
  hasActiveSubscription?: boolean;
}
export const getCurrentStep = ({ onboardingOrganisationUuid, eulaAccepted, numberExists, status }: IGetCurrentStep) => {
  const { STARTED, NUMBER_SELECTION, WAITING_FOR_CUSTOM_NUMBER, EMBEDDED_SIGNUP, CHOOSE_PLAN } =
    OnboardingApplicationStatus;

  // Customer has not signed up
  if (!onboardingOrganisationUuid || (!status && !numberExists)) return OnboardingStep.SIGNUP;

  // Customer has signed up but needs to accept terms and do readiness check
  if (status === STARTED) return eulaAccepted ? OnboardingStep.READINESS : OnboardingStep.TERMS;

  // Customer has created org but not added a number
  if (status === NUMBER_SELECTION || status === WAITING_FOR_CUSTOM_NUMBER) return OnboardingStep.NUMBER;

  // Customer has added number but not created WhatsApp business account
  if (status === EMBEDDED_SIGNUP) return OnboardingStep.EMBEDDED_SIGNUP;

  // Customer has created a WhatsApp business account and needs to choose a payment plan
  if (status === CHOOSE_PLAN) return OnboardingStep.CHOOSE_PLAN;

  return null;
};

export const getLegacyCurrentStep = ({
  onboardingOrganisationUuid,
  eulaAccepted,
  numberExists,
  signupType,
  status,
  verificationStatus,
  hasActiveSubscription,
}: IGetCurrentStep) =>
  !onboardingOrganisationUuid || (!status && !numberExists)
    ? OnboardingStep.SIGNUP
    : status === OnboardingApplicationStatus.STARTED
      ? eulaAccepted
        ? OnboardingStep.READINESS
        : OnboardingStep.TERMS
      : status === OnboardingApplicationStatus.NUMBER_SELECTION ||
          status === OnboardingApplicationStatus.WAITING_FOR_CUSTOM_NUMBER
        ? OnboardingStep.NUMBER
        : status === OnboardingApplicationStatus.EMBEDDED_SIGNUP
          ? OnboardingStep.EMBEDDED_SIGNUP
          : !status && signupType === SignupType.EMBEDDED_SIGNUP
            ? !isVerificationStepDone(onboardingOrganisationUuid, verificationStatus)
              ? OnboardingStep.META_VERIFICATION
              : !hasActiveSubscription
                ? OnboardingStep.CHOOSE_PLAN
                : null
            : null;

export const formatPhoneNumber = (phoneNumber?: string | null) =>
  phoneNumber ? parsePhoneNumber(phoneNumber).formatInternational() : "";

export const notifyRequestError = () => {
  notify({
    type: "error",
    autoClose: false,
    description: (
      <Trans
        i18nKey="notifications.errors.request-error"
        components={{ a: <Anchor href={TurnSupportSignUp} target="_blank" /> }}
      />
    ),
  });
};

export const getOnboardingPhoneNumber = (number?: TInitialNumber, organisation?: TOrganisationDetails) => {
  const { numberStatus } = getStepStatuses(number, organisation);

  if (number?.numberType === NumberType.VIRTUAL_NUMBER)
    return organisation?.activeOnboardingApplication?.phoneNumber || undefined;

  return number?.signupType === SignupType.EMBEDDED_SIGNUP
    ? number.fromAddr
    : numberStatus === StepStatuses.DONE || numberStatus === StepStatuses.NEXT
      ? organisation?.activeOnboardingApplication?.phoneNumber || undefined
      : undefined;
};

export const getOnboardingURLTupleForStep = (
  step?: OnboardingStep,
  organisationUuid?: string
): [string, IUseNavigationOptions?] => {
  if (!organisationUuid) {
    return ["/live/onboarding/create-organisation"];
  }
  switch (step) {
    case OnboardingStep.NUMBER:
      return [`/live/onboarding/${organisationUuid}/connect-number-intro`];
    case OnboardingStep.EMBEDDED_SIGNUP:
      return [`/live/onboarding/${organisationUuid}/connect-waba-intro`];
    case OnboardingStep.META_VERIFICATION:
      return [`/live/onboarding/${organisationUuid}/verify-business`];
    case OnboardingStep.CHOOSE_PLAN:
      // Choose plan LiveView is not ready, so we still use the legacy URL here
      return ["onboarding", { state: { step } }];
    default:
      return ["/live/onboarding/create-organisation"];
  }
};

export const useCurrentOnboardingStep = ({
  organisation,
  numberUuid,
}: {
  organisation?: TOrganisationDetails;
  numberUuid?: string;
}) => {
  const isNewOnboarding = isEnabledFeature(FeatureFlag.NEW_ONBOARDING);
  const { status, eulaAccepted } = organisation?.activeOnboardingApplication ?? {};

  const { data: numberData } = useQuery<getInitialNumber, getInitialNumberVariables>(GET_INITIAL_NUMBER.query, {
    variables: { numberUuid: numberUuid ?? "" },
    skip: !numberUuid,
  });
  const { number } = GET_INITIAL_NUMBER.parse(numberData) ?? {};
  const numberExists = !!number;
  const { signupType, waba } = number ?? {};
  const { verificationStatus } = waba?.facebookBusiness ?? {};
  const hasActiveSubscription = numberHasActiveSubscription(number);

  const getCurrentStepFn = isNewOnboarding ? getCurrentStep : getLegacyCurrentStep;

  return getCurrentStepFn({
    eulaAccepted,
    numberExists,
    onboardingOrganisationUuid: organisation?.uuid,
    signupType,
    status,
    hasActiveSubscription,
    verificationStatus,
  });
};
