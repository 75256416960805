import React, { Fragment } from "react";

import { Button } from "ds/Button";
import { Icon } from "ds/icons";

import { BreadcrumbsItem } from "./components";
import { BreadcrumbsContainer } from "./styles";
import { BreadcrumbsProps } from "./types";

const Breadcrumbs = ({ items, back }: BreadcrumbsProps) => (
  <BreadcrumbsContainer>
    {back && <Button size="small" variation="flat" icon={<Icon name="ArrowLeftIcon" />} {...back} />}
    {items.map(({ text, onClick, onEdit, tooltip, menu }, index) => (
      <Fragment key={index}>
        {index > 0 && <Icon name="SlashDividerIcon" color="grayBase" />}
        <BreadcrumbsItem
          text={text}
          onClick={onClick}
          onEdit={onEdit}
          current={index === items.length - 1}
          tooltip={tooltip}
          menu={menu}
        />
      </Fragment>
    ))}
  </BreadcrumbsContainer>
);

export { Breadcrumbs };

/* todo(alexandrchebotar, 2022-10-07): 
        1. Stories
        2. Types
        3. Docs
*/
