import { Box } from "grommet";
import React from "react";
import MoonLoader from "react-spinners/MoonLoader";

import { ColorGrayLightest } from "ds/Core";

import { SpinnerText } from "./styles";
import { ISpinnerProps } from "./types";

export const Spinner = ({ color, reverse, size, text, textProps, ...props }: ISpinnerProps) => (
  <Box direction="row" align="center" {...props}>
    {reverse && text && (
      <SpinnerText reverse {...textProps}>
        {text}
      </SpinnerText>
    )}
    <MoonLoader color={color || ColorGrayLightest} size={size || 12} speedMultiplier={1.5} />
    {!reverse && text && <SpinnerText {...textProps}>{text}</SpinnerText>}
  </Box>
);
