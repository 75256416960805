export const typographyTokens = {
  headings: {
    h1: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "56px",
      fontSize: "48px",
      textDecoration: "none",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    h2: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "40px",
      fontSize: "32px",
      textDecoration: "none",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    h3: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "32px",
      fontSize: "24px",
      textDecoration: "none",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    h4: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "32px",
      fontSize: "22px",
      textDecoration: "none",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    h5: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "24px",
      fontSize: "20px",
      textDecoration: "none",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    h6: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "24px",
      fontSize: "18px",
      textDecoration: "none",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
  },
  copy: {
    large100: {
      fontFamily: "DM Sans",
      fontWeight: 400,
      lineHeight: "24px",
      fontSize: "20px",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    large200: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "24px",
      fontSize: "20px",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    medium100: {
      fontFamily: "DM Sans",
      fontWeight: 400,
      lineHeight: "24px",
      fontSize: "18px",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    medium200: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "24px",
      fontSize: "18px",
      fontVariationSettings: "normal",
      fontOpticalSizing: "auto",
    },
    "paragraph1-100": {
      fontFamily: "DM Sans",
      fontWeight: 400,
      lineHeight: "24px",
      fontSize: "16px",
    },
    "paragraph1-200": {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "24px",
      fontSize: "16px",
    },
    "paragraph2-100": {
      fontFamily: "DM Sans",
      fontWeight: 400,
      lineHeight: "24px",
      fontSize: "15px",
    },
    "paragraph2-200": {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "24px",
      fontSize: "15px",
    },
    caption100: {
      fontFamily: "DM Sans",
      fontWeight: 400,
      lineHeight: "24px",
      fontSize: "14px",
    },
    caption200: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "24px",
      fontSize: "14px",
    },
    small100: {
      fontFamily: "DM Sans",
      fontWeight: 400,
      lineHeight: "16px",
      fontSize: "12px",
    },
    small200: {
      fontFamily: "DM Sans",
      fontWeight: 500,
      lineHeight: "16px",
      fontSize: "12px",
    },
  },
} as const;

export type TypographyHeadingsType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type TypographyCopyType =
  | "large100"
  | "large200"
  | "medium100"
  | "medium200"
  | "paragraph1-100"
  | "paragraph1-200"
  | "paragraph2-100"
  | "paragraph2-200"
  | "caption100"
  | "caption200"
  | "small100"
  | "small200";
