import styled from "styled-components";

import { Box } from "ds/Box";
import { BorderRadius200, ColorGrayLight, ColorNeutralWhite } from "ds/Core";

export const StyledDropdownContainer = styled.div`
  position: relative;
`;

export const StyledDrop = styled(Box)`
  background: ${ColorNeutralWhite};
  box-shadow:
    0px 8px 15px 2px rgba(0, 0, 0, 0.1),
    0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${BorderRadius200};
  z-index: 999999;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${ColorGrayLight};
    display: none;
    border-radius: 32px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${ColorGrayLight};
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }
`;
