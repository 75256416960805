import { DefaultTheme } from "styled-components";

import { FeedbackColorCritical, FeedbackColorSuccess, FeedbackColorWarning } from "ds/Alias";
import { ColorGrayBase } from "ds/Core";

import { getColor } from "../../styles";
import { Prompts, TPromptTypes, TStyleStates } from "./types";

export const getFieldStyles = (type: TPromptTypes, theme: DefaultTheme, state?: TStyleStates) => {
  switch (type) {
    case Prompts.ERROR:
      if (state === "prompt") {
        return `background: ${getColor("unknown.6")}`;
      }
      return `border-color: ${FeedbackColorCritical};`;
    case Prompts.WARNING:
      if (state === "prompt") {
        return `background: ${FeedbackColorWarning};`;
      }
      return `border-color: ${FeedbackColorWarning};`;
    case Prompts.SUCCESS:
      if (state === "prompt") {
        return `background: ${FeedbackColorSuccess}`;
      }
      return `border-color: ${FeedbackColorSuccess};`;
    default:
      if (state === "focus") {
        return `border-color: ${theme.colors.black};`;
      }
      if (state === "prompt") {
        return "background: white;";
      }
      return `border-color: ${ColorGrayBase};`;
  }
};

interface IGetPrompt {
  error?: string;
  warning?: string;
  success?: string;
}
export const getPrompt = ({ error, warning, success }: IGetPrompt) => {
  if (error) {
    return {
      type: Prompts.ERROR,
      text: error,
    };
  } else if (warning) {
    return {
      type: Prompts.WARNING,
      text: warning,
    };
  } else if (success) {
    return {
      type: Prompts.SUCCESS,
      text: success,
    };
  }
  return {
    type: Prompts.DEFAULT,
  };
};
