import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";

import { Box } from "ds/Box";
import { ReactPortal } from "ds/Modal/src/Portal";
import { Text } from "ds/Typography";

import { StyledTooltip } from "./styles";
import { TooltipProps, TooltipSize, TooltipType } from "./types";

import "react-popper-tooltip/dist/styles.css";

const WithPortal = ({ children, usePortal }: { usePortal?: boolean; children: React.ReactNode }) => {
  if (usePortal) {
    return <ReactPortal wrapperId="tooltip-root">{children}</ReactPortal>;
  }
  return <>{children}</>;
};

export const TooltipComponent = ({
  children,
  content,
  trigger,
  triggerProps,
  type = TooltipType.NEUTRAL,
  showPointer = true,
  size = TooltipSize.MEDIUM,
  offset = [0, 12],
  usePortal = true,
  maxWidth = "500px",
  condensed = false,
  ...props
}: TooltipProps) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: "bottom",
    interactive: true,
    offset,
    ...props,
  });

  return (
    <>
      <Box width="max-content" height="max-content" ref={setTriggerRef} {...triggerProps}>
        {trigger}
      </Box>
      <WithPortal usePortal={usePortal}>
        {visible && (children || content) && (
          <StyledTooltip
            borderRadius="4px"
            paddingY={condensed ? "25" : "100"}
            paddingX={condensed ? "100" : "100"}
            borderWidth="1px"
            type={type}
            showPointer={showPointer}
            ref={setTooltipRef}
            {...getTooltipProps({ className: "tooltip-container" })}
          >
            <Text maxWidth={maxWidth} preset={size === TooltipSize.MEDIUM ? "caption100" : "small100"}>
              {children ?? content}
              <span className="hover-area" />
            </Text>
            <div {...getArrowProps({ className: "tooltip-arrow" })}>
              <svg width="12" height="6" viewBox="0 0 12 6" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.20711 6.29289C0.577141 6.92286 1.02331 8 1.91421 8L13.0858 8C13.9767 8 14.4229 6.92286 13.7929 6.29289L8.20711 0.707107C7.81658 0.316582 7.18342 0.316581 6.79289 0.707106L1.20711 6.29289Z" />
              </svg>
            </div>
          </StyledTooltip>
        )}
      </WithPortal>
    </>
  );
};
