export const CLIENT_SET = "@turn/CLIENT_SET";
export const ERROR_SET = "@turn/ERROR_SET";

export const MEDIA_TYPE_APPLICATION = "APPLICATION";
export const MEDIA_TYPE_AUDIO = "AUDIO";
export const MEDIA_TYPE_CONTACT = "CONTACT";
export const MEDIA_TYPE_DOCUMENT = "DOCUMENT";
export const MEDIA_TYPE_IMAGE = "IMAGE";
export const MEDIA_TYPE_LOCATION = "LOCATION";
export const MEDIA_TYPE_TEXT = "TEXT";
export const MEDIA_TYPE_STICKER = "STICKER";
export const MEDIA_TYPE_VIDEO = "VIDEO";
export const MEDIA_TYPE_VOICE = "VOICE";

export const DIRECTION_INBOUND = "INBOUND";
export const DIRECTION_OUTBOUND = "OUTBOUND";

export const DIRECT_NUMBER = "DIRECT_NUMBER";
export const VIRTUAL_NUMBER = "VIRTUAL_NUMBER";
export const VIRTUAL_NUMBER_PROVIDER = "VIRTUAL_NUMBER_PROVIDER";

export const CHATLIST_PAGE_SIZE = 20;
export const CONTENT_MAX_LENGTH = 4096;

export const ACCESS_TOKEN_PARAM = "token";

export const TURN_HOST = process.env.REACT_APP_TURN_HOST || "https://qa.whatsapp.turn.io";
export const TURN_WS_HOST = process.env.REACT_APP_TURN_WS_HOST || "wss://qa.whatsapp.turn.io";
export const TURN_LOGIN_STATE = process.env.REACT_APP_TURN_LOGIN_STATE || "v3";
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const TURN_RELEASE = process.env.REACT_APP_VERSION || "0.0.0-unversioned";
export const PADDLE_CLIENT_SIDE_TOKEN_KEY = process.env.REACT_APP_PADDLE_CLIENT_SIDE_TOKEN_KEY;
export const PROFITWELL_PUBLIC_KEY = process.env.REACT_APP_PROFITWELL_PUBLIC_TOKEN;

export const DEFAULT_APP_CONFIG = {
  turn_host: TURN_HOST,
  turn_ws_host: TURN_WS_HOST,
  login_state: TURN_LOGIN_STATE,
  sentry_dsn: SENTRY_DSN,
  turn_release: TURN_RELEASE,
  paddle_client_side_token: PADDLE_CLIENT_SIDE_TOKEN_KEY,
  profitwell_public_token: PROFITWELL_PUBLIC_KEY,
};
