/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";

const defaultOptions = {} as const;
export type DeleteOrganisationTokenMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  organisationTokenUuid: Types.Scalars["ID"];
}>;

export type DeleteOrganisationTokenMutation = {
  __typename: "RootMutationType";
  deleteOrganisationToken: { __typename: "OrganisationToken"; uuid: string | null; name: string | null } | null;
};

export type SetPrivacyEnabledFieldMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  privacyEnabled: Types.Scalars["Boolean"];
}>;

export type SetPrivacyEnabledFieldMutation = {
  __typename: "RootMutationType";
  setPrivacyEnabledField: { __typename: "Organisation"; uuid: string | null; privacyEnabled: boolean | null } | null;
};

export const DeleteOrganisationTokenDocument = gql`
  mutation deleteOrganisationToken($organisationUuid: ID!, $organisationTokenUuid: ID!) {
    deleteOrganisationToken(organisationUuid: $organisationUuid, organisationTokenUuid: $organisationTokenUuid) {
      uuid
      name
    }
  }
`;
export type DeleteOrganisationTokenMutationFn = Apollo.MutationFunction<
  DeleteOrganisationTokenMutation,
  DeleteOrganisationTokenMutationVariables
>;

/**
 * __useDeleteOrganisationTokenMutation__
 *
 * To run a mutation, you first call `useDeleteOrganisationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganisationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganisationTokenMutation, { data, loading, error }] = useDeleteOrganisationTokenMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      organisationTokenUuid: // value for 'organisationTokenUuid'
 *   },
 * });
 */
export function useDeleteOrganisationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOrganisationTokenMutation, DeleteOrganisationTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrganisationTokenMutation, DeleteOrganisationTokenMutationVariables>(
    DeleteOrganisationTokenDocument,
    options
  );
}
export type DeleteOrganisationTokenMutationHookResult = ReturnType<typeof useDeleteOrganisationTokenMutation>;
export type DeleteOrganisationTokenMutationResult = Apollo.MutationResult<DeleteOrganisationTokenMutation>;
export type DeleteOrganisationTokenMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganisationTokenMutation,
  DeleteOrganisationTokenMutationVariables
>;
export const SetPrivacyEnabledFieldDocument = gql`
  mutation setPrivacyEnabledField($organisationUuid: ID!, $privacyEnabled: Boolean!) {
    setPrivacyEnabledField(organisationUuid: $organisationUuid, privacyEnabled: $privacyEnabled) {
      uuid
      privacyEnabled
    }
  }
`;
export type SetPrivacyEnabledFieldMutationFn = Apollo.MutationFunction<
  SetPrivacyEnabledFieldMutation,
  SetPrivacyEnabledFieldMutationVariables
>;

/**
 * __useSetPrivacyEnabledFieldMutation__
 *
 * To run a mutation, you first call `useSetPrivacyEnabledFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrivacyEnabledFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrivacyEnabledFieldMutation, { data, loading, error }] = useSetPrivacyEnabledFieldMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      privacyEnabled: // value for 'privacyEnabled'
 *   },
 * });
 */
export function useSetPrivacyEnabledFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<SetPrivacyEnabledFieldMutation, SetPrivacyEnabledFieldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetPrivacyEnabledFieldMutation, SetPrivacyEnabledFieldMutationVariables>(
    SetPrivacyEnabledFieldDocument,
    options
  );
}
export type SetPrivacyEnabledFieldMutationHookResult = ReturnType<typeof useSetPrivacyEnabledFieldMutation>;
export type SetPrivacyEnabledFieldMutationResult = Apollo.MutationResult<SetPrivacyEnabledFieldMutation>;
export type SetPrivacyEnabledFieldMutationOptions = Apollo.BaseMutationOptions<
  SetPrivacyEnabledFieldMutation,
  SetPrivacyEnabledFieldMutationVariables
>;
