import { Anchor, Box, Text } from "grommet";
import React from "react";

import { FeedbackColorCritical } from "ds/Alias";
import {
  ColorBlueDark,
  ColorBlueLightest,
  ColorGreenBase,
  ColorGreenLightest,
  ColorRedLightest,
  ColorYellowBase,
  ColorYellowLightest,
} from "ds/Core";

const SUCCESS_TYPE = "success";
const ALERT_TYPE = "alert";
const ERROR_TYPE = "error";

export const TextMessage = ({ type, label, text, href, ...rest }) => {
  const getMessageTypeProps = () => {
    switch (type) {
      case ERROR_TYPE:
        return {
          color: FeedbackColorCritical,
          background: ColorRedLightest,
        };
      case ALERT_TYPE:
        return {
          color: ColorYellowBase,
          background: ColorYellowLightest,
        };
      case SUCCESS_TYPE:
        return {
          color: ColorGreenBase,
          background: ColorGreenLightest,
        };
      default:
        return {
          color: ColorBlueDark,
          background: ColorBlueLightest,
        };
    }
  };
  const { color, background } = getMessageTypeProps();
  return (
    <Box pad={{ vertical: "4px", horizontal: "16px" }} round="5px" background={background} {...rest}>
      <Text color={color}>
        <>
          {label ? `${label}: ` : ""}
          {href ? <Anchor color={color} href={href} label={text} target="_blank" /> : { text }}
        </>
      </Text>
    </Box>
  );
};
