import {
  ApolloError,
  DocumentNode,
  MaybeMasked,
  OperationVariables,
  SubscriptionHookOptions,
  TypedDocumentNode,
  useSubscription as apolloUseSubscription,
} from "@apollo/client";

export const useSubscription = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables>
): {
  loading: boolean;
  data?: MaybeMasked<TData> | undefined;
  error?: ApolloError | undefined;
} => {
  const data = apolloUseSubscription<TData, TVariables>(subscription, options);
  return data;
};
