/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { MessageDetailsFragment } from "./fragments.generated";
import { MessageDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type OnMessageChangedSubscriptionVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
}>;

export type OnMessageChangedSubscription = {
  __typename: "RootSubscriptionType";
  onMessageChanged: ({ __typename: "Message" } & MessageDetailsFragment) | null;
};

export const OnMessageChangedDocument = gql`
  subscription onMessageChanged($chatUuid: ID!) {
    onMessageChanged(chatUuid: $chatUuid) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;

/**
 * __useOnMessageChangedSubscription__
 *
 * To run a query within a React component, call `useOnMessageChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageChangedSubscription({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *   },
 * });
 */
export function useOnMessageChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<OnMessageChangedSubscription, OnMessageChangedSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnMessageChangedSubscription, OnMessageChangedSubscriptionVariables>(
    OnMessageChangedDocument,
    options
  );
}
export type OnMessageChangedSubscriptionHookResult = ReturnType<typeof useOnMessageChangedSubscription>;
export type OnMessageChangedSubscriptionResult = Apollo.SubscriptionResult<OnMessageChangedSubscription>;
