import styled from "styled-components";

import { ColorGrayLight } from "ds/Core";

import { TSpacing, getSpacing } from "../styles";

export const Divider = styled.div<{ margin?: TSpacing }>`
  width: 100%;
  height: 1px;
  margin: ${({ margin, theme: { spaces } }) => (margin ? getSpacing(margin) : `${spaces.s}px 0`)};
  background-color: ${ColorGrayLight};
`;
