import React from "react";

import { typographyTokens } from "ds/theme/typography-tokens";

import { StyledTypography } from "./Styles";
import { TypographyProvider } from "./TypographyProvider";
import { ParagraphProps } from "./types";
import { useTypographyContext } from "./useTypographyContext";

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ children, preset, fontFamily = "dm-sans", ...rest }, ref) => {
    const contextProps = useTypographyContext();
    const styles = preset ? typographyTokens.copy[preset] : {};
    return (
      <TypographyProvider fontFamily={fontFamily} {...styles} {...contextProps} {...rest}>
        <StyledTypography ref={ref} fontFamily={fontFamily} {...styles} {...contextProps} {...rest} as="p">
          {children}
        </StyledTypography>
      </TypographyProvider>
    );
  }
);

Paragraph.displayName = "Paragraph";

export { Paragraph };
