import { Box } from "grommet";
import React from "react";
import BarLoader from "react-spinners/BarLoader";
import styled from "styled-components";

import { ColorBrandDefault } from "ds/Core";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 102;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const ComponentOverlayLoader = ({ loading, children, top = 0, color = ColorBrandDefault, ...props }) => {
  return loading ? (
    <Box
      flex
      fill="vertical"
      direction="column"
      justify="center"
      align="center"
      width="100%"
      style={{ height: "100%", position: "relative" }}
      {...props}
    >
      <Box style={{ position: "absolute", top, width: "100%", zIndex: 103 }} justify="center">
        <BarLoader width="100%" color={color} />
      </Box>

      <Overlay />
      {children}
    </Box>
  ) : (
    children
  );
};
