import "dayjs/locale/pt";
import moment from "moment";
import "moment/locale/pt";

import { dayjs } from "utils/date";
import { FeatureFlag, isEnabledFeature } from "utils/featureFlags";
import i18n from "utils/i18n";
import { getStorageSwitch } from "utils/localStorage";

import { DEFAULT_LOCALE, INFO_PORTUGUESE_MODAL_LOCAL_STORAGE_KEY, localeOptions } from "../constants";
import { getLocaleCookie, setLocaleCookie } from "./cookies";

// context(alexandrchebotar, 2024-12-16): set default locale as loading resources switches it automatically
dayjs.locale(DEFAULT_LOCALE);
moment.locale(DEFAULT_LOCALE);

export const applyLocale = (locale: string, reload?: boolean) => {
  i18n.changeLanguage(locale);
  dayjs.locale(locale);
  moment.locale(locale);
  setLocaleCookie(locale);

  if (reload) {
    // context(alexandrchebotar, 2024-12-06): need to reload page to be sure that Liveview content also changed the locale
    window.location.reload();
  }
};

interface InitLocaleOptions {
  onPortugueseDetected?: () => void;
}

export const initLocale = ({ onPortugueseDetected }: InitLocaleOptions = {}) => {
  // context(alexandrchebotar, 2024-12-10): use DEFAULT_LOCALE without setting locale to cookies when the feature flag is disabled
  if (isEnabledFeature(FeatureFlag.LOCALES)) {
    const { pathname } = window.location;
    const isNumberPage = pathname.includes("number/");
    const isOnboardingPage = pathname.includes("onboarding/");

    const currentLocale = getLocaleCookie();

    if (currentLocale) {
      // context(alexandrchebotar, 2024-12-06): renew cookie value  to prevent 400-days limit
      applyLocale(currentLocale);
      return currentLocale;
      // context(alexandrchebotar, 2024-12-11): skip autodetecting outside of Onboarding flow or Number page
    } else if (isNumberPage || isOnboardingPage) {
      const locales = localeOptions.map(({ value }) => value);

      // context(alexandrchebotar, 2024-12-06): set first valid navigator language as locale or show prompt if callback is provided
      const targetLocale = navigator.languages.find((code) => locales.includes(code.slice(0, 2)))?.slice(0, 2);

      if (targetLocale) {
        // context(alexandrchebotar, 2024-12-11): show prompt on Number pages
        if (
          isNumberPage &&
          targetLocale === "pt" &&
          onPortugueseDetected &&
          !getStorageSwitch(INFO_PORTUGUESE_MODAL_LOCAL_STORAGE_KEY)
        ) {
          onPortugueseDetected();
          return DEFAULT_LOCALE;
        } else {
          applyLocale(targetLocale, true);
          return targetLocale;
        }
      }

      // context(alexandrchebotar, 2024-12-09): use default locale if there is no supported navigator language
      applyLocale(DEFAULT_LOCALE);
      return DEFAULT_LOCALE;
    }
  }

  return DEFAULT_LOCALE;
};
