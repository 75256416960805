import { Box } from "grommet";
import React from "react";
import styled from "styled-components";

import { ColorPurpleDark, ColorPurpleLightest } from "ds/Core";

import { InfoIconOutline } from "../icons/InfoIconOutline";

const StyledContainer = styled(Box)`
  background: ${ColorPurpleLightest};
  border: 1px solid ${ColorPurpleDark};
  box-sizing: border-box;
  border-radius: 8px;
`;

const SUCCESS_TYPE = "success";

export const CardNotify = ({ type = SUCCESS_TYPE, children, ...props }) => {
  const renderIcon = () => {
    switch (type) {
      case SUCCESS_TYPE:
        return <InfoIconOutline fill={ColorPurpleDark} />;
      default:
        return;
    }
  };
  return (
    <StyledContainer type={type} direction="row" pad="18px" {...props}>
      <Box margin={{ right: "16px" }}>{renderIcon()}</Box>
      {children}
    </StyledContainer>
  );
};
