/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

import * as Types from "../../generated/types";

export type OperatorAvailabilityFragment = {
  __typename: "OperatorAvailability";
  accountUuid: string | null;
  isAvailable: boolean | null;
  numberUuid: string | null;
  uuid: string | null;
};

export const OperatorAvailabilityFragmentDoc = gql`
  fragment OperatorAvailabilityFragment on OperatorAvailability {
    accountUuid
    isAvailable
    numberUuid
    uuid
  }
`;
