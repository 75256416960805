import * as React from "react";

import { ColorRedBase } from "ds/Core";

import { TDefaultIconProps } from "./types";

export const Warning = ({ size = 16, fill = ColorRedBase, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      id="icon-/-16-/-status-warning-outline"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <path
        d="M8,2 L14.6666667,14 L1.33333333,14 L8,2 Z M8,6 L8,10 M8,10.6666667 L8,12"
        id="Group"
        stroke={fill}
        strokeWidth="1.5"
      ></path>
    </g>
  </svg>
);
