/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Heading, Text } from "grommet";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ColorGrayDark } from "ds/Core";
import { ConnectionLostIconOutline } from "lib/icons/ConnectionLostIconOutline";

export const ConnectionLost = ({ lost }) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!lost) {
      document.textContent = "Turn";
      history.goBack();
    }
    if (lost) {
      const controller = new window.AbortController();
      controller.abort();
      document.title = "522 Connection Timed Out";
      console.error("Internet Connection Timed Out");
    }
  }, [lost]);

  return (
    <Box pad="large" align="center" justify="center" direction="column" height="100vh">
      <ConnectionLostIconOutline />
      <Heading level="2" margin="32px 0 16px">
        {t("connection-lost.title")}
      </Heading>
      <Box width={{ max: "560px" }}>
        <Text size="large" color={ColorGrayDark} textAlign="center" style={{ whiteSpace: "break-spaces" }}>
          {t("connection-lost.description")}
        </Text>
      </Box>
    </Box>
  );
};
