import { IconsListType } from "@backlight-dev/turnio.design-icons/Icons/types";
import { createElement } from "react";
import ReactDom from "react-dom/server";
import styled from "styled-components";

import {
  BorderColorDefault,
  InteractiveColorActive,
  InteractiveColorDisabled,
  InteractiveColorHover,
  InteractiveColorHoverAlt,
} from "ds/Alias";
import { BorderRadius100, ColorBrandDefault, ColorGrayLightest } from "ds/Core";
import { Icon, IconProps } from "ds/icons";

import { getLabelCheckboxStyles } from "./theme";
import { CheckboxSizesType } from "./types";

const reactSvgComponentToMarkupString = (Component: typeof Icon, props: IconProps) => {
  return `data:image/svg+xml,${encodeURIComponent(
    ReactDom.renderToStaticMarkup(createElement(Component, { ...props }))
  )}`;
};
export const StyledCheckbox = styled.input<{ inputSize: CheckboxSizesType; icon: IconsListType }>`
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;

  &:checked + label {
    &:before {
      content: ${({ inputSize, icon }) =>
        `url(${reactSvgComponentToMarkupString(Icon, {
          color: "interactiveColorActive",
          size: inputSize === "large" ? "18px" : inputSize === "medium" ? "16px" : "14px",
          name: icon,
        })})`};
      background-color: ${ColorBrandDefault};
      border-color: transparent;
    }

    &:hover {
      &:before {
        background-color: ${InteractiveColorHover};
      }
    }

    + svg {
      display: block;
    }
  }

  &:disabled + label {
    &:before {
      background-color: ${ColorGrayLightest};
      border-color: ${BorderColorDefault};
    }
  }

  &:checked {
    &:disabled + label {
      &:before {
        content: ${({ inputSize, icon }) =>
          `url(${reactSvgComponentToMarkupString(Icon, {
            color: "neutralWhite",
            size: inputSize === "large" ? "18px" : inputSize === "medium" ? "16px" : "14px",
            name: icon,
          })})`};
        background-color: ${InteractiveColorDisabled};
        border-color: transparent;
      }
    }
  }
`;

export const StyledLabel = styled.label<{ size: CheckboxSizesType; isPresentText?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:before {
    ${({ size }) => getLabelCheckboxStyles({ size })}
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    content: " ";
    border: 2px solid ${BorderColorDefault};
    border-radius: ${BorderRadius100};
    color: transparent;
    transition: 0.3s;
    line-height: 0px;
    ${({ isPresentText }) => !isPresentText && "margin-right: 0px;"}
  }

  &:hover {
    &:before {
      background-color: ${InteractiveColorHoverAlt};
      border-color: ${InteractiveColorActive};
    }
  }

  &:active {
    &:before {
      background-color: ${InteractiveColorHoverAlt};
      border-color: ${InteractiveColorActive};
    }
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
