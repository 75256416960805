/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { TriggerMetaDetailsFragment } from "./fragments.generated";
import { NotebookDetailsFragment } from "./fragments.generated";
import { SampleNotebookDetailsFragment } from "./fragments.generated";
import { StackContainerDetailsFragment } from "./fragments.generated";
import { StackContainerPreviewFragment } from "./fragments.generated";
import { StackContainerPreviewMinFragment } from "./fragments.generated";
import { TriggerDetailsFragment } from "./fragments.generated";
import { ExpressionDetailsFragment } from "./fragments.generated";
import { TriggerMetaDetailsFragmentDoc } from "./fragments.generated";
import { NotebookDetailsFragmentDoc } from "./fragments.generated";
import { SampleNotebookDetailsFragmentDoc } from "./fragments.generated";
import { StackContainerDetailsFragmentDoc } from "./fragments.generated";
import { StackContainerPreviewFragmentDoc } from "./fragments.generated";
import { StackContainerPreviewMinFragmentDoc } from "./fragments.generated";
import { TriggerDetailsFragmentDoc } from "./fragments.generated";
import { ExpressionDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type GenerateCodeForCardsQueryVariables = Types.Exact<{
  cards: Array<Types.StackCardGeneratorIo> | Types.StackCardGeneratorIo;
}>;

export type GenerateCodeForCardsQuery = {
  __typename: "RootQueryType";
  generateCodeForCards: { __typename: "NotebookCellCode"; value: string; type: Types.NotebookCellType };
};

export type GenerateCodeForTriggersQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  triggers: Array<Types.TriggerIo> | Types.TriggerIo;
}>;

export type GenerateCodeForTriggersQuery = {
  __typename: "RootQueryType";
  generateCodeForTriggers: {
    __typename: "NotebookCellCode";
    value: string;
    type: Types.NotebookCellType;
    issues: Array<{
      __typename: "StackIssue";
      type: Types.StackIssueType;
      line: number | null;
      column: number | null;
      snippet: string | null;
      description: string;
    }>;
  };
};

export type GenerateTriggerMetaQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  triggers: Array<Types.TriggerIo> | Types.TriggerIo;
}>;

export type GenerateTriggerMetaQuery = {
  __typename: "RootQueryType";
  generateCodeForTriggers: { __typename: "NotebookCellCode" } & TriggerMetaDetailsFragment;
};

export type ParseNotebookQueryVariables = Types.Exact<{
  notebook: Types.NotebookIo;
  numberUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type ParseNotebookQuery = {
  __typename: "RootQueryType";
  parseNotebook: ({ __typename: "NotebookType" } & NotebookDetailsFragment) | null;
};

export type ListSampleNotebooksQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ListSampleNotebooksQuery = {
  __typename: "RootQueryType";
  listSampleNotebooks: Array<{ __typename: "SampleNotebook" } & SampleNotebookDetailsFragment>;
};

export type GetStackContainerQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
}>;

export type GetStackContainerQuery = {
  __typename: "RootQueryType";
  getStackContainer: { __typename: "StackContainer" } & StackContainerDetailsFragment;
};

export type GetNotebookRevisionsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  onlyNamedRevisions?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type GetNotebookRevisionsQuery = {
  __typename: "RootQueryType";
  getStackContainer: {
    __typename: "StackContainer";
    uuid: string;
    notebookRevisions: {
      __typename: "NotebookRevisionConnection";
      hasNextPage: boolean;
      revisions: Array<{
        __typename: "NotebookRevisionPreview";
        accountUuid: string | null;
        insertedAt: any;
        uuid: string;
        versionName: string | null;
      } | null>;
    };
  };
};

export type GetNotebookRevisionQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  notebookRevisionUuid: Types.Scalars["ID"];
}>;

export type GetNotebookRevisionQuery = {
  __typename: "RootQueryType";
  getNotebookRevision: {
    __typename: "NotebookRevision";
    accountUuid: string | null;
    insertedAt: any;
    uuid: string;
    versionName: string | null;
    notebook: { __typename: "NotebookType" } & NotebookDetailsFragment;
  };
};

export type CreateStackDataExportMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  objectUuid: Types.Scalars["ID"];
  type: Types.Scalars["String"];
}>;

export type CreateStackDataExportMutation = {
  __typename: "RootMutationType";
  createStackDataExport: { __typename: "Export"; status: string | null; uuid: string | null } | null;
};

export type ListStackContainersPreviewsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy?: Types.InputMaybe<Types.Scalars["String"]>;
  search?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type ListStackContainersPreviewsQuery = {
  __typename: "RootQueryType";
  listStackContainersPreviews: {
    __typename: "StackContainerPreviewsResults";
    count: number;
    stackContainerPreviews: Array<{ __typename: "StackContainerPreview" } & StackContainerPreviewFragment>;
  };
};

export type ListStackContainersPreviewsMinQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy?: Types.InputMaybe<Types.Scalars["String"]>;
  search?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type ListStackContainersPreviewsMinQuery = {
  __typename: "RootQueryType";
  listStackContainersPreviews: {
    __typename: "StackContainerPreviewsResults";
    count: number;
    stackContainerPreviews: Array<{ __typename: "StackContainerPreview" } & StackContainerPreviewMinFragment>;
  };
};

export type ListStackContainersQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
}>;

export type ListStackContainersQuery = {
  __typename: "RootQueryType";
  listStackContainers: Array<{
    __typename: "StackContainer";
    uuid: string;
    name: string;
    updatedAt: any;
    isNotebookValid: boolean | null;
    isCanvas: boolean;
    triggers: Array<{ __typename: "Trigger" } & TriggerDetailsFragment>;
  }>;
};

export type GenerateCodeForExpressionQueryVariables = Types.Exact<{
  items: Array<Types.ExpressionBasicIo> | Types.ExpressionBasicIo;
}>;

export type GenerateCodeForExpressionQuery = { __typename: "RootQueryType"; generateCodeForExpression: string };

export type ParseExpressionQueryVariables = Types.Exact<{
  expression: Types.Scalars["String"];
}>;

export type ParseExpressionQuery = {
  __typename: "RootQueryType";
  parseExpression: { __typename: "Expression" } & ExpressionDetailsFragment;
};

export type ListJourneyGlobalDataQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
}>;

export type ListJourneyGlobalDataQuery = {
  __typename: "RootQueryType";
  listJourneyGlobalData: Array<{
    __typename: "JourneyData";
    uuid: string;
    name: string;
    data:
      | {
          __typename: "JourneyDataDictionary";
          id: string;
          type: Types.JourneyDataPayloadType;
          dictionary: Array<{ __typename: "KeyValuePair"; key: string; value: string | null }>;
        }
      | {
          __typename: "JourneyDataTable";
          id: string;
          type: Types.JourneyDataPayloadType;
          table: {
            __typename: "JourneyTable";
            columns: Array<{ __typename: "JourneyTableColumn"; id: string; index: number; key: string }>;
            rows: Array<{
              __typename: "JourneyTableRow";
              id: string;
              index: number;
              items: Array<{ __typename: "KeyValuePair"; key: string; value: string | null }>;
            }>;
          };
        };
  }>;
};

export type ListJourneyLocalDataQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
}>;

export type ListJourneyLocalDataQuery = {
  __typename: "RootQueryType";
  listJourneyLocalData: Array<{
    __typename: "JourneyData";
    uuid: string;
    name: string;
    data:
      | {
          __typename: "JourneyDataDictionary";
          id: string;
          type: Types.JourneyDataPayloadType;
          dictionary: Array<{ __typename: "KeyValuePair"; key: string; value: string | null }>;
        }
      | {
          __typename: "JourneyDataTable";
          id: string;
          type: Types.JourneyDataPayloadType;
          table: {
            __typename: "JourneyTable";
            columns: Array<{ __typename: "JourneyTableColumn"; id: string; index: number; key: string }>;
            rows: Array<{
              __typename: "JourneyTableRow";
              id: string;
              index: number;
              items: Array<{ __typename: "KeyValuePair"; key: string; value: string | null }>;
            }>;
          };
        };
  }>;
};

export const GenerateCodeForCardsDocument = gql`
  query generateCodeForCards($cards: [StackCardGeneratorIo!]!) {
    generateCodeForCards(cards: $cards) {
      value
      type
    }
  }
`;

/**
 * __useGenerateCodeForCardsQuery__
 *
 * To run a query within a React component, call `useGenerateCodeForCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateCodeForCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateCodeForCardsQuery({
 *   variables: {
 *      cards: // value for 'cards'
 *   },
 * });
 */
export function useGenerateCodeForCardsQuery(
  baseOptions: Apollo.QueryHookOptions<GenerateCodeForCardsQuery, GenerateCodeForCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateCodeForCardsQuery, GenerateCodeForCardsQueryVariables>(
    GenerateCodeForCardsDocument,
    options
  );
}
export function useGenerateCodeForCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenerateCodeForCardsQuery, GenerateCodeForCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateCodeForCardsQuery, GenerateCodeForCardsQueryVariables>(
    GenerateCodeForCardsDocument,
    options
  );
}
export type GenerateCodeForCardsQueryHookResult = ReturnType<typeof useGenerateCodeForCardsQuery>;
export type GenerateCodeForCardsLazyQueryHookResult = ReturnType<typeof useGenerateCodeForCardsLazyQuery>;
export type GenerateCodeForCardsQueryResult = Apollo.QueryResult<
  GenerateCodeForCardsQuery,
  GenerateCodeForCardsQueryVariables
>;
export const GenerateCodeForTriggersDocument = gql`
  query generateCodeForTriggers($numberUuid: ID!, $triggers: [TriggerIo!]!) {
    generateCodeForTriggers(numberUuid: $numberUuid, triggers: $triggers) {
      value
      type
      issues {
        type
        line
        column
        snippet
        description
      }
    }
  }
`;

/**
 * __useGenerateCodeForTriggersQuery__
 *
 * To run a query within a React component, call `useGenerateCodeForTriggersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateCodeForTriggersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateCodeForTriggersQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      triggers: // value for 'triggers'
 *   },
 * });
 */
export function useGenerateCodeForTriggersQuery(
  baseOptions: Apollo.QueryHookOptions<GenerateCodeForTriggersQuery, GenerateCodeForTriggersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateCodeForTriggersQuery, GenerateCodeForTriggersQueryVariables>(
    GenerateCodeForTriggersDocument,
    options
  );
}
export function useGenerateCodeForTriggersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenerateCodeForTriggersQuery, GenerateCodeForTriggersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateCodeForTriggersQuery, GenerateCodeForTriggersQueryVariables>(
    GenerateCodeForTriggersDocument,
    options
  );
}
export type GenerateCodeForTriggersQueryHookResult = ReturnType<typeof useGenerateCodeForTriggersQuery>;
export type GenerateCodeForTriggersLazyQueryHookResult = ReturnType<typeof useGenerateCodeForTriggersLazyQuery>;
export type GenerateCodeForTriggersQueryResult = Apollo.QueryResult<
  GenerateCodeForTriggersQuery,
  GenerateCodeForTriggersQueryVariables
>;
export const GenerateTriggerMetaDocument = gql`
  query generateTriggerMeta($numberUuid: ID!, $triggers: [TriggerIo!]!) {
    generateCodeForTriggers(numberUuid: $numberUuid, triggers: $triggers) {
      ...TriggerMetaDetails
    }
  }
  ${TriggerMetaDetailsFragmentDoc}
`;

/**
 * __useGenerateTriggerMetaQuery__
 *
 * To run a query within a React component, call `useGenerateTriggerMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateTriggerMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateTriggerMetaQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      triggers: // value for 'triggers'
 *   },
 * });
 */
export function useGenerateTriggerMetaQuery(
  baseOptions: Apollo.QueryHookOptions<GenerateTriggerMetaQuery, GenerateTriggerMetaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateTriggerMetaQuery, GenerateTriggerMetaQueryVariables>(
    GenerateTriggerMetaDocument,
    options
  );
}
export function useGenerateTriggerMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenerateTriggerMetaQuery, GenerateTriggerMetaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateTriggerMetaQuery, GenerateTriggerMetaQueryVariables>(
    GenerateTriggerMetaDocument,
    options
  );
}
export type GenerateTriggerMetaQueryHookResult = ReturnType<typeof useGenerateTriggerMetaQuery>;
export type GenerateTriggerMetaLazyQueryHookResult = ReturnType<typeof useGenerateTriggerMetaLazyQuery>;
export type GenerateTriggerMetaQueryResult = Apollo.QueryResult<
  GenerateTriggerMetaQuery,
  GenerateTriggerMetaQueryVariables
>;
export const ParseNotebookDocument = gql`
  query parseNotebook($notebook: NotebookIo!, $numberUuid: ID) {
    parseNotebook(notebook: $notebook, numberUuid: $numberUuid) {
      ...NotebookDetails
    }
  }
  ${NotebookDetailsFragmentDoc}
`;

/**
 * __useParseNotebookQuery__
 *
 * To run a query within a React component, call `useParseNotebookQuery` and pass it any options that fit your needs.
 * When your component renders, `useParseNotebookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParseNotebookQuery({
 *   variables: {
 *      notebook: // value for 'notebook'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useParseNotebookQuery(
  baseOptions: Apollo.QueryHookOptions<ParseNotebookQuery, ParseNotebookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ParseNotebookQuery, ParseNotebookQueryVariables>(ParseNotebookDocument, options);
}
export function useParseNotebookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ParseNotebookQuery, ParseNotebookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ParseNotebookQuery, ParseNotebookQueryVariables>(ParseNotebookDocument, options);
}
export type ParseNotebookQueryHookResult = ReturnType<typeof useParseNotebookQuery>;
export type ParseNotebookLazyQueryHookResult = ReturnType<typeof useParseNotebookLazyQuery>;
export type ParseNotebookQueryResult = Apollo.QueryResult<ParseNotebookQuery, ParseNotebookQueryVariables>;
export const ListSampleNotebooksDocument = gql`
  query listSampleNotebooks {
    listSampleNotebooks {
      ...SampleNotebookDetails
    }
  }
  ${SampleNotebookDetailsFragmentDoc}
`;

/**
 * __useListSampleNotebooksQuery__
 *
 * To run a query within a React component, call `useListSampleNotebooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSampleNotebooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSampleNotebooksQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSampleNotebooksQuery(
  baseOptions?: Apollo.QueryHookOptions<ListSampleNotebooksQuery, ListSampleNotebooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSampleNotebooksQuery, ListSampleNotebooksQueryVariables>(
    ListSampleNotebooksDocument,
    options
  );
}
export function useListSampleNotebooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSampleNotebooksQuery, ListSampleNotebooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSampleNotebooksQuery, ListSampleNotebooksQueryVariables>(
    ListSampleNotebooksDocument,
    options
  );
}
export type ListSampleNotebooksQueryHookResult = ReturnType<typeof useListSampleNotebooksQuery>;
export type ListSampleNotebooksLazyQueryHookResult = ReturnType<typeof useListSampleNotebooksLazyQuery>;
export type ListSampleNotebooksQueryResult = Apollo.QueryResult<
  ListSampleNotebooksQuery,
  ListSampleNotebooksQueryVariables
>;
export const GetStackContainerDocument = gql`
  query getStackContainer($numberUuid: ID!, $stackContainerUuid: ID!) {
    getStackContainer(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      ...StackContainerDetails
    }
  }
  ${StackContainerDetailsFragmentDoc}
`;

/**
 * __useGetStackContainerQuery__
 *
 * To run a query within a React component, call `useGetStackContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStackContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStackContainerQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *   },
 * });
 */
export function useGetStackContainerQuery(
  baseOptions: Apollo.QueryHookOptions<GetStackContainerQuery, GetStackContainerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStackContainerQuery, GetStackContainerQueryVariables>(GetStackContainerDocument, options);
}
export function useGetStackContainerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStackContainerQuery, GetStackContainerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStackContainerQuery, GetStackContainerQueryVariables>(
    GetStackContainerDocument,
    options
  );
}
export type GetStackContainerQueryHookResult = ReturnType<typeof useGetStackContainerQuery>;
export type GetStackContainerLazyQueryHookResult = ReturnType<typeof useGetStackContainerLazyQuery>;
export type GetStackContainerQueryResult = Apollo.QueryResult<GetStackContainerQuery, GetStackContainerQueryVariables>;
export const GetNotebookRevisionsDocument = gql`
  query getNotebookRevisions(
    $numberUuid: ID!
    $stackContainerUuid: ID!
    $limit: Int
    $offset: Int
    $onlyNamedRevisions: Boolean
  ) {
    getStackContainer(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      notebookRevisions(limit: $limit, offset: $offset, onlyNamedRevisions: $onlyNamedRevisions) {
        hasNextPage
        revisions {
          accountUuid
          insertedAt
          uuid
          versionName
        }
      }
    }
  }
`;

/**
 * __useGetNotebookRevisionsQuery__
 *
 * To run a query within a React component, call `useGetNotebookRevisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotebookRevisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotebookRevisionsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      onlyNamedRevisions: // value for 'onlyNamedRevisions'
 *   },
 * });
 */
export function useGetNotebookRevisionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotebookRevisionsQuery, GetNotebookRevisionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotebookRevisionsQuery, GetNotebookRevisionsQueryVariables>(
    GetNotebookRevisionsDocument,
    options
  );
}
export function useGetNotebookRevisionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotebookRevisionsQuery, GetNotebookRevisionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotebookRevisionsQuery, GetNotebookRevisionsQueryVariables>(
    GetNotebookRevisionsDocument,
    options
  );
}
export type GetNotebookRevisionsQueryHookResult = ReturnType<typeof useGetNotebookRevisionsQuery>;
export type GetNotebookRevisionsLazyQueryHookResult = ReturnType<typeof useGetNotebookRevisionsLazyQuery>;
export type GetNotebookRevisionsQueryResult = Apollo.QueryResult<
  GetNotebookRevisionsQuery,
  GetNotebookRevisionsQueryVariables
>;
export const GetNotebookRevisionDocument = gql`
  query getNotebookRevision($numberUuid: ID!, $notebookRevisionUuid: ID!) {
    getNotebookRevision(numberUuid: $numberUuid, notebookRevisionUuid: $notebookRevisionUuid) {
      accountUuid
      insertedAt
      uuid
      versionName
      notebook {
        ...NotebookDetails
      }
    }
  }
  ${NotebookDetailsFragmentDoc}
`;

/**
 * __useGetNotebookRevisionQuery__
 *
 * To run a query within a React component, call `useGetNotebookRevisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotebookRevisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotebookRevisionQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      notebookRevisionUuid: // value for 'notebookRevisionUuid'
 *   },
 * });
 */
export function useGetNotebookRevisionQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotebookRevisionQuery, GetNotebookRevisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotebookRevisionQuery, GetNotebookRevisionQueryVariables>(
    GetNotebookRevisionDocument,
    options
  );
}
export function useGetNotebookRevisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotebookRevisionQuery, GetNotebookRevisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotebookRevisionQuery, GetNotebookRevisionQueryVariables>(
    GetNotebookRevisionDocument,
    options
  );
}
export type GetNotebookRevisionQueryHookResult = ReturnType<typeof useGetNotebookRevisionQuery>;
export type GetNotebookRevisionLazyQueryHookResult = ReturnType<typeof useGetNotebookRevisionLazyQuery>;
export type GetNotebookRevisionQueryResult = Apollo.QueryResult<
  GetNotebookRevisionQuery,
  GetNotebookRevisionQueryVariables
>;
export const CreateStackDataExportDocument = gql`
  mutation createStackDataExport($numberUuid: ID!, $objectUuid: ID!, $type: String!) {
    createStackDataExport(numberUuid: $numberUuid, objectUuid: $objectUuid, type: $type) {
      status
      uuid
    }
  }
`;
export type CreateStackDataExportMutationFn = Apollo.MutationFunction<
  CreateStackDataExportMutation,
  CreateStackDataExportMutationVariables
>;

/**
 * __useCreateStackDataExportMutation__
 *
 * To run a mutation, you first call `useCreateStackDataExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStackDataExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStackDataExportMutation, { data, loading, error }] = useCreateStackDataExportMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      objectUuid: // value for 'objectUuid'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateStackDataExportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStackDataExportMutation, CreateStackDataExportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStackDataExportMutation, CreateStackDataExportMutationVariables>(
    CreateStackDataExportDocument,
    options
  );
}
export type CreateStackDataExportMutationHookResult = ReturnType<typeof useCreateStackDataExportMutation>;
export type CreateStackDataExportMutationResult = Apollo.MutationResult<CreateStackDataExportMutation>;
export type CreateStackDataExportMutationOptions = Apollo.BaseMutationOptions<
  CreateStackDataExportMutation,
  CreateStackDataExportMutationVariables
>;
export const ListStackContainersPreviewsDocument = gql`
  query listStackContainersPreviews($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String, $search: String) {
    listStackContainersPreviews(
      numberUuid: $numberUuid
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      search: $search
    ) {
      count
      stackContainerPreviews {
        ...StackContainerPreview
      }
    }
  }
  ${StackContainerPreviewFragmentDoc}
`;

/**
 * __useListStackContainersPreviewsQuery__
 *
 * To run a query within a React component, call `useListStackContainersPreviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStackContainersPreviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStackContainersPreviewsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListStackContainersPreviewsQuery(
  baseOptions: Apollo.QueryHookOptions<ListStackContainersPreviewsQuery, ListStackContainersPreviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListStackContainersPreviewsQuery, ListStackContainersPreviewsQueryVariables>(
    ListStackContainersPreviewsDocument,
    options
  );
}
export function useListStackContainersPreviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListStackContainersPreviewsQuery, ListStackContainersPreviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListStackContainersPreviewsQuery, ListStackContainersPreviewsQueryVariables>(
    ListStackContainersPreviewsDocument,
    options
  );
}
export type ListStackContainersPreviewsQueryHookResult = ReturnType<typeof useListStackContainersPreviewsQuery>;
export type ListStackContainersPreviewsLazyQueryHookResult = ReturnType<typeof useListStackContainersPreviewsLazyQuery>;
export type ListStackContainersPreviewsQueryResult = Apollo.QueryResult<
  ListStackContainersPreviewsQuery,
  ListStackContainersPreviewsQueryVariables
>;
export const ListStackContainersPreviewsMinDocument = gql`
  query listStackContainersPreviewsMin($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String, $search: String) {
    listStackContainersPreviews(
      numberUuid: $numberUuid
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      search: $search
    ) {
      count
      stackContainerPreviews {
        ...StackContainerPreviewMin
      }
    }
  }
  ${StackContainerPreviewMinFragmentDoc}
`;

/**
 * __useListStackContainersPreviewsMinQuery__
 *
 * To run a query within a React component, call `useListStackContainersPreviewsMinQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStackContainersPreviewsMinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStackContainersPreviewsMinQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListStackContainersPreviewsMinQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListStackContainersPreviewsMinQuery,
    ListStackContainersPreviewsMinQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListStackContainersPreviewsMinQuery, ListStackContainersPreviewsMinQueryVariables>(
    ListStackContainersPreviewsMinDocument,
    options
  );
}
export function useListStackContainersPreviewsMinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListStackContainersPreviewsMinQuery,
    ListStackContainersPreviewsMinQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListStackContainersPreviewsMinQuery, ListStackContainersPreviewsMinQueryVariables>(
    ListStackContainersPreviewsMinDocument,
    options
  );
}
export type ListStackContainersPreviewsMinQueryHookResult = ReturnType<typeof useListStackContainersPreviewsMinQuery>;
export type ListStackContainersPreviewsMinLazyQueryHookResult = ReturnType<
  typeof useListStackContainersPreviewsMinLazyQuery
>;
export type ListStackContainersPreviewsMinQueryResult = Apollo.QueryResult<
  ListStackContainersPreviewsMinQuery,
  ListStackContainersPreviewsMinQueryVariables
>;
export const ListStackContainersDocument = gql`
  query listStackContainers($numberUuid: ID!) {
    listStackContainers(numberUuid: $numberUuid) {
      uuid
      name
      updatedAt
      isNotebookValid
      isCanvas
      triggers {
        ...TriggerDetails
      }
    }
  }
  ${TriggerDetailsFragmentDoc}
`;

/**
 * __useListStackContainersQuery__
 *
 * To run a query within a React component, call `useListStackContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStackContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStackContainersQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useListStackContainersQuery(
  baseOptions: Apollo.QueryHookOptions<ListStackContainersQuery, ListStackContainersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListStackContainersQuery, ListStackContainersQueryVariables>(
    ListStackContainersDocument,
    options
  );
}
export function useListStackContainersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListStackContainersQuery, ListStackContainersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListStackContainersQuery, ListStackContainersQueryVariables>(
    ListStackContainersDocument,
    options
  );
}
export type ListStackContainersQueryHookResult = ReturnType<typeof useListStackContainersQuery>;
export type ListStackContainersLazyQueryHookResult = ReturnType<typeof useListStackContainersLazyQuery>;
export type ListStackContainersQueryResult = Apollo.QueryResult<
  ListStackContainersQuery,
  ListStackContainersQueryVariables
>;
export const GenerateCodeForExpressionDocument = gql`
  query generateCodeForExpression($items: [ExpressionBasicIo!]!) {
    generateCodeForExpression(items: $items)
  }
`;

/**
 * __useGenerateCodeForExpressionQuery__
 *
 * To run a query within a React component, call `useGenerateCodeForExpressionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateCodeForExpressionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateCodeForExpressionQuery({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useGenerateCodeForExpressionQuery(
  baseOptions: Apollo.QueryHookOptions<GenerateCodeForExpressionQuery, GenerateCodeForExpressionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateCodeForExpressionQuery, GenerateCodeForExpressionQueryVariables>(
    GenerateCodeForExpressionDocument,
    options
  );
}
export function useGenerateCodeForExpressionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenerateCodeForExpressionQuery, GenerateCodeForExpressionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateCodeForExpressionQuery, GenerateCodeForExpressionQueryVariables>(
    GenerateCodeForExpressionDocument,
    options
  );
}
export type GenerateCodeForExpressionQueryHookResult = ReturnType<typeof useGenerateCodeForExpressionQuery>;
export type GenerateCodeForExpressionLazyQueryHookResult = ReturnType<typeof useGenerateCodeForExpressionLazyQuery>;
export type GenerateCodeForExpressionQueryResult = Apollo.QueryResult<
  GenerateCodeForExpressionQuery,
  GenerateCodeForExpressionQueryVariables
>;
export const ParseExpressionDocument = gql`
  query parseExpression($expression: String!) {
    parseExpression(expression: $expression) {
      ...ExpressionDetails
    }
  }
  ${ExpressionDetailsFragmentDoc}
`;

/**
 * __useParseExpressionQuery__
 *
 * To run a query within a React component, call `useParseExpressionQuery` and pass it any options that fit your needs.
 * When your component renders, `useParseExpressionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParseExpressionQuery({
 *   variables: {
 *      expression: // value for 'expression'
 *   },
 * });
 */
export function useParseExpressionQuery(
  baseOptions: Apollo.QueryHookOptions<ParseExpressionQuery, ParseExpressionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ParseExpressionQuery, ParseExpressionQueryVariables>(ParseExpressionDocument, options);
}
export function useParseExpressionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ParseExpressionQuery, ParseExpressionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ParseExpressionQuery, ParseExpressionQueryVariables>(ParseExpressionDocument, options);
}
export type ParseExpressionQueryHookResult = ReturnType<typeof useParseExpressionQuery>;
export type ParseExpressionLazyQueryHookResult = ReturnType<typeof useParseExpressionLazyQuery>;
export type ParseExpressionQueryResult = Apollo.QueryResult<ParseExpressionQuery, ParseExpressionQueryVariables>;
export const ListJourneyGlobalDataDocument = gql`
  query listJourneyGlobalData($numberUuid: ID!) {
    listJourneyGlobalData(numberUuid: $numberUuid) {
      uuid
      name
      data {
        id
        type
        ... on JourneyDataDictionary {
          dictionary {
            key
            value
          }
        }
        ... on JourneyDataTable {
          table {
            columns {
              id
              index
              key
            }
            rows {
              id
              index
              items {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListJourneyGlobalDataQuery__
 *
 * To run a query within a React component, call `useListJourneyGlobalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJourneyGlobalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJourneyGlobalDataQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useListJourneyGlobalDataQuery(
  baseOptions: Apollo.QueryHookOptions<ListJourneyGlobalDataQuery, ListJourneyGlobalDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListJourneyGlobalDataQuery, ListJourneyGlobalDataQueryVariables>(
    ListJourneyGlobalDataDocument,
    options
  );
}
export function useListJourneyGlobalDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListJourneyGlobalDataQuery, ListJourneyGlobalDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListJourneyGlobalDataQuery, ListJourneyGlobalDataQueryVariables>(
    ListJourneyGlobalDataDocument,
    options
  );
}
export type ListJourneyGlobalDataQueryHookResult = ReturnType<typeof useListJourneyGlobalDataQuery>;
export type ListJourneyGlobalDataLazyQueryHookResult = ReturnType<typeof useListJourneyGlobalDataLazyQuery>;
export type ListJourneyGlobalDataQueryResult = Apollo.QueryResult<
  ListJourneyGlobalDataQuery,
  ListJourneyGlobalDataQueryVariables
>;
export const ListJourneyLocalDataDocument = gql`
  query listJourneyLocalData($numberUuid: ID!, $stackContainerUuid: ID!) {
    listJourneyLocalData(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      name
      data {
        id
        type
        ... on JourneyDataDictionary {
          dictionary {
            key
            value
          }
        }
        ... on JourneyDataTable {
          table {
            columns {
              id
              index
              key
            }
            rows {
              id
              index
              items {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListJourneyLocalDataQuery__
 *
 * To run a query within a React component, call `useListJourneyLocalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJourneyLocalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJourneyLocalDataQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *   },
 * });
 */
export function useListJourneyLocalDataQuery(
  baseOptions: Apollo.QueryHookOptions<ListJourneyLocalDataQuery, ListJourneyLocalDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListJourneyLocalDataQuery, ListJourneyLocalDataQueryVariables>(
    ListJourneyLocalDataDocument,
    options
  );
}
export function useListJourneyLocalDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListJourneyLocalDataQuery, ListJourneyLocalDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListJourneyLocalDataQuery, ListJourneyLocalDataQueryVariables>(
    ListJourneyLocalDataDocument,
    options
  );
}
export type ListJourneyLocalDataQueryHookResult = ReturnType<typeof useListJourneyLocalDataQuery>;
export type ListJourneyLocalDataLazyQueryHookResult = ReturnType<typeof useListJourneyLocalDataLazyQuery>;
export type ListJourneyLocalDataQueryResult = Apollo.QueryResult<
  ListJourneyLocalDataQuery,
  ListJourneyLocalDataQueryVariables
>;
