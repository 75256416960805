import { Box, Button, Text } from "grommet";
import styled from "styled-components";

import { FeedbackColorCritical } from "ds/Alias";
import {
  ColorBrandDefault,
  ColorGrayDarkest,
  ColorGrayLight,
  ColorGrayLightest,
  ColorNeutralWhite,
  ColorPurpleDark,
} from "ds/Core";

export const StyledButton = styled(Button)`
  border: none;
  min-height: 48px;
  font-family: "DM Sans";
  font-size: 15px;
  line-height: 18px;
  &:hover {
    box-shadow: none;
    opacity: 0.7;
  }
`;

export const StyledDeleteButton = styled(StyledButton)<{ btnType?: "primary" }>`
  background: ${({ btnType }) => (btnType === "primary" ? ColorBrandDefault : FeedbackColorCritical)};
  box-shadow: 0px 0px 14px rgba(6, 51, 67, 0.11869);
  border-radius: 28px;
  color: ${ColorNeutralWhite};
  margin-right: 16px;
  font-weight: 600;
`;

export const StyledCancelButton = styled(StyledButton)`
  background: transparent;
  color: ${ColorPurpleDark};
`;

export const StyledConfirmTitle = styled(Text)`
  font-family: "DM Sans";
  font-weight: ${({ weight }) => weight || "normal"};
  font-size: 18px;
  line-height: 26px;
  color: ${ColorGrayDarkest};

  p {
    margin: 0 0 1em;
    &:last-of-type {
      margin: 0;
    }
  }
`;

export const StyledConfirmContainer = styled(Box)`
  background: ${ColorNeutralWhite};
  border: 1px solid ${ColorGrayLight};
  box-shadow: 0px 4px 10px rgba(13, 31, 38, 0.08);
  border-radius: 5px;
  position: relative;
`;

export const StyledCloseButton = styled(StyledButton)`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 4px;
  min-height: auto;
  height: 32px;
  width: 32px;
  border-radius: 32px;

  &:hover {
    background: ${ColorGrayLightest};
  }
  &:focus {
    box-shadow: none;
  }
`;
