import React from "react";
import { useTranslation } from "react-i18next";

import { Box } from "ds/Box";
import { Checkbox } from "ds/Checkbox";
import { Modal } from "ds/Modal";
import { Text } from "ds/Typography";
import { BillingTransactionState } from "graphql/generated/types";
import { useNavigation } from "hooks";
import { useModalContext } from "hooks/useModalContext";
import { usePaymentContext } from "hooks/usePaymentContext";

import { Illustration } from "./Illustration";

export const PastDueInvoiceModal = () => {
  const { t } = useTranslation();
  const { pastDueInvoice: visible, updateModal } = useModalContext();
  const { subscription, chargePastDueOnAutomatic, setChargePastDueOnAutomatic } = usePaymentContext();
  const { navigateTo } = useNavigation();

  const amount = (
    subscription?.billingTransactions?.filter(
      (transaction) =>
        transaction.status === BillingTransactionState.PAST_DUE || transaction.status === BillingTransactionState.BILLED
    ) || []
  )
    .reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0)
    .toLocaleString();

  return (
    <Modal
      title={t("payment.past-due-invoice.title")}
      visible={visible}
      type="STEPS"
      primaryAction={{
        onClick: () => {
          updateModal("pastDueInvoice", false);
          setTimeout(navigateTo("payment"), 300);
        },
        text: t("btn.proceed"),
      }}
      secondaryAction={{
        onClick: () => window.open("https://wa.me/16506001008?text=overdue-account", "_blank"),
        text: t("btn.contact"),
      }}
      style={{ maxWidth: "518px" }}
      onHide={() => updateModal("pastDueInvoice", false)}
    >
      <Box gap="24px">
        <Box
          backgroundColor="backgroundColorRed"
          borderRadius="24px"
          justifyContent="flex-end"
          alignItems="center"
          pt="40px"
        >
          <Illustration />
        </Box>
        <Text preset="paragraph1-100" color="textColorSubtle">
          {t("payment.past-due-invoice.description")}
        </Text>
        <Text fontSize="20px" lineHeight="24px" fontWeight="200">
          {t("payment.past-due-invoice.amount", { amount })}
        </Text>
        <Checkbox
          text={t("payment.past-due-invoice.charge-past-due")}
          size="large"
          checked={chargePastDueOnAutomatic}
          onChange={(e) => {
            setChargePastDueOnAutomatic(e.target.checked);
          }}
        />
      </Box>
    </Modal>
  );
};
