import _isString from "lodash/isString.js";
import React from "react";

import { TextColorDefault, TextColorNonessential } from "ds/Alias";
import { Avatar } from "ds/Avatar";
import { Button } from "ds/Button";
import { Text } from "ds/Typography";
import { Icon } from "ds/icons";

import {
  StyledAvatar,
  StyledContent,
  StyledDropdownItem,
  StyledLabelContainer,
  StyledLeftIcon,
  StyledRightIcon,
} from "./styles";
import { DropdownItemProps, DropdownSize, DropdownVariation } from "./types";

const DropdownItem = ({
  size = DropdownSize.LARGE,
  variation = DropdownVariation.PRIMARY,
  disabled,
  selected,
  description,
  icon,
  label,
  secondLevel,
  children,
  position,
  avatar,
  onClick,
  helpText,
  ...rest
}: DropdownItemProps) => {
  const hasLabel = !!label;

  return children ? (
    <>{React.Children.map(children, (child) => ({ ...child, props: { ...child.props, isLevelDrop: true } }))}</>
  ) : (
    <StyledDropdownItem
      size={size}
      variation={variation}
      disabled={disabled}
      open={selected}
      hasSecondLevel={!!description}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      <StyledContent>
        {avatar ? (
          <StyledAvatar size={size} hasLabel={hasLabel}>
            <Avatar {...avatar} size={size === DropdownSize.SMALL ? "xsmall" : description ? "medium" : "small"} />
          </StyledAvatar>
        ) : icon ? (
          <StyledLeftIcon hasLabel={hasLabel} size={size}>
            {React.isValidElement(icon)
              ? React.cloneElement(icon as React.ReactElement<any>, {
                  color: (icon.props.color ?? disabled) ? TextColorNonessential : TextColorDefault,
                })
              : icon}
          </StyledLeftIcon>
        ) : null}
        {label && (
          <StyledLabelContainer>
            {_isString(label) ? (
              <Text wordBreak="break-word" preset={size === "small" ? "paragraph1-100" : "medium100"}>
                {label}
              </Text>
            ) : (
              label
            )}
            {description && (
              <Text preset="caption100" color="textColorSubtle" wordBreak="break-word" lineHeight="150%">
                {description}
              </Text>
            )}
          </StyledLabelContainer>
        )}
      </StyledContent>

      {helpText && (
        <Button
          variation="flat"
          size="xsmall"
          type="button"
          icon={<Icon name="InfoCircleIcon" size="small" color="grayBase" />}
          tooltip={{
            placement: "top-end",
            content: helpText,
            usePortal: false,
          }}
        />
      )}
      {secondLevel ? (
        <StyledRightIcon position={position || "auto"} hasLabel={hasLabel} maxHeight={16}>
          <Icon name="ChevronRightIcon" size="small" color={disabled ? "grayBase" : "iconColorStrokeDefault"} />
        </StyledRightIcon>
      ) : (
        selected && (
          <StyledRightIcon hasLabel={hasLabel} position="right">
            <Icon name="CheckIcon" size="medium" />
          </StyledRightIcon>
        )
      )}
    </StyledDropdownItem>
  );
};

export { DropdownItem };
