export const aliasTokens = {
  color: {
    backgroundColorPink: "#EB80FD",
    backgroundColorPurple: "#C09DFF",
    backgroundColorRed: "#FF643B",
    backgroundColorYellow: "#D8C833",
    backgroundColorBlue: "#00B4DC",
    backgroundColorGreen: "#1DBF73",
    backgroundColorCream: "#F7F2D8",
    backgroundColorBlack: "#000",
    backgroundColorWhite: "#fff",
    backgroundColorGray: "#F9F9FA",
    backgroundColorTest: "#FBD90E",
    interactiveColorHover: "#834fca",
    interactiveColorHoverAlt: "#eee2ff",
    interactiveColorActive: "#d7c0ff",
    interactiveColorDisabled: "#CACCCD",
    interactiveColorAction: "#460095",
    interactiveColorActiveAlt: "#460095",
    interactiveColorHoverGray: "#EEE2FF",
    feedbackColorBackgroundInformative: "#CCF0F8",
    feedbackColorBackgroundCritical: "#FFE1D8",
    feedbackColorBackgroundSuccess: "#D2F2E3",
    feedbackColorBackgroundWarning: "#F7F2D8",
    feedbackColorCritical: "#FF643B",
    feedbackColorSuccess: "#1DBF73",
    feedbackColorInformative: "#00B4DC",
    feedbackColorWarning: "#817224",
    textColorDefault: "#000",
    textColorSubtle: "#5A5F62",
    textColorNonessential: "#9B9EA0",
    textColorAction: "#8654CD",
    textColorOnDark: "#fff",
    iconColorStrokeDefault: "#000",
    iconColorStrokeDisabled: "#CACCCD",
    borderColorDefault: "#E7E8E9",
  },
  iconSize: {
    small: "16px",
    medium: "20px",
    large: "24px",
  },
};

export type AliasColorType =
  | "backgroundColorPink"
  | "backgroundColorPurple"
  | "backgroundColorRed"
  | "backgroundColorYellow"
  | "backgroundColorBlue"
  | "backgroundColorGreen"
  | "backgroundColorCream"
  | "backgroundColorBlack"
  | "backgroundColorWhite"
  | "backgroundColorGray"
  | "backgroundColorTest"
  | "interactiveColorHover"
  | "interactiveColorHoverAlt"
  | "interactiveColorActive"
  | "interactiveColorDisabled"
  | "interactiveColorAction"
  | "interactiveColorActiveAlt"
  | "interactiveColorHoverGray"
  | "feedbackColorBackgroundInformative"
  | "feedbackColorBackgroundCritical"
  | "feedbackColorBackgroundSuccess"
  | "feedbackColorBackgroundWarning"
  | "feedbackColorCritical"
  | "feedbackColorSuccess"
  | "feedbackColorInformative"
  | "feedbackColorWarning"
  | "textColorDefault"
  | "textColorSubtle"
  | "textColorNonessential"
  | "textColorAction"
  | "textColorOnDark"
  | "iconColorStrokeDefault"
  | "iconColorStrokeDisabled"
  | "borderColorDefault";

export type AliasIconSizeType = "small" | "medium" | "large";
