/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

import * as Types from "../../generated/types";

export type PaddleConfigurationFragment = {
  __typename: "PaddleConfiguration";
  sandboxMode: boolean | null;
  vendorId: number;
};

export type PaddleBillingPriceFragment = {
  __typename: "PaddleBillingPrice";
  id: string;
  description: Types.PriceDescriptions | null;
  billingCycle: { __typename: "BillingCycle"; interval: Types.PaddleInterval | null; frequency: number } | null;
  trialPeriod: { __typename: "BillingCycle"; interval: Types.PaddleInterval | null; frequency: number } | null;
  unitPrice: { __typename: "UnitPrice"; amount: string; currencyCode: Types.PaddleCurrencyCode | null } | null;
};

export type PricesConfigurationDetailsFragment = {
  __typename: "PricesConfiguration";
  paddleConfiguration: ({ __typename: "PaddleConfiguration" } & PaddleConfigurationFragment) | null;
  paddleBillingPrices: Array<({ __typename: "PaddleBillingPrice" } & PaddleBillingPriceFragment) | null> | null;
};

export type SubscriptionBillingTransactionDetailsFragment = {
  __typename: "BillingSubscriptionTransaction";
  amount: string;
  billedAt: any | null;
  priceName: Array<string>;
  receiptUrl: string | null;
  paddleId: string;
  checkout: string | null;
  status: Types.BillingTransactionState | null;
};

export type PaymentNumberSubscriptionDetailsFragment = {
  __typename: "NumberSubscription";
  subscriptionId: string | null;
  state: Types.SubscriptionState | null;
  nextPaymentDate: any | null;
  additionalInformation: string | null;
  collectionMode: Types.BillingCollectionMode | null;
  scheduledChangeAction: Types.ScheduledChange | null;
  scheduledChangeAt: any | null;
  billingTransactions: Array<
    { __typename: "BillingSubscriptionTransaction" } & SubscriptionBillingTransactionDetailsFragment
  > | null;
};

export type NumberSubscriptionDetailsFragment = {
  __typename: "NumberSubscription";
  subscriptionId: string | null;
  state: Types.SubscriptionState | null;
  nextPaymentDate: any | null;
  nextPaymentAmount: number | null;
  recurringTransactionAmount: number | null;
  scheduledChangeAction: Types.ScheduledChange | null;
  scheduledChangeAt: any | null;
  nextPaymentDiscount: number | null;
  pausedAt: any | null;
  updateUrl: string | null;
  cancelUrl: string | null;
  collectionMode: Types.BillingCollectionMode | null;
  customerId: string | null;
  priceName: Array<Types.SubscriptionPlan> | null;
  additionalInformation: string | null;
  billingTransactions: Array<
    { __typename: "BillingSubscriptionTransaction" } & SubscriptionBillingTransactionDetailsFragment
  > | null;
};

export type SubscriptionDetailsFragment = {
  __typename: "Number";
  uuid: string | null;
  subscription: ({ __typename: "NumberSubscription" } & NumberSubscriptionDetailsFragment) | null;
};

export const PaddleConfigurationFragmentDoc = gql`
  fragment paddleConfiguration on PaddleConfiguration {
    sandboxMode
    vendorId
  }
`;
export const PaddleBillingPriceFragmentDoc = gql`
  fragment paddleBillingPrice on PaddleBillingPrice {
    id
    description
    billingCycle {
      interval
      frequency
    }
    trialPeriod {
      interval
      frequency
    }
    unitPrice {
      amount
      currencyCode
    }
  }
`;
export const PricesConfigurationDetailsFragmentDoc = gql`
  fragment PricesConfigurationDetails on PricesConfiguration {
    paddleConfiguration {
      ...paddleConfiguration
    }
    paddleBillingPrices {
      ...paddleBillingPrice
    }
  }
  ${PaddleConfigurationFragmentDoc}
  ${PaddleBillingPriceFragmentDoc}
`;
export const SubscriptionBillingTransactionDetailsFragmentDoc = gql`
  fragment SubscriptionBillingTransactionDetails on BillingSubscriptionTransaction {
    amount
    billedAt
    priceName
    receiptUrl
    paddleId
    checkout
    status
  }
`;
export const PaymentNumberSubscriptionDetailsFragmentDoc = gql`
  fragment PaymentNumberSubscriptionDetails on NumberSubscription {
    subscriptionId
    state
    nextPaymentDate
    additionalInformation
    collectionMode
    billingTransactions {
      ...SubscriptionBillingTransactionDetails
    }
    scheduledChangeAction
    scheduledChangeAt
  }
  ${SubscriptionBillingTransactionDetailsFragmentDoc}
`;
export const NumberSubscriptionDetailsFragmentDoc = gql`
  fragment NumberSubscriptionDetails on NumberSubscription {
    subscriptionId
    state
    nextPaymentDate
    nextPaymentAmount
    recurringTransactionAmount
    scheduledChangeAction
    scheduledChangeAt
    nextPaymentDiscount
    pausedAt
    updateUrl
    cancelUrl
    collectionMode
    customerId
    billingTransactions {
      ...SubscriptionBillingTransactionDetails
    }
    priceName
    additionalInformation
    subscriptionId
  }
  ${SubscriptionBillingTransactionDetailsFragmentDoc}
`;
export const SubscriptionDetailsFragmentDoc = gql`
  fragment SubscriptionDetails on Number {
    uuid
    subscription {
      ...NumberSubscriptionDetails
    }
  }
  ${NumberSubscriptionDetailsFragmentDoc}
`;
