import dayjs from "dayjs";

import { BannerStatus } from "ds/Banner/src/types";
import { ColorsType } from "ds/types";
import { Maybe, ScheduledChange, SubscriptionState } from "graphql/generated/types";
import { PaymentBannerStatus, PaymentBannerStatusState } from "utils/context/payment";
import i18n from "utils/i18n";

export const PAYMENT_BANNER_HEIGHT = 64;

export const BILLING_PAYMENT_WINDOW_DAYS = 14;
export const ACCOUNT_PAUSE_DURATION_UNTIL_CANCELLED_DAYS = 90;
export const CANCELLATION_NOTICE_PERIOD_DAYS = 30;

/**
 * Calculates the payment banner status based on the provided data.
 *
 * @param {Object} data - An optional object containing the following properties:
 *   - state: The subscription state (optional).
 *   - scheduledChangeAction: The scheduled change action (optional).
 *   - latestBillDate: The date the latest bill was issued (optional).
 *   - scheduledChangeAt: The scheduled change date (optional).
 *   - now: The current date (optional).
 * @return {PaymentBannerStatusState | undefined} The payment banner status state or undefined.
 */

export const getPaymentBannerStatus = (data?: {
  state?: Maybe<SubscriptionState>;
  scheduledChangeAction?: Maybe<ScheduledChange>;
  latestUnpaidBillDate?: Maybe<Date>;
  scheduledChangeAt?: Maybe<Date>;
  nextPaymentDate?: Maybe<Date>;
  now?: Maybe<Date>;
  pausedDate?: Maybe<Date>;
}): PaymentBannerStatusState | undefined => {
  if (!data) {
    return;
  }
  const { state, scheduledChangeAction, latestUnpaidBillDate, scheduledChangeAt, nextPaymentDate, now, pausedDate } =
    data ?? {};
  const latestBillDueDate = latestUnpaidBillDate
    ? dayjs(latestUnpaidBillDate).add(BILLING_PAYMENT_WINDOW_DAYS, "day")
    : undefined;

  let timeLeft;

  if (state === SubscriptionState.ACTIVE && !scheduledChangeAction) {
    timeLeft = latestBillDueDate ? dayjs(latestBillDueDate).endOf("day") : undefined;
  } else if (scheduledChangeAction === ScheduledChange.PAUSE) {
    timeLeft = scheduledChangeAt ? dayjs(new Date(scheduledChangeAt)).endOf("day") : undefined;
  } else if (state === SubscriptionState.PAUSED && pausedDate) {
    timeLeft = dayjs(pausedDate).add(ACCOUNT_PAUSE_DURATION_UNTIL_CANCELLED_DAYS, "day").endOf("day");
  } else if (state === SubscriptionState.TRIALING && nextPaymentDate) {
    timeLeft = nextPaymentDate ? dayjs(new Date(nextPaymentDate)).endOf("day") : undefined;
  }

  const timeNow = now || new Date();
  const days = timeLeft ? dayjs(timeLeft).diff(timeNow, "days") : 0;
  const hours = timeLeft ? dayjs(timeLeft).diff(timeNow, "hours") : 0;

  if (state === SubscriptionState.PAUSED && pausedDate) {
    if (timeLeft) {
      // If there are between 1 and 30 full days left before the subscription ends show the "Cancel (x days)" status.
      if (days <= 30 && days >= 1) {
        return {
          status: PaymentBannerStatus.CANCEL_DATE,
          days: days <= 1 ? 2 : days,
        };
      }
      // If there is less than one day left before the subscription ends show the "Cancel (x hours)" status.
      if (days < 1 && hours > 0) {
        return {
          status: PaymentBannerStatus.CANCEL_HOURS,
          hours,
        };
      }
      // If the subscription has ended show the "Cancel (0 hours)" status.
      if (hours <= 0) {
        return {
          status: PaymentBannerStatus.CANCEL_HOURS,
          hours: 0,
        };
      }
    }
  }

  if (
    state !== SubscriptionState.PAUSED &&
    state !== SubscriptionState.CANCELED &&
    scheduledChangeAction === ScheduledChange.PAUSE
  ) {
    if (timeLeft) {
      // If there are between 1 and 5 full days left before the subscription ends and more than 24 hours left, show the "Pause (x days)" status.
      if (days <= 5 && days >= 1) {
        return {
          status: PaymentBannerStatus.PAUSE_DATE,
          days: days <= 1 ? 2 : days,
        };
      }
      // If there is full 1 day left before the subscription ends and more than 1 hour left, show the "Pause (x hours)" status.
      if (days < 1 && hours > 0) {
        return {
          status: PaymentBannerStatus.PAUSE_HOURS,
          hours,
        };
      }
      // If there is 1 hour left before the subscription ends or less, show the "Pause(0 hours)" status.
      if (hours <= 0) {
        return {
          status: PaymentBannerStatus.PAUSE_HOURS,
          hours: 0,
        };
      }
    }
  }

  if (state === SubscriptionState.ACTIVE) {
    if (timeLeft) {
      // If there are between 1 and 5 full days left before the subscription ends and more than 24 hours left, show the "Active (x days)" status.
      if (days <= 5) {
        return {
          status: PaymentBannerStatus.ACTIVE_DATE,
          days: hours > 0 ? days : 0,
        };
      }
    }
  }

  if (state === SubscriptionState.PAST_DUE) {
    // If there is no date set for the subscription to end, show the "Past Due" status.
    return {
      status: PaymentBannerStatus.PAST_DUE,
    };
  }

  if (state === SubscriptionState.PAUSED) {
    // If there is no date set for the subscription to end, show the "Paused" status.
    return {
      status: PaymentBannerStatus.PAUSED,
    };
  }

  if (state === SubscriptionState.CANCELED) {
    // If the subscription is canceled, show the "Canceled" status.
    return {
      status: PaymentBannerStatus.CANCELED,
    };
  }

  if (state === SubscriptionState.TRIALING) {
    // If the subscription is trialing, show the "Trialing" status.
    return {
      status: PaymentBannerStatus.TRIALING,
      days,
    };
  }

  if (!state) {
    return { status: PaymentBannerStatus.INACTIVE };
  }

  return;
};

export const getBannerTitle = (data?: PaymentBannerStatusState, nextPaymentDate?: Maybe<Date>) => {
  const { status, days, hours } = data || {};
  if (status === PaymentBannerStatus.ACTIVE_DATE) {
    return i18n.t("payment.banner.active.days", { count: days });
  }

  if (status === PaymentBannerStatus.PAST_DUE) {
    return i18n.t("payment.banner.past-due.default");
  }

  if (status === PaymentBannerStatus.PAUSED) {
    return i18n.t("payment.banner.paused.default");
  }

  if (status === PaymentBannerStatus.PAUSE_DATE) {
    return i18n.t("payment.banner.pause.days", { count: days });
  }

  if (status === PaymentBannerStatus.PAUSE_HOURS) {
    return i18n.t("payment.banner.pause.hours", { count: hours });
  }

  if (status === PaymentBannerStatus.CANCELED) {
    return i18n.t("payment.banner.canceled.default");
  }

  if (status === PaymentBannerStatus.CANCEL_DATE) {
    return i18n.t("payment.banner.cancel.days", { count: days });
  }

  if (status === PaymentBannerStatus.CANCEL_HOURS) {
    return i18n.t("payment.banner.cancel.hours", { count: hours });
  }

  if (status === PaymentBannerStatus.TRIALING) {
    return i18n.t("payment.banner.trialing.days", {
      count: days,
      date: dayjs(nextPaymentDate).endOf("day").format("LL"),
    });
  }

  if (status === PaymentBannerStatus.INACTIVE) {
    return i18n.t("payment.banner.inactive.default");
  }

  return;
};

export const PaymentBannerOptions: {
  [key in `${BannerStatus}`]: {
    bg: ColorsType;
    icon: {
      color: ColorsType;
      label: string;
      animation?: "shake" | "move" | "bell";
    };
    actions: {
      switch?: boolean;
      close?: boolean;
      contact?: boolean;
      pay?: boolean;
      activate?: boolean;
    };
  };
} = {
  [BannerStatus.DEFAULT]: {
    bg: "blueLightest",
    icon: {
      color: "neutralWhite",
      label: "📣",
      animation: "bell",
    },
    actions: {
      close: true,
    },
  },
  [BannerStatus.SUCCESS]: {
    bg: "neutralBlack",
    icon: {
      color: "greenLightest",
      label: "🥳",
    },
    actions: {
      close: true,
    },
  },
  [BannerStatus.WARNING]: {
    bg: "feedbackColorBackgroundWarning",
    icon: {
      color: "pinkLightest",
      label: "👉",
      animation: "move",
    },
    actions: {
      contact: true,
      pay: true,
    },
  },
  [BannerStatus.ERROR]: {
    bg: "feedbackColorBackgroundCritical",
    icon: {
      color: "redLightest",
      label: "🚨",
      animation: "shake",
    },
    actions: {
      contact: true,
      pay: true,
    },
  },
  [BannerStatus.ACTIVATE]: {
    bg: "feedbackColorBackgroundCritical",
    icon: {
      color: "redLightest",
      label: "🚨",
      animation: "shake",
    },
    actions: {
      activate: true,
    },
  },
};
