import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSubscription } from "api/useSubscription";
import { OnboardingApplicationStatus } from "graphql/generated/types";
import { useCancelActiveOnboardingApplicationMutation } from "graphql/onboarding";
import { GET_ORGANISATION, useGetOrganisationQuery } from "graphql/organisation";
import {
  ON_INITIAL_NUMBER_CHANGED,
  TInitialNumber,
  onInitialNumberChanged,
  onInitialNumberChangedVariables,
} from "graphqlQueries/number";
import { useGlobalContext, useNavigation } from "hooks";
import { usePageTitle } from "hooks/usePageTitle";
import { hasDirectNumber, isAdmin } from "selectors/organisation";
import { FeatureFlag, isEnabledFeature } from "utils/featureFlags";

import {
  getAccountStatus,
  getBusinessStatus,
  getLegacyStepStatuses,
  getNameStatus,
  getOnboardingPhoneNumber,
  getOnboardingURLTupleForStep,
  getStepStatuses,
} from "../utils";
import { OnboardingProgressContent } from "./OnboardingProgressContent";

const { STARTED } = OnboardingApplicationStatus;

interface IOnboardingProgress {
  number: TInitialNumber;
  sidebar?: boolean;
  onClose?: () => void;
  hideHeader?: boolean;
}

const OnboardingProgress = ({ number, sidebar, onClose, hideHeader }: IOnboardingProgress) => {
  const isNewOnboarding = isEnabledFeature(FeatureFlag.NEW_ONBOARDING);
  const { t } = useTranslation();
  usePageTitle(t("page-title.onboarding"));

  const { navigateTo } = useNavigation();
  const { UNSAFE_organisationUuid, onboardingStep } = useGlobalContext();

  const { data } = useGetOrganisationQuery({
    variables: { organisationUuid: UNSAFE_organisationUuid! },
    skip: !UNSAFE_organisationUuid,
    fetchPolicy: "cache-and-network",
  });
  const organisation = GET_ORGANISATION.parse(data)?.organisation;
  const { status } = organisation?.activeOnboardingApplication ?? {};

  const { graphNameStatus, waba, uuid: numberUuid } = number;

  useSubscription<onInitialNumberChanged, onInitialNumberChangedVariables>(ON_INITIAL_NUMBER_CHANGED.query, {
    variables: { numberUuid },
  });

  const onboardingStepURLTuple = getOnboardingURLTupleForStep(onboardingStep || undefined, UNSAFE_organisationUuid);

  // context(alexandrchebotar, 2021-10-29): redirection after signed-up
  useEffect(() => {
    if (sidebar && status === STARTED) {
      if (isNewOnboarding) {
        navigateTo(...onboardingStepURLTuple)();
      } else {
        navigateTo("onboarding")();
      }
    }
  }, [UNSAFE_organisationUuid, status, navigateTo, sidebar, isNewOnboarding, onboardingStepURLTuple]);

  const { numberStatus, embeddedSignupStatus, payStatus, verificationStatus } = isNewOnboarding
    ? getStepStatuses(number, organisation)
    : getLegacyStepStatuses(number, organisation);

  const [cancelOnboardingApplication] = useCancelActiveOnboardingApplicationMutation({
    variables: { organisationUuid: UNSAFE_organisationUuid! },
  });

  return (
    <OnboardingProgressContent
      accountStatus={getAccountStatus(waba?.accountReviewStatus)}
      businessStatus={getBusinessStatus(waba?.facebookBusiness?.verificationStatus)}
      cancelOnboardingApplication={cancelOnboardingApplication}
      embeddedSignupStatus={embeddedSignupStatus}
      isAdmin={isAdmin(organisation)}
      organisationHasDirectNumber={hasDirectNumber(organisation)}
      nameStatus={getNameStatus(graphNameStatus)}
      numberStatus={numberStatus}
      payStatus={payStatus}
      phoneNumber={getOnboardingPhoneNumber(number, organisation)}
      sidebar={sidebar}
      hideHeader={hideHeader}
      verificationStatus={verificationStatus}
      onClose={onClose}
      organisationUuid={UNSAFE_organisationUuid || ""}
    />
  );
};

export default OnboardingProgress;
