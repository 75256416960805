import styled from "styled-components";

import { ColorGrayLightest } from "ds/Core";
import { colors } from "lib/impact/styles/colors";

export const ItemContainer = styled.div<{ focused?: boolean }>`
  border-radius: 8px;
  ${({ focused }) => focused && `background: ${ColorGrayLightest};`}
`;

export const SelectItemsList = styled.ul`
  padding: 0 ${({ theme: { spaces } }) => spaces.s}px;
  margin: ${({ theme: { spaces } }) => spaces.s}px 0;
  list-style-type: none;
`;

export const SearchContainerOuter = styled.div`
  position: sticky;
  z-index: 5;
  top: 0;
  width: 100%;
  padding-top: ${({ theme: { spaces } }) => spaces.s}px;
  background: ${colors.white};
`;

export const SearchContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme: { spaces } }) => `${spaces.s}px ${spaces.m}px`};
`;

export const SearchIconContainer = styled.div`
  width: 28px;
  height: 21px;
  display: flex;
  justify-content: center;
  margin-right: ${({ theme: { spaces } }) => spaces.s}px;
`;

export const SearchInput = styled.input`
  min-width: 0;
  font-size: 18px;
  line-height: 1.25;
  border: none;
  outline: none;
`;
