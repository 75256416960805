/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

import {
  AccountDetailsFragment,
  OrganisationAccountDetailsFragment,
} from "../../accounts/generated/fragments.generated";
import {
  AccountDetailsFragmentDoc,
  OrganisationAccountDetailsFragmentDoc,
} from "../../accounts/generated/fragments.generated";
import * as Types from "../../generated/types";
import { NumberMinFragmentFragment } from "../../number/generated/fragments.generated";
import { NumberMinFragmentFragmentDoc } from "../../number/generated/fragments.generated";

export type ListOrganisationTokensDetailsFragment = {
  __typename: "Organisation";
  uuid: string | null;
  organisationTokens: Array<{
    __typename: "OrganisationToken";
    uuid: string | null;
    type: Types.OrganisationTokenType | null;
    scope: string | null;
    name: string | null;
    summary: string | null;
    createdAt: any | null;
    expiresAt: any | null;
  } | null> | null;
};

export type OnboardingApplicationFragment = {
  __typename: "OnboardingApplication";
  eulaAccepted: boolean;
  phoneNumber: string | null;
  phoneNumberProvider: Types.PhoneNumberProvider | null;
  status: Types.OnboardingApplicationStatus;
  uuid: string;
  customNumberRequest: { __typename: "CustomNumberRequest"; country: string; state: string | null } | null;
  poolNumber: { __typename: "PoolNumber"; uuid: string; fbGraphId: string; phoneNumber: string } | null;
};

export type OrganisationDetailsFragment = {
  __typename: "Organisation";
  insertedAt: any | null;
  name: string | null;
  uuid: string | null;
  type: Types.OrganisationType | null;
  signupType: Types.OrganisationSignupType | null;
  signupReferral: string | null;
  privacyEnabled: boolean | null;
  waba: {
    __typename: "Waba";
    fbGraphId: string;
    uuid: string;
    cachedFreeMonthlyConversationsCount: number;
    conversationsWaived: boolean;
    conversationsWaiverCustomAmount: number | null;
  } | null;
  numbers: Array<({ __typename: "Number" } & NumberMinFragmentFragment) | null> | null;
  organisationAccounts: Array<
    ({ __typename: "OrganisationAccount" } & OrganisationAccountDetailsFragment) | null
  > | null;
  myAccount: ({ __typename: "Account" } & AccountDetailsFragment) | null;
  activeOnboardingApplication: ({ __typename: "OnboardingApplication" } & OnboardingApplicationFragment) | null;
};

export const ListOrganisationTokensDetailsFragmentDoc = gql`
  fragment ListOrganisationTokensDetails on Organisation {
    uuid
    organisationTokens {
      uuid
      type
      scope
      name
      summary
      createdAt
      expiresAt
    }
  }
`;
export const OnboardingApplicationFragmentDoc = gql`
  fragment onboardingApplication on OnboardingApplication {
    customNumberRequest {
      country
      state
    }
    eulaAccepted
    phoneNumber
    phoneNumberProvider
    status
    uuid
    poolNumber {
      uuid
      fbGraphId
      phoneNumber
    }
  }
`;
export const OrganisationDetailsFragmentDoc = gql`
  fragment OrganisationDetails on Organisation {
    insertedAt
    name
    uuid
    type
    signupType
    signupReferral
    privacyEnabled
    waba {
      fbGraphId
      uuid
      cachedFreeMonthlyConversationsCount
      conversationsWaived
      conversationsWaiverCustomAmount
    }
    numbers {
      ...NumberMinFragment_
    }
    organisationAccounts {
      ...OrganisationAccountDetails
    }
    myAccount {
      ...AccountDetails
    }
    activeOnboardingApplication {
      ...onboardingApplication
    }
  }
  ${NumberMinFragmentFragmentDoc}
  ${OrganisationAccountDetailsFragmentDoc}
  ${AccountDetailsFragmentDoc}
  ${OnboardingApplicationFragmentDoc}
`;
