import styled from "styled-components";

import { BorderColorDefault } from "ds/Alias/src/tokens";
import {
  BorderRadius100,
  ColorGrayLightest,
  ColorNeutralBlack,
  ColorNeutralWhite,
  FontFamilyDMSans,
  FontSize10,
  LineHeight25,
  Space25,
  Space100,
  Space200,
  SpaceNone,
} from "ds/Core";
import { DropPositions } from "ds/Drop/src/types";
import { FormColorTextPlaceholder, FormColorTextPlaceholderFocus } from "ds/Form";
import { Icon } from "ds/icons";

import { getSelectStyles } from "./theme";
import { StyledSelectProps } from "./types";

export const StyledSelectButton = styled.button<StyledSelectProps & { isPlaceholder?: boolean; multiple?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${ColorNeutralWhite};
  font-family: ${FontFamilyDMSans};
  cursor: pointer;

  ${({ size, error, disabled }) => getSelectStyles({ size, error, disabled })};

  ${({ multiple }) =>
    multiple &&
    `
    height: auto;
  `}

  ${({ isPlaceholder, open }) =>
    (isPlaceholder || (open && isPlaceholder)) &&
    `
    > span {
      color: ${!open ? FormColorTextPlaceholder : FormColorTextPlaceholderFocus};
    }
  `}
`;

export const StyledRightIcon = styled(Icon)<{ position: DropPositions }>`
  margin-left: ${Space200};

  ${({ position }) =>
    (["bottom-start", "bottom", "bottom-end", "top-start", "top", "top-end", "auto-start", "auto", "auto-end"].includes(
      position
    ) ||
      !position) &&
    "transform: rotate(90deg);"}
`;

export const StyledLabel = styled.span<{ isPlaceholder?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  gap: ${Space100};
  padding: ${Space100} ${SpaceNone};
`;

export const Tag = styled.div`
  font-family: ${FontFamilyDMSans};
  font-size: ${FontSize10};
  line-height: ${LineHeight25};
  color: ${ColorNeutralBlack};
  background: ${ColorGrayLightest};
  border-radius: ${BorderRadius100};
  padding: ${Space25} 6px;
`;

export const ContentStyles: React.CSSProperties = { border: "none", overflow: "auto" };

export const HeaderStyles: React.CSSProperties = {
  padding: `0px ${Space200}`,
  borderBottom: `1px solid ${BorderColorDefault}`,
};
