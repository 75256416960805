import { Google } from "grommet-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import { Action } from "containers/Onboarding/components";
import { BackgroundColorYellow } from "ds/Alias";
import { Box } from "ds/Box";
import { ColorPurpleDarkest } from "ds/Core";
import { TurnWordMark } from "ds/Logo";
import { MicrosoftLogo } from "lib/icons/MicrosoftLogo";
import { calcSpacing } from "lib/impact";
import { TurnHostInfo } from "types";

const Title = styled.h1`
  color: white;
  text-wrap: balance;
  max-width: 450px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 300;
  margin: 0;

  @media screen and (min-width: 768px) {
    font-size: 48px;
    line-height: 56px;
    max-width: 550px;
  }
`;

const Ripple = styled.svg`
  position: absolute;
  left: -20%;
  right: -20%;
  bottom: 0;
  aspect-ratio: square;
  z-index: 0;
  transform: translateY(50%);

  @media screen and (min-width: 768px) {
    right: 0;
    top: 50%;
    bottom: 0;
    transform: translateX(50%) translateY(-50%);
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 40px;
  svg {
    width: 104px;
  }
`;

export interface ISignIn {
  turnHostInfo: TurnHostInfo;
}
export const SignIn = ({ turnHostInfo }: ISignIn) => {
  const { t } = useTranslation();
  const [referral = ""] = useQueryParam("referral", StringParam);
  const searchParams = new URLSearchParams({
    redirect_to: window.location.href,
    prompt: "login",
    referral,
  });

  const getLoginUrl = (SSOprovider: string) => `${turnHostInfo.turn_host}/auth/${SSOprovider}?${searchParams}`;

  return (
    <Box bg={ColorPurpleDarkest} minHeight="100vh" position="relative" p={{ sm: "400", lg: "800" }} overflow="hidden">
      <Box zIndex="1" position="relative">
        <LogoWrapper>
          <TurnWordMark fill={BackgroundColorYellow} />
        </LogoWrapper>

        <Title>{t("login.heading")}</Title>
        <Action
          label={t("login.action-google")}
          href={getLoginUrl("google")}
          icon={<Google color="plain" />}
          outline
          margin={{ top: calcSpacing(["l", "m"]) }}
        />
        <Action
          label={t("login.action-microsoft")}
          href={getLoginUrl("microsoft")}
          icon={<MicrosoftLogo />}
          outline
          margin={{ top: calcSpacing(["m", "s"]) }}
        />
      </Box>

      <Ripple xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1536 1536" fill="none">
        <path
          fill="#D8C833"
          d="M768 1536c424.15 0 768-343.85 768-768 0-424.155-343.85-768-768-768C343.845 0 0 343.845 0 768c0 424.15 343.845 768 768 768Z"
          opacity=".3"
        />
        <path
          fill="#D8C833"
          d="M768 1280c282.77 0 512-229.23 512-512s-229.23-512-512-512-512 229.23-512 512 229.23 512 512 512Z"
          opacity=".6"
        />
        <path
          fill="#D8C833"
          d="M768 1024c141.38 0 256-114.615 256-256S909.38 512 768 512c-141.385 0-256 114.615-256 256s114.615 256 256 256Z"
          opacity=".6"
        />
        <path
          fill="#D8C833"
          d="M768 1067.99c165.68 0 299.99-134.311 299.99-299.993S933.68 468.004 768 468.004c-165.682 0-299.993 134.311-299.993 299.993S602.318 1067.99 768 1067.99Z"
        />
      </Ripple>
    </Box>
  );
};
