import { Box, Text } from "grommet";
import React from "react";
import BarLoader from "react-spinners/BarLoader";

import { ColorNeutralBlack } from "ds/Core";

export const SimpleBarLoader = ({ text, ...props }) => (
  <Box
    flex
    fill="vertical"
    direction="column"
    justify="center"
    align="center"
    style={{
      position: "relative",
      transform: "translateZ(0)",
      isolation: "isolate",
    }}
    gap="medium"
    {...props}
  >
    {text && (
      <Text size="medium" color={ColorNeutralBlack} margin={{ bottom: "medium" }}>
        {text}
      </Text>
    )}
    <BarLoader color={ColorNeutralBlack} />
  </Box>
);
