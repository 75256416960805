import { Box, BoxTypes, Text } from "grommet";
import React from "react";
import BarLoader from "react-spinners/BarLoader";

import { ColorBrandDefault } from "ds/Core";

interface ICardLoaderProps extends BoxTypes {
  loading?: boolean;
  title?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const CardLoader = ({ loading, title, children, style, ...props }: ICardLoaderProps) => {
  return loading ? (
    <Box
      flex
      direction="column"
      alignSelf="stretch"
      fill="horizontal"
      width="730px"
      justify="center"
      align="center"
      style={{ height: "148px", width: "614px", ...style }}
      {...props}
    >
      {title && (
        <Text size="medium" color={ColorBrandDefault} margin={{ bottom: "medium" }}>
          {title}
        </Text>
      )}

      <BarLoader width={50} />
    </Box>
  ) : (
    <>{children}</>
  );
};
