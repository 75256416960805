import { Box, BoxExtendedProps, Text } from "grommet";
import React from "react";
import { useTranslation } from "react-i18next";
import BarLoader from "react-spinners/BarLoader";

import { ColorNeutralBlack, ColorNeutralWhite } from "ds/Core";

interface IFullPageLoaderProps extends BoxExtendedProps {
  title?: string;
  cover?: boolean;
  background?: string;
  color?: string;
}

export const FullPageLoader = ({
  title,
  cover,
  background = ColorNeutralWhite,
  color = ColorNeutralBlack,
  ...props
}: IFullPageLoaderProps) => (
  <Box
    flex
    fill="vertical"
    direction="column"
    justify="center"
    align="center"
    style={
      cover
        ? {
            position: "fixed",
            height: "100vh",
            width: "100vw",
            top: "0",
            left: "0",
            zIndex: 1005,
            background,
          }
        : { height: "100vh" }
    }
    gap="medium"
    {...props}
  >
    {title && (
      <Text size="medium" color={color}>
        {title}
      </Text>
    )}
    <BarLoader color={color} />
  </Box>
);

interface IFullPageLoaderContainerProps extends IFullPageLoaderProps {
  children: React.ReactNode;
  loading?: boolean;
}

export const FullPageLoaderContainer = ({ title, cover, loading, children }: IFullPageLoaderContainerProps) => (
  <>{loading ? <FullPageLoader title={title} cover={cover} /> : children}</>
);

export const ConnectingLoader = () => {
  const { t } = useTranslation();

  return <FullPageLoader title={t("global.connecting")} />;
};
