import {
  BackgroundColorBlue,
  BackgroundColorGreen,
  BackgroundColorPink,
  BackgroundColorPurple,
  BackgroundColorRed,
  BackgroundColorYellow,
  FeedbackColorWarning,
} from "ds/Alias";
import { ColorGrayDark, ColorNeutralBlack } from "ds/Core";
import { CardType } from "graphql/generated/types";

export const bigQueryTablesConstants = [
  { table: "attachments" },
  { table: "chats" },
  { table: "contact_details" },
  { table: "faqs" },
  { table: "message_attachments" },
  { table: "message_tags" },
  { table: "messages" },
  { table: "number_tags" },
];

export const responseConstants = ["AUTOMATOR", "OPERATOR", "STACK", "SYSTEM", "REMINDER"];

export const ALL_CONVERSATIONS = "All conversations";

export const ORDERING = {
  ALPHABET_ASC: "title",
  ALPHABET_DESC: "-title",
  LATEST_FIRST: "-insertedAt",
  OLDEST_FIRST: "insertedAt",
  RECENTLY_UPDATED: "-updatedAt",
};

export const PAGE_SIZE = {
  CHATS: 50,
  CONTENT: 50,
  TEMPLATES: 20,
  THREADS: 10,
  REMINDERS: 20,
  AUTOMATORS: 15,
  REPLY_BOX: {
    TEMPLATES: 10,
  },
};

export const MAX_LENGTH = {
  LIST: {
    ROW_TITLE: 24,
    SECTION_TITLE: 24,
    ROW_DESCRIPTION: 72,
  },
  INTERACTIVE_MESSAGE: {
    CONTENT: 1024,
  },
  MESSAGE: {
    BUTTON: 20,
    HEADER: 60,
    CONTENT_ADVICE: 450,
    CONTENT_MEDIA: 1024,
    CONTENT: 4095,
    ERROR_CONTENT: 255,
    FOOTER: 60,
  },
  TITLE: 120,
  STACK: {
    CARD: {
      NAME: 250,
    },
  },
};

export const DEFAULT_IS_HANDLED_FILTER_VALUE = false;

export const CARD_TYPE_COLORS = {
  [CardType.TEXT]: BackgroundColorGreen,
  [CardType.MULTI]: BackgroundColorPink,
  [CardType.LOCATION]: FeedbackColorWarning,
  [CardType.MESSAGE]: BackgroundColorPurple,
  [CardType.STICKER]: BackgroundColorPink,
  [CardType.OPT_IN]: BackgroundColorRed,
  [CardType.FAQ]: BackgroundColorPurple,
  [CardType.BUTTONS]: BackgroundColorBlue,
  TRIGGER: BackgroundColorYellow,
  CONTENT: ColorNeutralBlack,
  ACTION: ColorGrayDark,
};

// todo(alexandrchebotar, 2022-10-18): set to 30000 after adding threads BE pagination
export const WEBSOCKET_REQUEST_TIMEOUT = 45000;
export const DEFAULT_REQUEST_TIMEOUT_SENTRY_ALERT = 10000;
export const DEFAULT_INTEGRATION_POLL_TIMEOUT = 15000;
export const MAX_INTEGRATION_POLL_TIMEOUT = 5000;

export const AUTOMATED_MESSAGES = "Automated messages";
export const REQUESTED_HELP = "Automated messages";

export const ReservedTag = {
  AUTOMATED_MESSAGES: "Automated messages",
  REQUESTED_HELP: "Requested help",
} as const;

export const HandoverAutomatedMessages = {
  HANDOVER_STARTS: "Handover starts",
  HANDOVER_HOLD: "Handover hold",
} as const;

export const AutomatedMessages = {
  ...HandoverAutomatedMessages,
} as const;

export const KANDUA_SUSPENDED_LINE = "+27115943710";

export const DEBOUNCE_INTERVAL = 500;

export const EXISTING_AUTOMATION_CUT_OFF_DATE = new Date("06/30/2024");
export const NEW_AUTOMATION_CUT_OFF_DATE = new Date("01/17/2024");
