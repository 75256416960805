/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { PoolNumberDetailsFragment } from "./fragments.generated";
import { PoolNumberDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type AvailablePoolNumbersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AvailablePoolNumbersQuery = {
  __typename: "RootQueryType";
  availablePoolNumbers: Array<{ __typename: "PoolNumber" } & PoolNumberDetailsFragment>;
};

export type AvailableCountriesForSubscriptionAddressQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AvailableCountriesForSubscriptionAddressQuery = {
  __typename: "RootQueryType";
  availableCountriesForSubscriptionAddress: Array<string | null>;
};

export type OnboardingConfigurationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OnboardingConfigurationQuery = {
  __typename: "RootQueryType";
  onboardingConfiguration: {
    __typename: "OnboardingConfiguration";
    bspFacebookAppId: string;
    bspBusinessLoginConfigurationId: string;
  } | null;
};

export type CheckWabaQueryVariables = Types.Exact<{
  onboardingApplicationUuid: Types.Scalars["ID"];
  organisationUuid: Types.Scalars["ID"];
  embeddedSignupUserCode: Types.Scalars["String"];
}>;

export type CheckWabaQuery = {
  __typename: "RootQueryType";
  wabaIsAvailableForEmbeddedSignup: {
    __typename: "WabaAvailabilityStatus";
    isAvailable: boolean;
    wabaGraphId: string;
  } | null;
};

export const AvailablePoolNumbersDocument = gql`
  query availablePoolNumbers {
    availablePoolNumbers {
      ...PoolNumberDetails
    }
  }
  ${PoolNumberDetailsFragmentDoc}
`;

/**
 * __useAvailablePoolNumbersQuery__
 *
 * To run a query within a React component, call `useAvailablePoolNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePoolNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePoolNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailablePoolNumbersQuery(
  baseOptions?: Apollo.QueryHookOptions<AvailablePoolNumbersQuery, AvailablePoolNumbersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailablePoolNumbersQuery, AvailablePoolNumbersQueryVariables>(
    AvailablePoolNumbersDocument,
    options
  );
}
export function useAvailablePoolNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailablePoolNumbersQuery, AvailablePoolNumbersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailablePoolNumbersQuery, AvailablePoolNumbersQueryVariables>(
    AvailablePoolNumbersDocument,
    options
  );
}
export type AvailablePoolNumbersQueryHookResult = ReturnType<typeof useAvailablePoolNumbersQuery>;
export type AvailablePoolNumbersLazyQueryHookResult = ReturnType<typeof useAvailablePoolNumbersLazyQuery>;
export type AvailablePoolNumbersQueryResult = Apollo.QueryResult<
  AvailablePoolNumbersQuery,
  AvailablePoolNumbersQueryVariables
>;
export const AvailableCountriesForSubscriptionAddressDocument = gql`
  query availableCountriesForSubscriptionAddress {
    availableCountriesForSubscriptionAddress
  }
`;

/**
 * __useAvailableCountriesForSubscriptionAddress__
 *
 * To run a query within a React component, call `useAvailableCountriesForSubscriptionAddress` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCountriesForSubscriptionAddress` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCountriesForSubscriptionAddress({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCountriesForSubscriptionAddress(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableCountriesForSubscriptionAddressQuery,
    AvailableCountriesForSubscriptionAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableCountriesForSubscriptionAddressQuery,
    AvailableCountriesForSubscriptionAddressQueryVariables
  >(AvailableCountriesForSubscriptionAddressDocument, options);
}
export function useAvailableCountriesForSubscriptionAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCountriesForSubscriptionAddressQuery,
    AvailableCountriesForSubscriptionAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCountriesForSubscriptionAddressQuery,
    AvailableCountriesForSubscriptionAddressQueryVariables
  >(AvailableCountriesForSubscriptionAddressDocument, options);
}
export type AvailableCountriesForSubscriptionAddressHookResult = ReturnType<
  typeof useAvailableCountriesForSubscriptionAddress
>;
export type AvailableCountriesForSubscriptionAddressLazyQueryHookResult = ReturnType<
  typeof useAvailableCountriesForSubscriptionAddressLazyQuery
>;
export type AvailableCountriesForSubscriptionAddressQueryResult = Apollo.QueryResult<
  AvailableCountriesForSubscriptionAddressQuery,
  AvailableCountriesForSubscriptionAddressQueryVariables
>;
export const OnboardingConfigurationDocument = gql`
  query onboardingConfiguration {
    onboardingConfiguration {
      bspFacebookAppId
      bspBusinessLoginConfigurationId
    }
  }
`;

/**
 * __useOnboardingConfigurationQuery__
 *
 * To run a query within a React component, call `useOnboardingConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<OnboardingConfigurationQuery, OnboardingConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardingConfigurationQuery, OnboardingConfigurationQueryVariables>(
    OnboardingConfigurationDocument,
    options
  );
}
export function useOnboardingConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnboardingConfigurationQuery, OnboardingConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardingConfigurationQuery, OnboardingConfigurationQueryVariables>(
    OnboardingConfigurationDocument,
    options
  );
}
export type OnboardingConfigurationQueryHookResult = ReturnType<typeof useOnboardingConfigurationQuery>;
export type OnboardingConfigurationLazyQueryHookResult = ReturnType<typeof useOnboardingConfigurationLazyQuery>;
export type OnboardingConfigurationQueryResult = Apollo.QueryResult<
  OnboardingConfigurationQuery,
  OnboardingConfigurationQueryVariables
>;
export const CheckWabaDocument = gql`
  query checkWaba($onboardingApplicationUuid: ID!, $organisationUuid: ID!, $embeddedSignupUserCode: String!) {
    wabaIsAvailableForEmbeddedSignup(
      onboardingApplicationUuid: $onboardingApplicationUuid
      organisationUuid: $organisationUuid
      embeddedSignupUserCode: $embeddedSignupUserCode
    ) {
      isAvailable
      wabaGraphId
    }
  }
`;

/**
 * __useCheckWabaQuery__
 *
 * To run a query within a React component, call `useCheckWabaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckWabaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckWabaQuery({
 *   variables: {
 *      onboardingApplicationUuid: // value for 'onboardingApplicationUuid'
 *      organisationUuid: // value for 'organisationUuid'
 *      embeddedSignupUserCode: // value for 'embeddedSignupUserCode'
 *   },
 * });
 */
export function useCheckWabaQuery(baseOptions: Apollo.QueryHookOptions<CheckWabaQuery, CheckWabaQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckWabaQuery, CheckWabaQueryVariables>(CheckWabaDocument, options);
}
export function useCheckWabaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckWabaQuery, CheckWabaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckWabaQuery, CheckWabaQueryVariables>(CheckWabaDocument, options);
}
export type CheckWabaQueryHookResult = ReturnType<typeof useCheckWabaQuery>;
export type CheckWabaLazyQueryHookResult = ReturnType<typeof useCheckWabaLazyQuery>;
export type CheckWabaQueryResult = Apollo.QueryResult<CheckWabaQuery, CheckWabaQueryVariables>;
