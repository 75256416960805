import { useMemo } from "react";
import { useHistory } from "react-router";

import { TChatGroup } from "pages/NumberDetail/pages/Inbox/types";

import { useGlobalContext } from "./useGlobalContext";

export const getPath = <StateType>(locationId: string, options: IUseNavigationOptions<StateType> = {}) => {
  const {
    addNewNumber,
    chatGroupId,
    chatUuid,
    collectionUuid,
    numberUuid,
    organisationUuid,
    playbookUuid,
    search = "",
    stackContainerUuid,
    threadUuid,
    variableUuid,
  } = options;

  switch (locationId) {
    case "automations":
      return `/number/${numberUuid}/journeys/automation`;
    case "billing":
      return `/billing/${numberUuid}`;
    case "canvas":
      return `/stacks/${numberUuid}/${stackContainerUuid}/canvas`;
    case "content-templates":
      return `/number/${numberUuid}/content/templates`;
    case "community":
      return "/playbooks/public";
    case "dashboard":
      return `/number/${numberUuid}`;
    case "imported-playbooks":
      return `/number/${numberUuid}/playbooks`;
    case "inbox":
      const url = new URL(`${location.origin}${search}`);

      if (collectionUuid) {
        url.searchParams.set("collectionUuid", collectionUuid);
      }
      if (chatUuid) {
        url.searchParams.set("chatUuid", chatUuid);
      }
      if (chatGroupId) {
        url.searchParams.set("chatGroupId", chatGroupId);
      }

      return `/number/${numberUuid}/inbox${url.search}`;
    case "onboarding":
      return `/onboarding/${organisationUuid}${addNewNumber ? "?addNewNumber=1" : ""}`;
    case "organisation-playbooks":
      return `/number/${numberUuid}/playbooks/organisation-playbooks`;
    case "playbook":
      return `/playbooks/${playbookUuid}`;
    case "playbook-edit":
      return `/playbooks/${numberUuid}/edit/${playbookUuid}`;
    case "playbook-new":
      return `/playbooks/${numberUuid}/new/${threadUuid || ""}`;
    case "playbook-public":
      return `/playbooks/public/${playbookUuid}`;
    case "qr":
      return `/qr/${numberUuid}`;
    case "reminders":
      return `/number/${numberUuid}/reminders`;
    case "reminders-templates":
      return `/reminders/${numberUuid}/templates`;
    case "sandbox":
      return `/org/${organisationUuid}/sandbox/new`;
    case "settings-inbox":
      return `/number/${numberUuid}/settings/inbox`;
    case "settings-ai":
      return `/number/${numberUuid}/settings/ai`;
    case "settings-billing":
      return `/number/${numberUuid}/settings/subscription`;
    case "settings-team":
      return `/number/${numberUuid}/settings/team`;
    case "stack-new":
      return `/stacks/${numberUuid}`;
    case "stack":
      return `/stacks/${numberUuid}/${stackContainerUuid}`;
    case "stacks":
      return `/number/${numberUuid}/journeys`;
    case "journeys-data":
      return `/number/${numberUuid}/journeys/data${variableUuid ? `?variableUuid=${variableUuid}` : ""}`;
    case "journeys-logs":
      return `/number/${numberUuid}/journeys/logs`;
    case "thread":
      return `/threads/${numberUuid}/edit/${threadUuid}`;
    case "thread-new":
      return `/threads/${numberUuid}/new`;
    case "threads":
      return `/number/${numberUuid}/journeys/threads`;
    case "payment":
      return `/payment/${numberUuid}`;
    case "templates":
      return `/number/${numberUuid}/content/templates`;
    case "people":
      return `/number/${numberUuid}/people`;
    default:
      return locationId;
  }
};

export interface IUseNavigationOptions<StateType = {}> {
  addNewNumber?: boolean;
  chatGroupId?: TChatGroup;
  chatUuid?: string;
  collectionUuid?: string;
  newTab?: boolean;
  numberUuid?: string;
  organisationUuid?: string;
  playbookUuid?: string;
  search?: string;
  stackContainerUuid?: string;
  state?: StateType;
  threadUuid?: string;
  variableUuid?: string;
  queryString?: string;
}

export const useNavigation = <StateType>(hookOptions?: IUseNavigationOptions<StateType>) => {
  const history = useHistory();
  const { numberUuid, organisationUuid } = useGlobalContext();

  return useMemo(() => {
    const getPathClone = (locationId: string, options?: IUseNavigationOptions<StateType>) =>
      getPath(locationId, {
        numberUuid,
        organisationUuid,
        ...hookOptions,
        ...options,
      });

    return {
      history,
      getPath: getPathClone,
      navigateTo: (locationId: string, options?: IUseNavigationOptions<StateType>) => () => {
        if (options?.newTab) {
          const isQAorProd = process.env.NODE_ENV === "production" || window.location.host === "qa.whatsapp.turn.io";
          const isPrPreview = window.location.host === "pr.turn.io";
          const linkPrefix = isQAorProd ? "/app" : "";
          const prPreviewPrefix = isPrPreview
            ? `/pr-preview${window.location.pathname.match(/\/pr-[0-9]+/gi)?.[0] ?? ""}`
            : "";
          window.open(
            `${prPreviewPrefix || linkPrefix}${getPathClone(locationId, options)}${options.queryString ?? ""}`,
            "_blank"
          );
        } else {
          // Redirect links to LiveViews correctly
          if (locationId.includes("/live/")) {
            const isLocalHost = window.location.host === "localhost:3000";
            let url = locationId;
            if (isLocalHost) {
              url = `http://localhost:4000${locationId}`;
            }
            window.location.href = url;
            return;
          }
          history.push(getPathClone(locationId, options), options?.state || hookOptions?.state);
        }
      },
    };
  }, [numberUuid, organisationUuid, hookOptions, history]);
};
