import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { createNetworkStatusNotifier } from "react-apollo-network-status";
import { useTranslation } from "react-i18next";
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory, useLocation } from "react-router-dom";
import useVH from "react-viewport-height";
import { QueryParamProvider } from "use-query-params";

import { ConnectionLost } from "components/ConnectionLost";
import { DeleteJourneyModal } from "components/DeleteJourneyModal";
import { DuplicateJourneyModal } from "components/DuplicateJourneyModal";
import { Forbidden } from "components/Forbidden";
import { useCurrentOnboardingStep } from "containers/Onboarding/utils";
import {
  GET_ENABLED_FEATURES,
  GET_ORGANISATIONS,
  TOrganisationDetails,
  useGetEnabledFeaturesQuery,
  useGetOrganisationsQuery,
} from "graphql/organisation";
import { TNumberMin } from "graphqlQueries/number";
import { useGlobalContext } from "hooks";
import { usePaymentContext } from "hooks/usePaymentContext";
import { useSelectionContext } from "hooks/useSelectionContext";
import { FullPageLoader } from "lib/loaders/FullPageLoader";
import { FullToast } from "lib/notification/FullToast";
import { Toast } from "lib/notification/Toast";
import { ToastContainer } from "lib/notification/ToastContainer";
import {
  findOrganisation,
  getOrganisationByNumber,
  getOrganisationByUuid,
  getRole,
  isAdmin,
  isOrganisationNumber,
} from "selectors/organisation";
import { initFeatureFlags } from "utils/featureFlags";
import { Host } from "utils/general";
import { appComponents } from "utils/lazy";
import { getLastNumberUuid, getLastOrganisationUuid } from "utils/localStorage";

import { UserActivityTracker } from "./UserActivityTracker";

const {
  Accounts,
  BillingContainer,
  ButtonsContainer,
  NumberDetail,
  Onboarding,
  PlaybookDetail,
  PlaybookForm,
  PlaybooksPublic,
  QrView,
  Sandbox,
  StackDetail,
  StackDetailCanvas,
  TemplateContainer,
  Thread,
  ReminderDetail,
} = appComponents;

export const { link: networkStatusLink, useApolloNetworkStatus } = createNetworkStatusNotifier();

interface IAppProps {
  client: ApolloClient<NormalizedCacheObject>;
}
const App = ({ client }: IAppProps) => {
  useVH();
  const { turnHostInfo } = useGlobalContext();

  useEffect(() => {
    if (turnHostInfo.turn_host.includes(Host.QA)) {
      const faviconLink = document.getElementById("favicon") as HTMLLinkElement;
      faviconLink.href = faviconLink.href.replace("favicon.ico", "favicon-qa.ico");
    }
  }, [turnHostInfo.turn_host]);

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <UserActivityTracker />
        <Toast />
        <ToastContainer />
        <FullToast />
        <BaseRouter />
      </ApolloProvider>
    </div>
  );
};

const BaseRouter = () => {
  const { t } = useTranslation();
  const { disabledSubscription } = usePaymentContext();
  const {
    UNSAFE_organisationUuid,
    UNSAFE_numberUuid,
    setNumberUuid,
    setOrganisationUuid,
    logOut,
    setIsAdmin,
    setRole,
    setMyAccountUuid,
    setCurrentOrganisation,
    numberUuid,
    currentOrganisation,
    setOnboardingStep,
  } = useGlobalContext();

  const { data, loading } = useGetOrganisationsQuery();
  const organisations = useMemo(() => GET_ORGANISATIONS.parse(data)?.organisations, [data]);

  useGetEnabledFeaturesQuery({
    variables: {
      organisationUuid: UNSAFE_organisationUuid as string,
      numberUuid: UNSAFE_numberUuid as string,
    },
    skip: !UNSAFE_organisationUuid,
    onCompleted: (data) => {
      const { getEnabledFeatures } = GET_ENABLED_FEATURES.parse(data) ?? {};
      initFeatureFlags(getEnabledFeatures);
    },
  });

  useEffect(() => {
    if (!UNSAFE_organisationUuid) {
      const initOrganisation = getOrganisationByUuid(organisations, getLastOrganisationUuid());
      const initOrganisationUuid = initOrganisation?.uuid;

      if (initOrganisationUuid) {
        const lastNumberUuid = getLastNumberUuid(initOrganisationUuid);
        const numberIsValid = isOrganisationNumber(initOrganisation, lastNumberUuid);
        const initNumberUuid = numberIsValid ? lastNumberUuid : initOrganisation.numbers[0]?.uuid;

        setOrganisationUuid(initOrganisationUuid);
        setNumberUuid(initNumberUuid);
      }
    }
  }, [UNSAFE_organisationUuid, setOrganisationUuid, setNumberUuid, organisations]);

  useEffect(() => {
    if (UNSAFE_numberUuid) {
      const organisationIsValid = isOrganisationNumber(
        findOrganisation(organisations, UNSAFE_organisationUuid),
        UNSAFE_numberUuid
      );

      if (!organisationIsValid) {
        const organisation = getOrganisationByNumber(organisations, UNSAFE_numberUuid);

        if (organisation) {
          setOrganisationUuid(organisation.uuid);
        }
      }
    }
    // context(alexandrchebotar, 2021-10-25): only check for numberUuid change and ignore organisationUuid change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UNSAFE_numberUuid]);

  useEffect(() => {
    if (UNSAFE_organisationUuid) {
      const organisation = findOrganisation(organisations, UNSAFE_organisationUuid);
      const numberIsValid = isOrganisationNumber(organisation, UNSAFE_numberUuid);
      setCurrentOrganisation(organisation);

      if (!numberIsValid && organisation) {
        const lastNumberUuid = getLastNumberUuid(UNSAFE_organisationUuid);
        const numberIsValid = isOrganisationNumber(organisation, lastNumberUuid);
        const numberUuid = numberIsValid ? lastNumberUuid : organisation.numbers[0]?.uuid;

        setNumberUuid(numberUuid);
      }
    }
    // context(alexandrchebotar, 2021-10-25): only check for organisationUuid change and ignore numberUuid change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UNSAFE_organisationUuid]);

  useEffect(() => {
    const organisation = findOrganisation(organisations, UNSAFE_organisationUuid);
    const myAccountUuid = organisation?.myAccount.uuid;

    setIsAdmin(isAdmin(organisation));
    setRole(getRole(organisation));
    if (myAccountUuid) {
      setMyAccountUuid(myAccountUuid);
    }
  }, [organisations, UNSAFE_organisationUuid, setIsAdmin, setRole, setMyAccountUuid]);

  // context(puvvl, 2024-02-22) Init profitwell engagement
  useEffect(() => {
    const organisation = findOrganisation(organisations, UNSAFE_organisationUuid);
    const myAccountEmail = organisation?.myAccount.email;
    if (myAccountEmail) {
      window?.profitwell?.("start", { user_email: myAccountEmail });
    }
    // context(puvvl, 2024-02-22) Need setup it once when we have org id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UNSAFE_organisationUuid]);

  const onboardingStep = useCurrentOnboardingStep({
    organisation: currentOrganisation,
    numberUuid,
  });

  useEffect(() => {
    setOnboardingStep(onboardingStep);
  }, [onboardingStep, setOnboardingStep]);

  return loading || !organisations || !UNSAFE_organisationUuid ? (
    <FullPageLoader title={t("loaders.fetching-data")} />
  ) : (
    // context(alexandrchebotar, 2023-05-22): all routes (including children components) can safety use not-null `numberUuid` from `GlobalContext`
    <Router basename={process.env.PUBLIC_URL}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <PagesSettings numbers={findOrganisation(organisations, UNSAFE_organisationUuid)?.numbers} />

        <Suspense fallback={<FullPageLoader title={t("loaders.loading-scripts")} />}>
          <RedirectingRouter organisations={organisations} />
          {/* context(alexandrchebotar, 2023-05-23): utility route for manual logout */}
          <Route
            exact
            path="/logout"
            render={() => {
              logOut();
              return null;
            }}
          />

          <Switch>
            {/* context(alexandrchebotar, 2023-05-22): routes before checking `numberUuid`  should use `UNSAFE_nimberUuid` (including children components) */}

            <Redirect exact from="/sandbox/new" to={`/org/${UNSAFE_organisationUuid}/sandbox/new`} />
            <Route path="/org/:organisationUuid/sandbox/new">
              <Sandbox organisations={organisations} />
            </Route>
            <Route exact path="/onboarding/:organisationUuid?">
              <Onboarding organisations={organisations} />
            </Route>
            <Route exact path="/org/:organisationUuid/members">
              <Accounts organisations={organisations} organisation={null} />
            </Route>

            {/* context(@Puvvl, 2024-04-30): Hide this route until we need it to show */}
            {/* <Route exact path="/payment/:numberUuid">
              <Payment />
            </Route> */}

            {/* context(alexandrchebotar, 2022-03-30): keeping redirections for deprecated routes: "/signup", "/signup-india-bootcamp-2021" */}
            <Redirect exact from="/signup" to="/" />
            {/* todo(alexandrchebotar, 2023-05-23): consider to remove deprecated "/signup-india-bootcamp-2021" route from redirections */}
            <Redirect exact from="/signup-india-bootcamp-2021" to="/" />

            {/* // todo(alexandrchebotar, 2023-05-23): ? add common `numberUuid` setter for all routes with `numberUuid` or `organisationUuid`
                        parameter to not render those components with wrong UUIDs? */}
            {UNSAFE_numberUuid ? (
              // context(alexandrchebotar, 2023-05-22): these routes (including children components) can safety to use not-null `numberUuid` from `GlobalContext`
              <Switch>
                {/* // context(alexandrchebotar, 2023-10-12): redirect from root to Inbox if feature enabled */}
                <Redirect exact from="/" to={`/number/${UNSAFE_numberUuid}/inbox`} />
                <Route path="/number/:numberUuid">
                  <NumberDetail organisations={organisations} />
                </Route>
                <Route path="/qr/:numberUuid">
                  <QrView />
                </Route>
                <Redirect exact from="/billing" to={`/billing/${UNSAFE_numberUuid}`} />
                <Route path="/billing/:numberUuid">
                  <BillingContainer organisations={organisations} />
                </Route>
                <Route exact path="/template/:numberUuid/:templateUuid?">
                  <TemplateContainer />
                </Route>
                <Route exact path={["/threads/:numberUuid/new", "/threads/:numberUuid/edit/:threadUuid"]}>
                  <Thread organisations={organisations} />
                </Route>
                <Route exact path="/buttons/:numberUuid/:cardUuid?">
                  <ButtonsContainer />
                </Route>
                <Route
                  exact
                  path={["/playbooks/:numberUuid/new/:threadUuid?", "/playbooks/:numberUuid/edit/:editPlaybookUuid"]}
                >
                  <PlaybookForm organisations={organisations} />
                </Route>
                <Route exact path="/playbooks/public">
                  <PlaybooksPublic />
                </Route>
                <Route exact path={["/playbooks/:playbookUuid", "/playbooks/public/:playbookUuid"]}>
                  <PlaybookDetail />
                </Route>
                <Route exact path="/stacks/:numberUuid/:stackContainerUuid?">
                  <StackDetail organisations={organisations} />
                </Route>
                <Route exact path="/stacks/:numberUuid/:stackContainerUuid/canvas">
                  <StackDetailCanvas />
                </Route>
                {!disabledSubscription && (
                  <Route exact path="/reminder/:numberUuid/">
                    <ReminderDetail organisations={organisations} />
                  </Route>
                )}

                {/* context(alexandrchebotar, 2024-01-15): redirection from old conversation URl to Inbox */}
                <Route
                  path="/c/:chatUuid"
                  render={({ location, match }) => (
                    <Redirect
                      to={{
                        pathname: `/number/${UNSAFE_numberUuid}/inbox`,
                        search: `chatUuid=${match.params.chatUuid}&${location.search.slice(1)}`,
                      }}
                    />
                  )}
                />
                {/* context(alexandrchebotar, 2024-01-15): redirection from old collection URl to Inbox */}
                <Route
                  path="/col/:collectionUuid"
                  render={({ location, match }) => (
                    <Redirect
                      to={{
                        pathname: `/number/${UNSAFE_numberUuid}/inbox`,
                        search: `collectionUuid=${match.params.collectionUuid}&${location.search.slice(1)}`,
                      }}
                    />
                  )}
                />
              </Switch>
            ) : (
              <Redirect exact path="/" to={`/onboarding/${UNSAFE_organisationUuid}`} />
            )}
          </Switch>
        </Suspense>
      </QueryParamProvider>
    </Router>
  );
};

interface IRedirectingRouter {
  organisations: TOrganisationDetails[];
}
const RedirectingRouter = ({ organisations }: IRedirectingRouter) => {
  const { organisationUuid } = useGlobalContext();
  const { queryError, mutationError } = useApolloNetworkStatus();
  const [lost, setLost] = useState(false);
  const history = useHistory();

  // context(alexandrchebotar, 2021-10-25): Check if the user enter non-existing organisation uuid in URL
  // or if the used doesn't have access to that organisation. We need it because we fetch not a single organisation
  // but all allowed organisations
  // TODO(alexandrchebotar, 2021-10-25): figure out why <Redirect to="/forbidden" /> doesn't work in this case
  const organisationNotFound = !findOrganisation(organisations, organisationUuid);
  useEffect(() => {
    if (organisationNotFound) {
      history.replace("/forbidden");
    }
  }, [history, organisationNotFound]);

  const isPresentNotFoundError = () => {
    if (queryError?.response?.errors?.find(({ code }: any) => code === 403)) {
      return true;
    } else if (mutationError?.response?.errors?.find(({ code }: any) => code === 403)) {
      return true;
    }
    return false;
  };

  const offline = () => {
    setLost(true);
    history.push("/522");
  };
  const online = () => setLost(false);

  useEffect(() => {
    window.addEventListener("offline", offline);
    window.addEventListener("online", online);
    return () => {
      window.removeEventListener("offline", offline);
      window.removeEventListener("online", online);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isPresentNotFoundError() && <Redirect to="/forbidden" />}
      <Route exact path="/forbidden" render={() => <Forbidden />} />
      <Route exact path="/522" render={() => <ConnectionLost lost={lost} />} />
    </>
  );
};

const PagesSettings = ({ numbers }: { numbers?: TNumberMin[] }) => {
  const { pathname } = useLocation();
  const { setSelectedItems } = useSelectionContext();

  useEffect(() => {
    if (pathname) {
      setSelectedItems(undefined);
    }
  }, [pathname, setSelectedItems]);

  return (
    <>
      <DeleteJourneyModal />
      <DuplicateJourneyModal numbers={numbers} />
    </>
  );
};

export default App;
