// Google
export const BQServiceAccount = "https://cloud.google.com/iam/docs/service-accounts";
export const BQKey = "https://cloud.google.com/iam/docs/creating-managing-service-account-keys";

// Facebook
export const FBBusinessManager = "https://business.facebook.com/";
export const facebookBusinessSecurity = "https://business.facebook.com/settings/security/";
export const TemplateRejectionsReasonsLink =
  "https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/#rejections";
export const facebookUnverifiedAccount = "https://www.facebook.com/business/help/2640149499569241";
export const facebookRequiredDocumentation = "https://www.facebook.com/business/help/159334372093366";
export const facebookNewTemplateGuidelines =
  "https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines";

// WhatsApp
export const WhatsApp = "https://whatsapp.com/";
export const WhatsAppQRCodeLink = "https://faq.whatsapp.com/android/contacts/how-to-scan-a-business-whatsapp-qr-code/";
export const WhatsAppStickerFaqLink = "https://faq.whatsapp.com/general/how-to-create-stickers-for-whatsapp";

// Turn website
export const TurnPrivacyLink = "https://www.turn.io/legal/privacy-policy";
export const TurnSecurityLink = "https://www.turn.io/legal/security";
export const TurnSLALink = "https://www.turn.io/legal/sla";
export const TurnPricingLink = "https://www.turn.io/product/pricing";
export const TurnTermsOfServiceLink = "https://www.turn.io/legal/turn-io-terms-of-service";
export const TurnTermsOfUseLink = "https://www.turn.io/legal/terms-of-use";

// Turn Learn
export const turnLearnLink = "https://learn.turn.io";
export const turnLearnJourneysLink = "https://learn.turn.io/l/en/article/6x1gi6an0u-a-masterclass-on-journeys";
export const BQLearnLink = `${turnLearnLink}/guiding-and-tracking/big-query-data-and-queries`;
export const learnBusinessVerification = `${turnLearnLink}/embedded-signup-faqs/what-is-facebook-business-verification`;
export const learnBusinessVerified = `${turnLearnLink}/embedded-signup-faqs/i-m-struggling-to-get-my-business-verified`;
export const learnCustomIntegrations = `${turnLearnLink}/article/3xdoxwweoh-custom-integrations`;
export const learnDisplayName = `${turnLearnLink}/embedded-signup-faqs/help-my-display-name-was-rejected`;
export const learnEmbeddedSignup = `${turnLearnLink}/embedded-signup-faqs/i-m-get-errors-when-doing-embedded-signup`;
export const learnSelectNumber = `${turnLearnLink}/embedded-signup-faqs/what-number-do-i-need-for-my-service`;
export const MakeYourServiceDocLink = `${turnLearnLink}/connect-with-your-users-on-chat/use-a-wa-me-link-and-qr-code-to-market-your-service`;
export const playbooksHelpLink = `${turnLearnLink}/article/yojnmrvosq-community-playbooks-alpha`;
export const conversationBasedPricing = `${turnLearnLink}/article/ptoa5o4frt-understand-conversation-based-pricing`;
export const learnRoutingLink = `${turnLearnLink}/article/zwwhiii2e3-how-does-the-routing-engine-assign-chats`;
export const learnSearchContacts = `${turnLearnLink}/article/wp16qugpw6-examples-to-search-contacts`;

// Turn other
export const ApiDocs = "https://whatsapp.turn.io/docs";
export const TurnSupport = "https://api.whatsapp.com/send?phone=16506001008&text=Hi!";
export const TurnSupportSignUp = "https://wa.me/16506001008?text=signup-support";
export const chatForImpactLink = "https://chatforimpact.io";
export const buildDocsLink = "https://whatsapp.turn.io/docs/category/build";
export const OnboardingJourneysVideoLink = "https://www.youtube.com/embed/mVkPGR7lysQ?si=Jz0vtwKB9f0_SM-Q";
export const OnboardingJourneysLearnLink =
  "https://learn.turn.io/l/en/article/5j1t3agmk0-build-with-the-no-code-canvas";
export const OnboardingVariablesVideoLink = "https://www.youtube.com/embed/pOmZS5C-wEE?si=I5O7HEWpRs9C2kbd";
export const FeedbackFormLink = "https://forms.gle/UmoQFehkvBVWWNEa6";
export const introducingRoutingVideoLink = "https://www.youtube.com/embed/kvdVmoSPbqg?si=RsY6LbxHpvQsEfwq";

// emails
export const TurnFinanceEmail = "finance@turn.io";
export const TurnImpactEmail = "impact@turn.io";
export const TurnInfoEmail = "info@turn.io";
export const TurnSupportEmail = "support@turn.io";
export const RoutingToAnyoneEmail = "anyone@example.com";

// OpenAI
export const OpenAIDocs = "https://platform.openai.com/docs";
export const OpenAIVoices = `${OpenAIDocs}/guides/text-to-speech/voice-options`;

// BE assets
export const AiGradientRelativePath = "/images/live/ai/ai-gradient.jpg";
