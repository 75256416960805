/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { InitialNumberFragment } from "../../number/generated/fragments.generated";
import { InitialNumberFragmentDoc } from "../../number/generated/fragments.generated";
import { OrganisationDetailsFragment } from "../../organisation/generated/fragments.generated";
import { OnboardingApplicationFragment } from "../../organisation/generated/fragments.generated";
import { OrganisationDetailsFragmentDoc } from "../../organisation/generated/fragments.generated";
import { OnboardingApplicationFragmentDoc } from "../../organisation/generated/fragments.generated";

const defaultOptions = {} as const;
export type CreateManualPaddleSubscriptionMutationVariables = Types.Exact<{
  addressCountry: Types.Scalars["String"];
  addressFirstLine: Types.Scalars["String"];
  addressPostalCode: Types.Scalars["String"];
  addressRegion: Types.Scalars["String"];
  customerEmail: Types.Scalars["String"];
  customerName: Types.Scalars["String"];
  numberUuid: Types.Scalars["ID"];
  subscriptionPlan: Types.SubscriptionPlan;
}>;

export type CreateManualPaddleSubscriptionMutation = {
  __typename: "RootMutationType";
  createManualPaddleSubscription: string;
};

export type AcceptEulaMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
}>;

export type AcceptEulaMutation = {
  __typename: "RootMutationType";
  acceptEula: {
    __typename: "Organisation";
    uuid: string | null;
    activeOnboardingApplication: {
      __typename: "OnboardingApplication";
      uuid: string;
      status: Types.OnboardingApplicationStatus;
      eulaAccepted: boolean;
    } | null;
    numbers: Array<{
      __typename: "Number";
      uuid: string | null;
      vname: string | null;
      fromAddr: string | null;
      profilePicture: string | null;
      numberType: Types.NumberType | null;
      backendType: Types.BackendType | null;
    } | null> | null;
  } | null;
};

export type RequestPhoneNumberMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  numberRequest: Types.NumberRequest;
}>;

export type RequestPhoneNumberMutation = {
  __typename: "RootMutationType";
  requestPhoneNumber: {
    __typename: "Organisation";
    uuid: string | null;
    activeOnboardingApplication: {
      __typename: "OnboardingApplication";
      uuid: string;
      status: Types.OnboardingApplicationStatus;
      phoneNumber: string | null;
      phoneNumberProvider: Types.PhoneNumberProvider | null;
      poolNumber: { __typename: "PoolNumber"; uuid: string; fbGraphId: string; phoneNumber: string } | null;
    } | null;
  } | null;
};

export type OnboardEmbeddedSignupNumberMutationVariables = Types.Exact<{
  onboardingApplicationUuid: Types.Scalars["ID"];
  organisationUuid: Types.Scalars["ID"];
}>;

export type OnboardEmbeddedSignupNumberMutation = {
  __typename: "RootMutationType";
  onboardEmbeddedSignupNumber: ({ __typename: "Number" } & InitialNumberFragment) | null;
};

export type SubmitSignUpOrganisationFormMutationVariables = Types.Exact<{
  communicationOptIn: Types.Scalars["Boolean"];
  firstName: Types.Scalars["String"];
  impactArea: Types.ImpactSectorType;
  lastName: Types.Scalars["String"];
  organisationCountry: Types.Scalars["String"];
  organisationName: Types.Scalars["String"];
  organisationType: Types.OrganisationEntityType;
  organisationUuid: Types.Scalars["ID"];
  signupReferral?: Types.InputMaybe<Types.Scalars["String"]>;
  website: Types.Scalars["String"];
  workEmail: Types.Scalars["String"];
}>;

export type SubmitSignUpOrganisationFormMutation = {
  __typename: "RootMutationType";
  submitSignUpOrganisationForm: ({ __typename: "Organisation" } & OrganisationDetailsFragment) | null;
};

export type CreateOrgAndSubmitSignUpFormMutationVariables = Types.Exact<{
  communicationOptIn: Types.Scalars["Boolean"];
  firstName: Types.Scalars["String"];
  impactArea: Types.ImpactSectorType;
  lastName: Types.Scalars["String"];
  organisationCountry: Types.Scalars["String"];
  organisationName: Types.Scalars["String"];
  organisationType: Types.OrganisationEntityType;
  signupReferral?: Types.InputMaybe<Types.Scalars["String"]>;
  website: Types.Scalars["String"];
  workEmail: Types.Scalars["String"];
}>;

export type CreateOrgAndSubmitSignUpFormMutation = {
  __typename: "RootMutationType";
  createOrgAndSubmitSignUpForm: ({ __typename: "Organisation" } & OrganisationDetailsFragment) | null;
};

export type SubmitUseCaseDescriptionMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  useCaseDescription?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type SubmitUseCaseDescriptionMutation = {
  __typename: "RootMutationType";
  submitUseCaseDescription: { __typename: "Organisation"; uuid: string | null } | null;
};

export type NewEnterpriseCustomerEmailMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
}>;

export type NewEnterpriseCustomerEmailMutation = {
  __typename: "RootMutationType";
  newEnterpriseCustomerEmail: boolean | null;
};

export type StartOnboardingApplicationForAdditionalNumberMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
}>;

export type StartOnboardingApplicationForAdditionalNumberMutation = {
  __typename: "RootMutationType";
  startOnboardingApplicationForAdditionalNumber:
    | ({ __typename: "OnboardingApplication" } & OnboardingApplicationFragment)
    | null;
};

export type CancelActiveOnboardingApplicationMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
}>;

export type CancelActiveOnboardingApplicationMutation = {
  __typename: "RootMutationType";
  cancelActiveOnboardingApplication: ({ __typename: "OnboardingApplication" } & OnboardingApplicationFragment) | null;
};

export const CreateManualPaddleSubscriptionDocument = gql`
  mutation createManualPaddleSubscription(
    $addressCountry: String!
    $addressFirstLine: String!
    $addressPostalCode: String!
    $addressRegion: String!
    $customerEmail: String!
    $customerName: String!
    $numberUuid: ID!
    $subscriptionPlan: SubscriptionPlan!
  ) {
    createManualPaddleSubscription(
      addressCountry: $addressCountry
      addressFirstLine: $addressFirstLine
      addressPostalCode: $addressPostalCode
      addressRegion: $addressRegion
      customerEmail: $customerEmail
      customerName: $customerName
      numberUuid: $numberUuid
      subscriptionPlan: $subscriptionPlan
    )
  }
`;
export type CreateManualPaddleSubscriptionMutationFn = Apollo.MutationFunction<
  CreateManualPaddleSubscriptionMutation,
  CreateManualPaddleSubscriptionMutationVariables
>;

/**
 * __useCreateManualPaddleSubscription__
 *
 * To run a mutation, you first call `useCreateManualPaddleSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualPaddleSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualPaddleSubscription, { data, loading, error }] = useCreateManualPaddleSubscription({
 *   variables: {
 *      addressCountry: // value for 'addressCountry'
 *      addressFirstLine: // value for 'addressFirstLine'
 *      addressPostalCode: // value for 'addressPostalCode'
 *      addressRegion: // value for 'addressRegion'
 *      customerEmail: // value for 'customerEmail'
 *      customerName: // value for 'customerName'
 *      numberUuid: // value for 'numberUuid'
 *      subscriptionPlan: // value for 'subscriptionPlan'
 *   },
 * });
 */
export function useCreateManualPaddleSubscription(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManualPaddleSubscriptionMutation,
    CreateManualPaddleSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateManualPaddleSubscriptionMutation, CreateManualPaddleSubscriptionMutationVariables>(
    CreateManualPaddleSubscriptionDocument,
    options
  );
}
export type CreateManualPaddleSubscriptionHookResult = ReturnType<typeof useCreateManualPaddleSubscription>;
export type CreateManualPaddleSubscriptionMutationResult =
  Apollo.MutationResult<CreateManualPaddleSubscriptionMutation>;
export type CreateManualPaddleSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateManualPaddleSubscriptionMutation,
  CreateManualPaddleSubscriptionMutationVariables
>;
export const AcceptEulaDocument = gql`
  mutation acceptEula($organisationUuid: ID!) {
    acceptEula(organisationUuid: $organisationUuid) {
      uuid
      activeOnboardingApplication {
        uuid
        status
        eulaAccepted
      }
      numbers {
        uuid
        vname
        fromAddr
        profilePicture
        numberType
        backendType
      }
    }
  }
`;
export type AcceptEulaMutationFn = Apollo.MutationFunction<AcceptEulaMutation, AcceptEulaMutationVariables>;

/**
 * __useAcceptEulaMutation__
 *
 * To run a mutation, you first call `useAcceptEulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptEulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptEulaMutation, { data, loading, error }] = useAcceptEulaMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useAcceptEulaMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptEulaMutation, AcceptEulaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptEulaMutation, AcceptEulaMutationVariables>(AcceptEulaDocument, options);
}
export type AcceptEulaMutationHookResult = ReturnType<typeof useAcceptEulaMutation>;
export type AcceptEulaMutationResult = Apollo.MutationResult<AcceptEulaMutation>;
export type AcceptEulaMutationOptions = Apollo.BaseMutationOptions<AcceptEulaMutation, AcceptEulaMutationVariables>;
export const RequestPhoneNumberDocument = gql`
  mutation requestPhoneNumber($organisationUuid: ID!, $numberRequest: NumberRequest!) {
    requestPhoneNumber(organisationUuid: $organisationUuid, numberRequest: $numberRequest) {
      uuid
      activeOnboardingApplication {
        uuid
        status
        phoneNumber
        phoneNumberProvider
        poolNumber {
          uuid
          fbGraphId
          phoneNumber
        }
      }
    }
  }
`;
export type RequestPhoneNumberMutationFn = Apollo.MutationFunction<
  RequestPhoneNumberMutation,
  RequestPhoneNumberMutationVariables
>;

/**
 * __useRequestPhoneNumberMutation__
 *
 * To run a mutation, you first call `useRequestPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPhoneNumberMutation, { data, loading, error }] = useRequestPhoneNumberMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      numberRequest: // value for 'numberRequest'
 *   },
 * });
 */
export function useRequestPhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestPhoneNumberMutation, RequestPhoneNumberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestPhoneNumberMutation, RequestPhoneNumberMutationVariables>(
    RequestPhoneNumberDocument,
    options
  );
}
export type RequestPhoneNumberMutationHookResult = ReturnType<typeof useRequestPhoneNumberMutation>;
export type RequestPhoneNumberMutationResult = Apollo.MutationResult<RequestPhoneNumberMutation>;
export type RequestPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  RequestPhoneNumberMutation,
  RequestPhoneNumberMutationVariables
>;
export const OnboardEmbeddedSignupNumberDocument = gql`
  mutation onboardEmbeddedSignupNumber($onboardingApplicationUuid: ID!, $organisationUuid: ID!) {
    onboardEmbeddedSignupNumber(
      onboardingApplicationUuid: $onboardingApplicationUuid
      organisationUuid: $organisationUuid
    ) {
      ...InitialNumber_
    }
  }
  ${InitialNumberFragmentDoc}
`;
export type OnboardEmbeddedSignupNumberMutationFn = Apollo.MutationFunction<
  OnboardEmbeddedSignupNumberMutation,
  OnboardEmbeddedSignupNumberMutationVariables
>;

/**
 * __useOnboardEmbeddedSignupNumberMutation__
 *
 * To run a mutation, you first call `useOnboardEmbeddedSignupNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardEmbeddedSignupNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardEmbeddedSignupNumberMutation, { data, loading, error }] = useOnboardEmbeddedSignupNumberMutation({
 *   variables: {
 *      onboardingApplicationUuid: // value for 'onboardingApplicationUuid'
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useOnboardEmbeddedSignupNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardEmbeddedSignupNumberMutation,
    OnboardEmbeddedSignupNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OnboardEmbeddedSignupNumberMutation, OnboardEmbeddedSignupNumberMutationVariables>(
    OnboardEmbeddedSignupNumberDocument,
    options
  );
}
export type OnboardEmbeddedSignupNumberMutationHookResult = ReturnType<typeof useOnboardEmbeddedSignupNumberMutation>;
export type OnboardEmbeddedSignupNumberMutationResult = Apollo.MutationResult<OnboardEmbeddedSignupNumberMutation>;
export type OnboardEmbeddedSignupNumberMutationOptions = Apollo.BaseMutationOptions<
  OnboardEmbeddedSignupNumberMutation,
  OnboardEmbeddedSignupNumberMutationVariables
>;
export const SubmitSignUpOrganisationFormDocument = gql`
  mutation submitSignUpOrganisationForm(
    $communicationOptIn: Boolean!
    $firstName: String!
    $impactArea: ImpactSectorType!
    $lastName: String!
    $organisationCountry: String!
    $organisationName: String!
    $organisationType: OrganisationEntityType!
    $organisationUuid: ID!
    $signupReferral: String
    $website: String!
    $workEmail: String!
  ) {
    submitSignUpOrganisationForm(
      communicationOptIn: $communicationOptIn
      firstName: $firstName
      impactArea: $impactArea
      lastName: $lastName
      organisationCountry: $organisationCountry
      organisationName: $organisationName
      organisationType: $organisationType
      organisationUuid: $organisationUuid
      signupReferral: $signupReferral
      website: $website
      workEmail: $workEmail
    ) {
      ...OrganisationDetails
    }
  }
  ${OrganisationDetailsFragmentDoc}
`;
export type SubmitSignUpOrganisationFormMutationFn = Apollo.MutationFunction<
  SubmitSignUpOrganisationFormMutation,
  SubmitSignUpOrganisationFormMutationVariables
>;

/**
 * __useSubmitSignUpOrganisationFormMutation__
 *
 * To run a mutation, you first call `useSubmitSignUpOrganisationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSignUpOrganisationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSignUpOrganisationFormMutation, { data, loading, error }] = useSubmitSignUpOrganisationFormMutation({
 *   variables: {
 *      communicationOptIn: // value for 'communicationOptIn'
 *      firstName: // value for 'firstName'
 *      impactArea: // value for 'impactArea'
 *      lastName: // value for 'lastName'
 *      organisationCountry: // value for 'organisationCountry'
 *      organisationName: // value for 'organisationName'
 *      organisationType: // value for 'organisationType'
 *      organisationUuid: // value for 'organisationUuid'
 *      signupReferral: // value for 'signupReferral'
 *      website: // value for 'website'
 *      workEmail: // value for 'workEmail'
 *   },
 * });
 */
export function useSubmitSignUpOrganisationFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitSignUpOrganisationFormMutation,
    SubmitSignUpOrganisationFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitSignUpOrganisationFormMutation, SubmitSignUpOrganisationFormMutationVariables>(
    SubmitSignUpOrganisationFormDocument,
    options
  );
}
export type SubmitSignUpOrganisationFormMutationHookResult = ReturnType<typeof useSubmitSignUpOrganisationFormMutation>;
export type SubmitSignUpOrganisationFormMutationResult = Apollo.MutationResult<SubmitSignUpOrganisationFormMutation>;
export type SubmitSignUpOrganisationFormMutationOptions = Apollo.BaseMutationOptions<
  SubmitSignUpOrganisationFormMutation,
  SubmitSignUpOrganisationFormMutationVariables
>;
export const CreateOrgAndSubmitSignUpFormDocument = gql`
  mutation createOrgAndSubmitSignUpForm(
    $communicationOptIn: Boolean!
    $firstName: String!
    $impactArea: ImpactSectorType!
    $lastName: String!
    $organisationCountry: String!
    $organisationName: String!
    $organisationType: OrganisationEntityType!
    $signupReferral: String
    $website: String!
    $workEmail: String!
  ) {
    createOrgAndSubmitSignUpForm(
      communicationOptIn: $communicationOptIn
      firstName: $firstName
      impactArea: $impactArea
      lastName: $lastName
      organisationCountry: $organisationCountry
      organisationName: $organisationName
      organisationType: $organisationType
      signupReferral: $signupReferral
      website: $website
      workEmail: $workEmail
    ) {
      ...OrganisationDetails
    }
  }
  ${OrganisationDetailsFragmentDoc}
`;
export type CreateOrgAndSubmitSignUpFormMutationFn = Apollo.MutationFunction<
  CreateOrgAndSubmitSignUpFormMutation,
  CreateOrgAndSubmitSignUpFormMutationVariables
>;

/**
 * __useCreateOrgAndSubmitSignUpFormMutation__
 *
 * To run a mutation, you first call `useCreateOrgAndSubmitSignUpFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgAndSubmitSignUpFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgAndSubmitSignUpFormMutation, { data, loading, error }] = useCreateOrgAndSubmitSignUpFormMutation({
 *   variables: {
 *      communicationOptIn: // value for 'communicationOptIn'
 *      firstName: // value for 'firstName'
 *      impactArea: // value for 'impactArea'
 *      lastName: // value for 'lastName'
 *      organisationCountry: // value for 'organisationCountry'
 *      organisationName: // value for 'organisationName'
 *      organisationType: // value for 'organisationType'
 *      signupReferral: // value for 'signupReferral'
 *      website: // value for 'website'
 *      workEmail: // value for 'workEmail'
 *   },
 * });
 */
export function useCreateOrgAndSubmitSignUpFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrgAndSubmitSignUpFormMutation,
    CreateOrgAndSubmitSignUpFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrgAndSubmitSignUpFormMutation, CreateOrgAndSubmitSignUpFormMutationVariables>(
    CreateOrgAndSubmitSignUpFormDocument,
    options
  );
}
export type CreateOrgAndSubmitSignUpFormMutationHookResult = ReturnType<typeof useCreateOrgAndSubmitSignUpFormMutation>;
export type CreateOrgAndSubmitSignUpFormMutationResult = Apollo.MutationResult<CreateOrgAndSubmitSignUpFormMutation>;
export type CreateOrgAndSubmitSignUpFormMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgAndSubmitSignUpFormMutation,
  CreateOrgAndSubmitSignUpFormMutationVariables
>;
export const SubmitUseCaseDescriptionDocument = gql`
  mutation submitUseCaseDescription($organisationUuid: ID!, $useCaseDescription: String) {
    submitUseCaseDescription(organisationUuid: $organisationUuid, useCaseDescription: $useCaseDescription) {
      uuid
    }
  }
`;
export type SubmitUseCaseDescriptionMutationFn = Apollo.MutationFunction<
  SubmitUseCaseDescriptionMutation,
  SubmitUseCaseDescriptionMutationVariables
>;

/**
 * __useSubmitUseCaseDescriptionMutation__
 *
 * To run a mutation, you first call `useSubmitUseCaseDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitUseCaseDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitUseCaseDescriptionMutation, { data, loading, error }] = useSubmitUseCaseDescriptionMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      useCaseDescription: // value for 'useCaseDescription'
 *   },
 * });
 */
export function useSubmitUseCaseDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitUseCaseDescriptionMutation, SubmitUseCaseDescriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitUseCaseDescriptionMutation, SubmitUseCaseDescriptionMutationVariables>(
    SubmitUseCaseDescriptionDocument,
    options
  );
}
export type SubmitUseCaseDescriptionMutationHookResult = ReturnType<typeof useSubmitUseCaseDescriptionMutation>;
export type SubmitUseCaseDescriptionMutationResult = Apollo.MutationResult<SubmitUseCaseDescriptionMutation>;
export type SubmitUseCaseDescriptionMutationOptions = Apollo.BaseMutationOptions<
  SubmitUseCaseDescriptionMutation,
  SubmitUseCaseDescriptionMutationVariables
>;
export const NewEnterpriseCustomerEmailDocument = gql`
  mutation newEnterpriseCustomerEmail($organisationUuid: ID!) {
    newEnterpriseCustomerEmail(organisationUuid: $organisationUuid)
  }
`;
export type NewEnterpriseCustomerEmailMutationFn = Apollo.MutationFunction<
  NewEnterpriseCustomerEmailMutation,
  NewEnterpriseCustomerEmailMutationVariables
>;

/**
 * __useNewEnterpriseCustomerEmailMutation__
 *
 * To run a mutation, you first call `useNewEnterpriseCustomerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewEnterpriseCustomerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newEnterpriseCustomerEmailMutation, { data, loading, error }] = useNewEnterpriseCustomerEmailMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useNewEnterpriseCustomerEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NewEnterpriseCustomerEmailMutation,
    NewEnterpriseCustomerEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NewEnterpriseCustomerEmailMutation, NewEnterpriseCustomerEmailMutationVariables>(
    NewEnterpriseCustomerEmailDocument,
    options
  );
}
export type NewEnterpriseCustomerEmailMutationHookResult = ReturnType<typeof useNewEnterpriseCustomerEmailMutation>;
export type NewEnterpriseCustomerEmailMutationResult = Apollo.MutationResult<NewEnterpriseCustomerEmailMutation>;
export type NewEnterpriseCustomerEmailMutationOptions = Apollo.BaseMutationOptions<
  NewEnterpriseCustomerEmailMutation,
  NewEnterpriseCustomerEmailMutationVariables
>;
export const StartOnboardingApplicationForAdditionalNumberDocument = gql`
  mutation startOnboardingApplicationForAdditionalNumber($organisationUuid: ID!) {
    startOnboardingApplicationForAdditionalNumber(organisationUuid: $organisationUuid) {
      ...onboardingApplication
    }
  }
  ${OnboardingApplicationFragmentDoc}
`;
export type StartOnboardingApplicationForAdditionalNumberMutationFn = Apollo.MutationFunction<
  StartOnboardingApplicationForAdditionalNumberMutation,
  StartOnboardingApplicationForAdditionalNumberMutationVariables
>;

/**
 * __useStartOnboardingApplicationForAdditionalNumberMutation__
 *
 * To run a mutation, you first call `useStartOnboardingApplicationForAdditionalNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartOnboardingApplicationForAdditionalNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startOnboardingApplicationForAdditionalNumberMutation, { data, loading, error }] = useStartOnboardingApplicationForAdditionalNumberMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useStartOnboardingApplicationForAdditionalNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartOnboardingApplicationForAdditionalNumberMutation,
    StartOnboardingApplicationForAdditionalNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartOnboardingApplicationForAdditionalNumberMutation,
    StartOnboardingApplicationForAdditionalNumberMutationVariables
  >(StartOnboardingApplicationForAdditionalNumberDocument, options);
}
export type StartOnboardingApplicationForAdditionalNumberMutationHookResult = ReturnType<
  typeof useStartOnboardingApplicationForAdditionalNumberMutation
>;
export type StartOnboardingApplicationForAdditionalNumberMutationResult =
  Apollo.MutationResult<StartOnboardingApplicationForAdditionalNumberMutation>;
export type StartOnboardingApplicationForAdditionalNumberMutationOptions = Apollo.BaseMutationOptions<
  StartOnboardingApplicationForAdditionalNumberMutation,
  StartOnboardingApplicationForAdditionalNumberMutationVariables
>;
export const CancelActiveOnboardingApplicationDocument = gql`
  mutation cancelActiveOnboardingApplication($organisationUuid: ID!) {
    cancelActiveOnboardingApplication(organisationUuid: $organisationUuid) {
      ...onboardingApplication
    }
  }
  ${OnboardingApplicationFragmentDoc}
`;
export type CancelActiveOnboardingApplicationMutationFn = Apollo.MutationFunction<
  CancelActiveOnboardingApplicationMutation,
  CancelActiveOnboardingApplicationMutationVariables
>;

/**
 * __useCancelActiveOnboardingApplicationMutation__
 *
 * To run a mutation, you first call `useCancelActiveOnboardingApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelActiveOnboardingApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelActiveOnboardingApplicationMutation, { data, loading, error }] = useCancelActiveOnboardingApplicationMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useCancelActiveOnboardingApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelActiveOnboardingApplicationMutation,
    CancelActiveOnboardingApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelActiveOnboardingApplicationMutation,
    CancelActiveOnboardingApplicationMutationVariables
  >(CancelActiveOnboardingApplicationDocument, options);
}
export type CancelActiveOnboardingApplicationMutationHookResult = ReturnType<
  typeof useCancelActiveOnboardingApplicationMutation
>;
export type CancelActiveOnboardingApplicationMutationResult =
  Apollo.MutationResult<CancelActiveOnboardingApplicationMutation>;
export type CancelActiveOnboardingApplicationMutationOptions = Apollo.BaseMutationOptions<
  CancelActiveOnboardingApplicationMutation,
  CancelActiveOnboardingApplicationMutationVariables
>;
