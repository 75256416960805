import { Box } from "grommet";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ColorNeutralBlack } from "ds/Core";
import { TColors, Text, calcSpacing, getColor, getSpacing } from "lib/impact";
import { ArrowRightIcon } from "lib/impact/Icons/ArrowRightIcon";
import { CheckRoundIcon } from "lib/impact/Icons/CheckRoundIcon";

import { StepStatuses } from "../types";

const StepTitle = styled(Text)`
  font-size: 22px;
  line-height: 1.25;

  @media screen and (max-height: 830px) {
    font-size: 20px;
  }
`;
export const StepText = styled(Text)`
  font-size: 18px;
  line-height: 1.25;

  @media screen and (max-height: 830px) {
    font-size: 16px;
  }
`;
export const StepSubTitle = styled(StepText)`
  /* font-size: 18px;
  line-height: 1.25; */
  margin: ${`${getSpacing("m")} 0 ${getSpacing("s")}`};

  @media screen and (max-height: 830px) {
    /* font-size: 16px; */
    margin: ${`${calcSpacing(["s", "xs"])} 0 ${getSpacing("xs")}`};
  }
`;
export const StepStatusText = styled(Text)`
  font-size: 16px;

  @media screen and (max-height: 830px) {
    font-size: 14px;
  }
`;
const StepStatusContainer = styled(Box)`
  padding: ${getSpacing("ml")};

  @media screen and (max-height: 830px) {
    padding: ${getSpacing("m")};
  }
`;

interface IStepStatus {
  title: string;
  color: TColors;
  status: StepStatuses;
  children?: ReactNode;
  onNext?: () => void;
  isAdmin: boolean;
  alwaysClickable?: boolean;
}

export const StepStatusCard = ({ children, title, color, status, onNext, isAdmin, alwaysClickable }: IStepStatus) => {
  const { t } = useTranslation();
  const next = status === StepStatuses.NEXT;
  const completed = status === StepStatuses.PENDING || status === StepStatuses.DONE;

  const clickable = next || alwaysClickable;

  return (
    <StepStatusContainer
      round="15px"
      gap={getSpacing("s")}
      background={next ? "black" : { color: getColor(color), opacity: 0.5 }}
      onClick={clickable && isAdmin ? onNext : undefined}
      flex={{ shrink: 0 }}
    >
      <Box direction="row" justify="between" align="center" gap="50px">
        <Box direction="row" justify="between" align="start" fill="horizontal" gap="40px">
          <Box>
            {next && (
              <StepStatusText color={color} margin={{ bottom: "xs" }}>
                {t("onboarding.progress-bar.next-step")}
              </StepStatusText>
            )}
            <StepTitle color={next ? "white" : "black"}>{title}</StepTitle>
          </Box>
          {completed && (
            <Box flex={{ shrink: 0 }}>
              <CheckRoundIcon />
            </Box>
          )}
        </Box>
        {clickable && isAdmin && (
          <ArrowRightIcon fill={alwaysClickable ? ColorNeutralBlack : undefined} style={{ flexShrink: 0 }} />
        )}
      </Box>
      {children}
    </StepStatusContainer>
  );
};
