import { Box, BoxExtendedProps } from "grommet";
import React from "react";
import styled from "styled-components";

import { ColorGrayBase, ColorNeutralBlack } from "ds/Core";
import { Typography, getSpacing, paragraph } from "lib/impact";

import { MAX_FORM_FIELD_WIDTH } from "../styles";

const ButtonTileContainer = styled(Box)`
  border-color: ${ColorGrayBase};
  &:hover {
    border-color: ${ColorNeutralBlack};
  }
`;
interface IButtonTile extends BoxExtendedProps {
  label: string;
  description?: string;
  onClick: () => void;
}
export const ButtonTile = ({ label, description, onClick, ...props }: IButtonTile) => (
  <ButtonTileContainer
    onClick={onClick}
    border={{ size: "2px" }}
    round="8px"
    pad={getSpacing("l")}
    width={{ max: MAX_FORM_FIELD_WIDTH }}
    {...props}
  >
    <Typography size="large" theme={paragraph}>
      {label}
    </Typography>
    {description && (
      <Typography size="large" color="dark-2" margin={{ top: "s" }}>
        {description}
      </Typography>
    )}
  </ButtonTileContainer>
);
