export const coreTokens = {
  color: {
    pinkLightest: "#FCE6FF",
    pinkLight: "#F3B3FE",
    pinkBase: "#EB80FD",
    pinkDark: "#8E4D97",
    pinkDarkest: "#47264C",
    greenLightest: "#D2F2E3",
    greenLight: "#77D9AB",
    greenBase: "#1DBF73",
    greenDark: "#137345",
    greenDarkest: "#0A3922",
    redLightest: "#FFE1D8",
    redLight: "#FEA689",
    redBase: "#FF643B",
    redDark: "#984023",
    redDarkest: "#4C2011",
    purpleLightest: "#EEE2FF",
    purpleLight: "#D7C0FF",
    purpleBase: "#C09DFF",
    purpleDark: "#834FCA",
    purpleDarkest: "#460095",
    blueLightest: "#CCF0F8",
    blueLight: "#66D2EA",
    blueBase: "#00B4DC",
    blueDark: "#006C85",
    blueDarkest: "#003642",
    yellowLightest: "#F7F2D8",
    yellowLight: "#E7D88A",
    yellowBase: "#D8C833",
    yellowDark: "#817224",
    yellowDarkest: "#403911",
    grayLightest: "#F6F6F7",
    grayLight: "#D9DADB",
    grayBase: "#B1B4B5",
    grayDark: "#5A5F62",
    grayDarkest: "#383C3E",
    neutralBlack: "#000",
    neutralWhite: "#fff",
    neutralCream: "#F7F2D8",
    transparency00: "#ffffff00",
    brandDefault: "#C09DFF",
    brandAccent: "#1DBF73",
  },
  space: {
    "0": "0px",
    "25": "2px",
    "50": "4px",
    "100": "8px",
    "150": "12px",
    "200": "16px",
    "250": "20px",
    "300": "24px",
    "400": "32px",
    "500": "40px",
    "600": "48px",
    "700": "56px",
    "800": "64px",
    "900": "72px",
    "1000": "80px",
    "1100": "88px",
    "1200": "96px",
    base: "8px",
  },
  fontSize: {
    "10": "12px",
    "25": "14px",
    "50": "15px",
    "100": "16px",
    "200": "18px",
    "300": "20px",
    "400": "22px",
    "500": "24px",
    "600": "28px",
    "700": "32px",
    "800": "36px",
    "900": "40px",
    "1000": "48px",
    base: "16px",
  },
  size: {
    "0": "0px",
    "8": "8px",
    "12": "12px",
    "16": "16px",
    "20": "20px",
    "24": "24px",
    "32": "32px",
    "40": "40px",
    "48": "48px",
    "56": "56px",
    "64": "64px",
    "72": "72px",
    "80": "80px",
    "88": "88px",
    "96": "96px",
    "104": "104px",
  },
  borderWidth: {
    "0": "0px",
    "100": "1px",
    "200": "2px",
  },
  borderRadius: {
    "0": "0px",
    "100": "3px",
    "200": "8px",
    "300": "16px",
    "400": "32px",
    pill: "9999px",
    circle: "1000px",
  },
  shadow: {
    low: "0px 0px 4px #0000001a, 0px 2px 2px #0000001a",
    medium: "0px 4px 4px #00000026, 0px 2px 2px #0000001a",
    high: "0px 8px 15px 2px #00000014, 0px 0px 4px #00000014",
  },
  lineHeight: {
    "25": "16px",
    "50": "24px",
    "100": "32px",
    "200": "40px",
    "300": "48px",
    "400": "56px",
    "500": "64px",
    "600": "72px",
    tight: "1.1",
    standard: "1.25",
    loose: "1.5",
  },
  fontWeight: {
    "100": 400,
    "200": 500,
  },
  fontFamily: {
    "dm-sans": "DM Sans",
  },
  textDecoration: {
    "0": "none",
    underline: "underline",
  },
};

export type CoreColorType =
  | "pinkLightest"
  | "pinkLight"
  | "pinkBase"
  | "pinkDark"
  | "pinkDarkest"
  | "greenLightest"
  | "greenLight"
  | "greenBase"
  | "greenDark"
  | "greenDarkest"
  | "redLightest"
  | "redLight"
  | "redBase"
  | "redDark"
  | "redDarkest"
  | "purpleLightest"
  | "purpleLight"
  | "purpleBase"
  | "purpleDark"
  | "purpleDarkest"
  | "blueLightest"
  | "blueLight"
  | "blueBase"
  | "blueDark"
  | "blueDarkest"
  | "yellowLightest"
  | "yellowLight"
  | "yellowBase"
  | "yellowDark"
  | "yellowDarkest"
  | "grayLightest"
  | "grayLight"
  | "grayBase"
  | "grayDark"
  | "grayDarkest"
  | "neutralBlack"
  | "neutralWhite"
  | "neutralCream"
  | "transparency00"
  | "brandDefault"
  | "brandAccent";

export type CoreSpaceType =
  | "0"
  | "25"
  | "50"
  | "100"
  | "150"
  | "200"
  | "250"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "1000"
  | "1100"
  | "1200"
  | "base";

export type CoreFontSizeType =
  | "10"
  | "25"
  | "50"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "1000"
  | "base";

export type CoreSizeType =
  | "0"
  | "8"
  | "12"
  | "16"
  | "20"
  | "24"
  | "32"
  | "40"
  | "48"
  | "56"
  | "64"
  | "72"
  | "80"
  | "88"
  | "96"
  | "104";

export type CoreBorderWidthType = "0" | "100" | "200";

export type CoreBorderRadiusType = "0" | "100" | "200" | "300" | "400" | "pill" | "circle";

export type CoreShadowType = "low" | "medium" | "high";

export type CoreLineHeightType =
  | "25"
  | "50"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "tight"
  | "standard"
  | "loose";

export type CoreFontWeightType = "100" | "200";

export type CoreFontFamilyType = "dm-sans";

export type CoreTextDecorationType = "0" | "underline";
