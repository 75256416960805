import React from "react";

import { ColorBrandDefault } from "ds/Core";

export const TurnLogo = ({ fill = ColorBrandDefault }: { fill?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5133 6H14.1814V8.49001C14.1814 9.09253 14.0156 9.55672 13.6839 9.8795C13.3523 10.2023 12.8824 10.3621 12.2806 10.3621H11V13.3409H13.7177V20.6511C13.7177 21.9791 13.9327 23.0366 14.3657 23.8143C14.7987 24.5951 15.4128 25.1546 16.2143 25.4928C17.0158 25.8309 17.9432 26 18.9966 26H20.6948V22.7722H19.7888C18.9444 22.7722 18.3547 22.6185 18.02 22.3142C17.6822 22.0098 17.5133 21.4534 17.5133 20.6542V13.344H20.6948V10.1469H17.5133V6.00307V6Z"
      fill={fill}
    />
  </svg>
);

export const TurnWordMark = ({ fill = ColorBrandDefault }: { fill?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 247 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M196.809 57.0042C193.154 57.0042 189.854 56.154 186.891 54.4495C183.936 52.7451 181.601 50.3572 179.904 47.2778C178.208 44.2024 177.362 40.6714 177.362 36.693C177.362 32.7145 178.225 29.1063 179.949 26.0309C181.673 22.9555 184.017 20.5636 186.972 18.8591C189.927 17.1546 193.239 16.3044 196.89 16.3044C200.541 16.3044 203.986 17.1546 206.921 18.8591C209.855 20.5636 212.167 22.9474 213.867 25.9902C215.563 29.0452 216.409 32.6088 216.409 36.6889C216.409 40.769 215.547 44.2675 213.823 47.3103C212.098 50.3653 209.77 52.7369 206.836 54.4414C203.901 56.1459 200.557 56.9961 196.805 56.9961L196.809 57.0042ZM196.886 49.0188C198.74 49.0188 200.42 48.551 201.942 47.6235C203.456 46.696 204.678 45.3089 205.609 43.4783C206.532 41.6477 206.998 39.3859 206.998 36.693C206.998 34 206.532 31.665 205.609 29.8304C204.686 27.9998 203.464 26.6248 201.942 25.7217C200.428 24.8186 198.768 24.363 196.967 24.363C195.165 24.363 193.494 24.8146 191.947 25.7217C190.401 26.6289 189.17 27.9998 188.239 29.8304C187.316 31.6609 186.851 33.9552 186.851 36.693C186.851 39.4307 187.316 41.6477 188.239 43.4783C189.162 45.3089 190.385 46.696 191.907 47.6235C193.421 48.551 195.08 49.0188 196.882 49.0188H196.886Z"
      fill={fill}
    />
    <path
      d="M149.751 56.459C148.003 56.459 146.586 55.9179 145.505 54.8318C144.424 53.7457 143.886 52.4561 143.886 50.9551C143.886 49.3564 144.424 48.0058 145.505 46.9197C146.586 45.8335 147.994 45.2925 149.751 45.2925C151.508 45.2925 152.836 45.8335 153.917 46.9197C154.997 48.0058 155.536 49.3482 155.536 50.9551C155.536 52.4561 154.997 53.7457 153.917 54.8318C152.836 55.9179 151.447 56.459 149.751 56.459Z"
      fill={fill}
    />
    <path
      d="M15.9126 7.02539H7.77213V13.1314C7.77213 14.608 7.36733 15.7471 6.55774 16.5403C5.74814 17.3336 4.59851 17.7241 3.12909 17.7241H0V25.0301H6.6387V42.9575C6.6387 46.2159 7.16493 48.8072 8.22146 50.7151C9.27798 52.6311 10.7798 54.002 12.739 54.8318C14.6982 55.6617 16.9611 56.0766 19.5356 56.0766H23.6848V48.1604H21.4705C19.406 48.1604 17.965 47.7821 17.1473 47.0377C16.3215 46.2932 15.9086 44.9264 15.9086 42.9657V25.0382H23.6807V17.1952H15.9086V7.02539H15.9126Z"
      fill={fill}
    />
    <path
      d="M136.494 23.9807C135.308 21.4179 133.6 19.4938 131.353 18.1961C129.111 16.9066 126.447 16.2598 123.363 16.2598H123.286C122.954 16.2598 122.634 16.276 122.318 16.2882L122.334 16.272C119.845 16.272 117.651 16.8374 115.748 17.952C113.842 19.0748 112.461 20.2219 111.599 21.3976H111.215L109.616 17.1913H101.431V56.0727H110.696V35.5784C110.696 35.3913 110.697 35.2082 110.713 35.0292C110.773 32.8366 111.194 30.9491 111.972 29.3667C112.385 28.5368 112.87 27.8046 113.429 27.1659C114.008 26.5232 114.668 25.974 115.408 25.5306C116.902 24.6275 118.574 24.1719 120.428 24.1719C123.31 24.1719 125.464 25.0628 126.88 26.8486C128.232 28.5531 128.937 30.9654 128.997 34.0733C128.997 34.2157 129.006 34.354 129.006 34.4963V56.0768H138.271V33.5729C138.271 29.7409 137.68 26.5476 136.494 23.9889V23.9807Z"
      fill={fill}
    />
    <path
      d="M57.8903 17.1872V37.6815C57.8903 37.8686 57.8903 38.0516 57.8741 38.2306C57.8134 40.4233 57.3924 42.3108 56.6152 43.8932C56.2023 44.7231 55.7165 45.4553 55.1579 46.094C54.5791 46.7367 53.9192 47.2859 53.1784 47.7293C51.6847 48.6324 50.0129 49.088 48.1589 49.088C45.2768 49.088 43.1233 48.1971 41.7065 46.4113C40.3544 44.7068 39.6501 42.2945 39.5894 39.1866C39.5894 39.0442 39.5813 38.9059 39.5813 38.7635V17.1831H30.3154V39.687C30.3154 43.519 30.9064 46.7123 32.0925 49.271C33.2786 51.8338 34.9868 53.758 37.2334 55.0556C39.476 56.3452 42.1396 56.992 45.2242 56.992H45.3011C45.633 56.992 45.9528 56.9757 46.2685 56.9635L46.2523 56.9798C48.7419 56.9798 50.9359 56.4143 52.8384 55.2997C54.745 54.177 56.1254 53.0298 56.9876 51.8542H57.3722L58.9711 56.0604H67.1561V17.1872H57.8903Z"
      fill={fill}
    />
    <path
      d="M83.3479 20.6409V17.1872H74.082V56.0686H83.3479V35.0536C83.3479 33.1701 83.7081 31.5104 84.4206 30.0988C85.133 28.6832 86.1531 27.6092 87.4606 26.8933C88.7722 26.1773 90.2659 25.8153 91.9458 25.8153C93.2816 25.8153 94.3989 25.9129 95.3218 26.096V16.9716C89.351 15.7593 84.9995 19.0828 83.3398 20.6368H83.3479V20.6409Z"
      fill={fill}
    />
    <path
      d="M166.582 10.9346C164.886 10.9346 163.486 10.4058 162.373 9.34406C161.263 8.29046 160.713 6.97652 160.713 5.4307C160.713 3.88488 161.267 2.52212 162.373 1.51734C163.474 0.508493 164.878 0 166.582 0C168.287 0 169.679 0.504425 170.792 1.51734C171.893 2.52619 172.452 3.832 172.452 5.4307C172.452 7.0294 171.897 8.2864 170.792 9.34406C169.683 10.4058 168.287 10.9346 166.582 10.9346Z"
      fill={fill}
    />
    <path
      d="M244.061 24.3344C242.802 22.0726 241.248 20.1363 239.402 18.5132V54.7097C241.219 53.1029 242.762 51.1869 244.017 48.9577C246.004 45.4348 247.004 41.3425 247.004 36.6887C247.004 32.035 246.02 27.8532 244.061 24.3303V24.3344Z"
      fill={fill}
    />
    <path
      d="M222.542 16.2597V56.9879C225.76 55.0353 228.343 52.3667 230.262 48.9659C232.249 45.4431 233.249 41.3507 233.249 36.697C233.249 32.0433 232.265 27.8615 230.306 24.3386C228.391 20.9093 225.805 18.2245 222.542 16.2638V16.2556V16.2597Z"
      fill={fill}
    />
    <path d="M171.218 17.248H161.952V56.1294H171.218V17.248Z" fill={fill} />
  </svg>
);
