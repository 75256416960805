import styled from "styled-components";

import { ColorGrayBase, ColorGrayLightest } from "ds/Core";
import { Paragraph } from "lib/impact/Typography/Paragraph";
import { colors } from "lib/impact/styles/colors";
import { Spaces, TSpacing, getSpacing } from "lib/impact/styles/spacing";

import { TFieldWrapperTheme, TPromptTypes } from "./types";
import { getFieldStyles } from "./utils";

type TStyledFieldWrapperContainer = { type: TPromptTypes; margin?: TSpacing } & TFieldWrapperTheme;

export const StyledFieldWrapperContainer = styled.div<TStyledFieldWrapperContainer>`
  width: 100%;
  margin: ${({ margin }) => getSpacing(margin ?? { bottom: Spaces.ML })};
  padding: ${getSpacing(Spaces.NONE)};

  display: flex;
  flex-direction: column;

  label {
    ${({ label: { fontSize, lineHeight } }) => `
      font-size: ${fontSize};
      line-height: ${lineHeight};
    `}
  }
`;

export const StyledInput = styled.div<TStyledFieldWrapperContainer>`
  & > input,
  select,
  textArea {
    width: 100%;
    text-overflow: ellipsis;
    ${({ height, text: { fontSize, lineHeight }, padding }) => `
      min-height: ${height.min};
      font-size: ${fontSize};
      line-height: ${lineHeight};
      padding: ${padding};
    `}
    box-sizing: border-box;
    border-width: 2px;
    border-radius: 8px;
    border-style: solid;
    outline: none;
    color: black;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${ColorGrayBase};
      ${({ text: { fontSize, lineHeight } }) => `
        font-size: ${fontSize};
        line-height: ${lineHeight};
      `}
      opacity: 1; /* Firefox */
    }

    ${({ type, theme }) => getFieldStyles(type, theme)}

    &:focus {
      ::placeholder {
        color: ${ColorGrayBase};
      }
      ${({ type, theme }) => getFieldStyles(type, theme, "focus")}
    }
    &:disabled {
      background-color: ${ColorGrayLightest};
      border: none;
      color: black;
      opacity: 1;
    }
  }
`;

export const StyledLabel = styled.label<React.CSSProperties>`
  color: ${colors.black};
  font-weight: 500;
  margin: ${getSpacing({ bottom: Spaces.S })};
`;

export const StyledPrompt = styled(Paragraph)<{ type: TPromptTypes }>`
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 4px;
  padding: ${getSpacing({ horizontal: Spaces.S, vertical: Spaces.XS })};
  margin: ${getSpacing({ top: Spaces.XS })};
  min-height: 26px;
  box-sizing: border-box;
  ${({ type, theme }) => getFieldStyles(type, theme, "prompt")}
`;
